import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { Dropdown, Modal } from 'react-bootstrap'
import Select from 'react-select'

import DeleteModal from 'pages/parts/DeleteModal'
import { httpOk, GET, POST, PUT, DELETE } from 'helpers/api'

function Index() {
	const { t } = useTranslation()

	const [data, setData] = useState([])
	const [dataDuplicate, setDataDuplicate] = useState([])
	const [selectedItem, setSelectedItem] = useState([])
	const [showModal, setShowModal] = useState(false)
	const [modalDelete, setModalDelete] = useState(false)
	const [deleteModalParams, setDeleteModalParams] = useState({ id: 0, index: 0 })

	async function createUpdate(e) {
		e.preventDefault()

		var response;
		if (selectedItem.id) {
			response = await PUT('/comments', selectedItem, { loader: true })
		} else {
			response = await POST('/comments', selectedItem, { loader: true })
		}

		if (httpOk(response)) {
			getData()
			toggleModal(false)
		}
	}

	function toggleModal(bool = false, item = {}) {
		setSelectedItem(item)
		setShowModal(bool)
	}

	async function deleteItem() {
		const response = await DELETE('/comments', { 'id': deleteModalParams.id }, { loader: true })
		if (httpOk(response)) {
			getData()
			setModalDelete(false)
		}
	}

	async function getData() {
		var response = await GET('/comments', {}, { loader: true })

		if (httpOk(response)) setData([...response.data])

		response.data.unshift({ 'id': null, 'name': t('main') })
		if (httpOk(response)) setDataDuplicate([...response.data])
	}

	useEffect(() => {
		getData()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between">
				<h4 className="vertical-center">
					{t('comments')} [{data.length}]
				</h4>
				<div className="d-flex">
					<button className="btn-rounded btn btn-primary"
						onClick={() => toggleModal(true)}>
						{t('create')}
					</button>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="table-responsive">
						<table className="table table-bordered mb-0">
							<thead>
								<tr>
									<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
									<th style={{ 'width': '30%' }}>{t('name2')}</th>
									<th style={{ 'width': '20%' }}>{t('category')}</th>
									<th style={{ 'width': '50%' }}></th>
									<th style={{ 'minWidth': '33px' }}></th>
								</tr>
							</thead>
							<tbody>
								{data?.map((item, index) => (
									<tr key={index}>
										<td className="text-center">{index + 1}</td>
										<td>{item.name}</td>
										<td>{item.parent_name}</td>
										<td></td>
										<td>
											<div className="table-dropdown">
												<Dropdown id={`dropdown-${index}`}>
													<Dropdown.Toggle variant="outline-primary">
														<i className="uil uil-ellipsis-v"></i>
													</Dropdown.Toggle>

													<Dropdown.Menu>
														<div className="table-dropdown-item"
															onClick={() => toggleModal(true, item)}>
															<i className="uil-edit-alt"></i>
															{t('edit')}
														</div>
														<div className="table-dropdown-item text-danger"
															onClick={() => {
																setDeleteModalParams({ id: item.id, index: index });
																setModalDelete(true)
															}}>
															<i className="uil-times"></i>
															{t('delete')}
														</div>
													</Dropdown.Menu>
												</Dropdown>
											</div>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>

			{/* MODAL */}
			<Modal show={showModal} animation={false} centered
				dialogClassName="update-modal-width" backdrop="static"
				onHide={() => toggleModal()}>
				<Modal.Header closeButton>
					<Modal.Title>
						{!selectedItem.id ?
							<>{t('create')}</>
							:
							<>{t('edit')}</>
						}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={createUpdate} autoComplete="off">
						<div className="form-group">
							<label>{t('name2')}</label>
							<input type="text" className="form-control" name="name"
								value={selectedItem.name ?? ''}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'name': e.target.value })} />
						</div>
						<div className="form-group">
							<label>{t('category')}</label>
							<Select
								options={dataDuplicate}
								value={dataDuplicate?.find(option => option.id === selectedItem.parent_id) || ''}
								onChange={(option) => setSelectedItem({ ...selectedItem, 'parent_id': option.id })}
								placeholder=""
								noOptionsMessage={() => ""}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>
						<div className="d-flex gap-2 w-100 mt-3">
							<button type="button" className="btn btn-outline-warning w-100"
								onClick={() => toggleModal()}>
								{t('cancel')}
							</button>
							<button type="submit" className="btn btn-primary w-100">
								{t('save')}
							</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL */}

			<DeleteModal
				modalDelete={modalDelete}
				setModalDelete={setModalDelete}
				deleteItem={deleteItem}>
			</DeleteModal>
		</>
	)
}

export default Index