import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { Modal, Dropdown } from 'react-bootstrap'
import DatePicker from "react-datepicker"
import Select from 'react-select'

import FilterSearch from 'pages/parts/FilterSearch'
import FilterPagination from 'pages/parts/FilterPagination'

import { returnSign, formatMoney, formatDate } from "helpers/helpers";
import { POST, GET, FILE, httpOk } from 'helpers/api'

function Index() {
	const { t } = useTranslation()

	const tgTypes = [
		{ 'id': 1, 'name': 'Рисунок' },
		{ 'id': 2, 'name': 'Несколько рисунков' },
		{ 'id': 3, 'name': 'Видео' }
	]

	const [selectedItem, setSelectedItem] = useState({ 'tg_type': 1 })
	const [showModal, setShowModal] = useState(false)
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [filterData, setFilterData] = useState({
		search: '',
	})
	const [pagination, setPagination] = useState({
		url: '/notifications',
		response: null
	})
	const [data, setData] = useState([])

	async function searchWithFilter() {
		var filterDataCopy = { ...filterData }
		var filterUrl = "/notifications"
		var urlParams = ""
		checkFilter()

		if (filterDataCopy.start_date)
			urlParams += returnSign(urlParams) + 'start_date=' + filterDataCopy.start_date
		if (filterDataCopy.end_date)
			urlParams += returnSign(urlParams) + 'end_date=' + filterDataCopy.end_date
		if (filterData.search)
			urlParams += returnSign(urlParams) + 'search=' + filterData.search
		if (filterData.page)
			urlParams += returnSign(urlParams) + 'page=' + filterData.page

		if (`/notifications${urlParams}` !== filterUrl)
			setFilterDataExist(true)

		filterUrl = filterUrl + urlParams

		const response = await GET(filterUrl, {}, { loader: true })
		if (httpOk(response)) {
			setData(response.data)
			setPagination({ ...pagination, 'url': filterData.url, 'response': response.data })
			setDropdownIsOpen(false)
		}
	}

	function resetFilter() {
		setFilterData({
			pos_id: null,
			search: '',
		})
		setFilterDataExist(false)
		searchWithFilter()
	}

	function checkFilter() {
		if (
			filterData.pos_id === null &&
			filterData.search === ''
		) {
			setFilterDataExist(false)
		}
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	async function paginate(page) {
		setFilterData({ ...filterData, 'page': page })
	}

	async function createUpdate(e) {
		e.preventDefault();

		const response = await POST('/notifications-telegram-send', selectedItem, { loader: true })

		if (httpOk(response)) {
			searchWithFilter()
			setShowModal(false)
		}
	}

	async function fileUpload(e) {
		if (selectedItem.tg_type === 1) {
			let formData = new FormData();
			formData.append("image", e.target.files[0]);
			formData.append("path", 'notifications');

			const response = await FILE('/upload-image', formData, {}, { loader: true });
			if (httpOk(response)) setSelectedItem({ ...selectedItem, 'image': response.data.url })
		}

		if (selectedItem.tg_type === 2) {
			let formData = new FormData();
			for (let i = 0; i < e.target.files.length; i++) {
				formData.append("images[]", e.target.files[i]);
			}
			formData.append("path", 'notifications');

			const response = await FILE('/upload-images', formData, {}, { loader: true });
			if (httpOk(response)) setSelectedItem({ ...selectedItem, 'images': response.data.urls })
		}

		if (selectedItem.tg_type === 3) {
			let formData = new FormData();
			formData.append("image", e.target.files[0]);
			formData.append("path", 'notifications');

			const response = await FILE('/upload-image', formData, {}, { loader: true });
			if (httpOk(response)) setSelectedItem({ ...selectedItem, 'video': response.data.url })
		}
	}

	function toggleModal(bool) {
		setShowModal(bool)
		setSelectedItem({ 'tg_type': 1 })
	}

	useEffect(() => {
		searchWithFilter(false)
	}, [filterData.search, filterData.page]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between">
				<h4 className="vertical-center">
					{t('telegram_notifications')} [{data.total}]
				</h4>
				<div className="d-flex">
					<button className="btn-rounded btn btn-primary"
						onClick={() => setShowModal(true)}>
						{t('create')}
					</button>
				</div>
			</div>

			<div className="card">
				<div className="card-body">

					<div className="d-flex mb-3">
						<div className="d-flex flex-wrap gap-2">
							<div>
								<FilterSearch
									filterData={filterData}
									setFilterData={setFilterData}>
								</FilterSearch>
							</div>
							<div>
								<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
									<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100">
										{t('filter')}
									</Dropdown.Toggle>

									<Dropdown.Menu className="dropdown-filter">
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('period')}</label>
												<div className="calendar-input">
													<DatePicker
														className="form-control"
														dateFormat="dd.MM.yyyy"
														selected={filterData.start_date ? new Date(filterData.start_date) : ''}
														onChange={(date) => setFilterData({ ...filterData, 'start_date': formatDate(date, 'yyyy-MM-dd') })} />
													<i className="uil uil-calendar-alt"></i>
												</div>
											</div>
											<div className="form-group col-md-6">
												<label className="invisible">.</label>
												<div className="calendar-input">
													<DatePicker
														className="form-control"
														dateFormat="dd.MM.yyyy"
														selected={filterData.end_date ? new Date(filterData.end_date) : ''}
														onChange={(date) => setFilterData({ ...filterData, 'end_date': formatDate(date, 'yyyy-MM-dd') })} />
													<i className="uil uil-calendar-alt"></i>
												</div>
											</div>
										</div>
										<div className="row mt-3">
											<div className="col-md-12">
												<div className="d-flex justify-content-end">
													<button className="btn btn-secondary me-2" onClick={() => setDropdownIsOpen(false)}>{t('cancel')}</button>
													<button className="btn btn-primary" onClick={() => searchWithFilter(false)}>{t('apply')}</button>
												</div>
											</div>
										</div>
									</Dropdown.Menu>
								</Dropdown>
							</div>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block">
							<div className="filter">
								<strong>{t('filter')}</strong>
								<br />
								{(filterData.start_date || filterData.end_date) &&
									<span className="me-2">
										<b>{t('period')}: </b>
										{filterData.start_date &&
											<span>{formatDate(filterData.start_date)}</span>
										}
										{(filterData.start_date && filterData.end_date) &&
											<span>|</span>
										}
										{filterData.end_date &&
											<span>{formatDate(filterData.end_date)}</span>
										}
									</span>
								}
								{filterData.search &&
									<span className="me-2">
										<b>{t('search')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase"
									onClick={resetFilter}>
									{t('reset')}
								</button>
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table table-bordered mb-0">
							<thead>
								<tr>
									<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
									<th style={{ 'width': '60%' }}>{t('description')}</th>
									<th style={{ 'width': '10%' }} className="text-center">{t('image')}</th>
									<th style={{ 'width': '10%' }} className="text-center">{t('date')}</th>
									<th style={{ 'width': '10%' }} className="text-end">{t('success')}</th>
									<th style={{ 'width': '10%' }} className="text-end">{t('errors')}</th>
								</tr>
							</thead>
							<tbody>
								{data?.data?.map((item, index) => (
									<tr key={index}>
										<td className="text-center">{index + 1}</td>
										<td>{item.message}</td>
										<td className="text-center">
											{item.image &&
												<img src={`https://backend.mison.uz/uploads/notifications/${item.image}`}
													width={50} height={50} alt="img" />
											}
										</td>
										<td className="text-center text-nowrap">
											{formatDate(item.created_at, 'dd.MM.yy HH:mm')}
										</td>
										<td className="text-end text-success">
											<b>{formatMoney(item.success_count)}</b>
										</td>
										<td className="text-end text-danger">
											<b>{formatMoney(item.errors_count)}</b>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>

					{pagination.response &&
						<FilterPagination
							pagination={pagination}
							paginate={paginate}>
						</FilterPagination>
					}
				</div>
			</div>

			{/* MODAL */}
			<Modal show={showModal} animation={false} centered
				dialogClassName="update-modal-width" backdrop="static"
				onHide={() => toggleModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>
						{t('create')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={createUpdate} autoComplete="off">
						<div className="form-group">
							<Select
								options={tgTypes}
								value={tgTypes.find(option => option.id === selectedItem.tg_type) || ''}
								onChange={(option) => setSelectedItem({ ...selectedItem, 'tg_type': option.id })}
								placeholder=""
								noOptionsMessage={() => t('list_empty')}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>
						{selectedItem.tg_type === 1 &&
							<div className="form-group">
								<input type="file" className="form-control" accept="image/jpg,image/jpeg"
									onChange={(e) => fileUpload(e)} />
							</div>
						}
						{selectedItem.tg_type === 2 &&
							<div className="form-group">
								<input type="file" className="form-control" accept="image/jpg,image/jpeg" multiple
									onChange={(e) => fileUpload(e)} />
							</div>
						}
						{selectedItem.tg_type === 3 &&
							<div className="form-group">
								<input type="file" className="form-control" accept="video/mp4"
									onChange={(e) => fileUpload(e)} />
							</div>
						}

						{selectedItem.tg_type === 2 &&
							<div className="mb-2"><b className="text-success">Зажмите ctrl для выбора несколько файлов</b></div>
						}
						{(selectedItem.tg_type === 1 && selectedItem.image) &&
							<div className="d-flex gap-3">
								<div style={{ 'width': '188px', 'height': '140px' }}>
									<img alt={selectedItem.image}
										src={`https://backend.mison.uz//uploads/notifications/${selectedItem.image}`}
										style={{ 'maxWidth': '100%', 'maxHeight': '100%' }} />
								</div>
							</div>
						}
						{(selectedItem.tg_type === 2 && selectedItem.images) &&
							<div className="d-flex gap-3">
								{selectedItem?.images?.map((item, index) => (
									<div style={{ 'width': '188px', 'height': '140px' }} key={index}>
										<img alt={item}
											src={`https://backend.mison.uz//uploads/notifications/${item}`}
											style={{ 'maxWidth': '100%', 'maxHeight': '100%' }} />
									</div>
								))}
							</div>
						}

						<div className="form-group">
							<label>{t("text")}</label>
							<textarea className="form-control" rows="5"
								onChange={(e) => setSelectedItem({ ...selectedItem, 'message': e.target.value })}>
							</textarea>
						</div>

						<div className="d-flex gap-2 w-100 mt-3">
							<button type="button" className="btn btn-outline-warning w-100"
								onClick={() => toggleModal(false)}>
								{t('cancel')}
							</button>
							<button type="submit" className="btn btn-primary w-100">
								{t('save')}
							</button>
						</div>
					</form>
				</Modal.Body>
			</Modal >
			{/* MODAL */}
		</>
	)
}

export default Index