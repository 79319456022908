import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { Modal, Dropdown } from 'react-bootstrap'
import Select from 'react-select'

import FilterSearch from 'pages/parts/FilterSearch'
import FilterPagination from 'pages/parts/FilterPagination'

import { findKeyFromArrayByValue, formatDate, formatMoney, returnSign } from 'helpers/helpers'
import { httpOk, GET, POST } from 'helpers/api'

function Index() {
	const { t } = useTranslation()

	const statuses = [
		{ 'id': 1, 'name': "Активный" },
		{ 'id': -1, 'name': "Отключен" },
		{ 'id': -3, 'name': "Удален" },
	]

	const types = [
		{ 'id': 'pos', 'name': "Торговая точка" },
		{ 'id': 'branch', 'name': "Филиал" },
	]

	const tariffs = [
		{ 'id': '300000', 'name': "300 000" },
		{ 'id': '500000', 'name': "500 000" },
	]

	const [data, setData] = useState([])
	const [users, setUsers] = useState([])
	const [modalUpdate, setModalUpdate] = useState(false)
	const [selectedItem, setSelectedItem] = useState({
		'type': 'pos',
		'tariff': '500000'
	})
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [filterData, setFilterData] = useState({ status: 1 })
	const [pagination, setPagination] = useState({
		url: '/partner/pos',
		response: null
	})

	async function createUpdate(e) {
		e.preventDefault();

		var selectedItemCopy = { ...selectedItem }
		if (selectedItemCopy.phone && selectedItemCopy.phone.length === 9)
			selectedItemCopy.phone = '998' + selectedItemCopy.phone

		const response = await POST('/partner/pos', selectedItemCopy, { loader: true })

		if (httpOk(response)) {
			setSelectedItem({ 'tariff': '500000' })
			setModalUpdate(false)
			searchWithFilter()
		}
	}

	async function searchWithFilter() {
		var filterDataCopy = { ...filterData }
		var filterUrl = "/partner/pos"
		var urlParams = ""
		checkFilter()

		if (filterDataCopy.search)
			urlParams += returnSign(urlParams) + 'search=' + filterDataCopy.search
		if (filterDataCopy.status)
			urlParams += returnSign(urlParams) + 'status=' + filterDataCopy.status
		if (filterDataCopy.page)
			urlParams += returnSign(urlParams) + 'page=' + filterDataCopy.page

		if (`/partner/pos${urlParams}` !== filterUrl)
			setFilterDataExist(true)

		filterUrl = filterUrl + urlParams

		const response = await GET(filterUrl, {}, { loader: true })
		if (httpOk(response)) {
			setData(response.data)
			setPagination({ ...pagination, 'url': filterUrl, 'response': response.data })
		}

		setDropdownIsOpen(false)
	}

	async function toggleModalCreateUpdate(bool, item = {}) {
		setSelectedItem({ 'type': 'pos', 'tariff': '500000' })
		setModalUpdate(bool)
	}

	async function handleSelectType(option) {
		setSelectedItem({ ...selectedItem, 'type': option ? option.id : null })

		var response = await GET('/partner/owners')
		if (httpOk(response)) setUsers(response.data)
	}

	function resetFilter() {
		setFilterData({
			status: null,
		})
		searchWithFilter()
	}

	function checkFilter() {
		if (filterData.status === null) {
			setFilterDataExist(false)
		}
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	async function paginate(page) {
		setFilterData({ ...filterData, 'page': page })
	}

	function returnClassStatus(status) {
		if (status === 1) {
			return "bg-primary"
		}
		if (status === -1) {
			return "bg-danger"
		}
		if (status === -2) {
			return "bg-secondary"
		}
		if (status === -3) {
			return "bg-danger"
		}
	}

	useEffect(() => {
		searchWithFilter()
	}, [filterData.search, filterData.page]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between">
				<div className="vertical-center">
					<h4>
						{t('poses')} [{data.total}]
					</h4>
				</div>
				<div className="d-flex">
					<button className="btn-rounded btn btn-primary"
						onClick={() => toggleModalCreateUpdate(true)}>
						{t('create')}
					</button>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex justify-content-between flex-wrap mb-3">
						<div className="d-flex flex-wrap gap-2">
							<div>
								<FilterSearch
									filterData={filterData}
									setFilterData={setFilterData}>
								</FilterSearch>
							</div>
							<div>
								<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
									<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100">
										{t('filter')}
									</Dropdown.Toggle>

									<Dropdown.Menu className="dropdown-filter">
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('status')}</label>
												<Select
													isClearable
													options={statuses}
													value={statuses.find(option => option.id === filterData.status) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'status': option ? option.id : null })}
													placeholder=""
													noOptionsMessage={() => t('list_empty')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
										</div>
										<div className="row mt-3">
											<div className="col-md-12">
												<div className="d-flex justify-content-end">
													<button className="btn btn-secondary me-2" onClick={() => setDropdownIsOpen(false)}>{t('cancel')}</button>
													<button className="btn btn-primary" onClick={() => searchWithFilter(false)}>{t('apply')}</button>
												</div>
											</div>
										</div>
									</Dropdown.Menu>
								</Dropdown>
							</div>
							<div className="d-flex gap-2">
								<div className="vertical-center">
									<span className="bg-primary bg-border text-white">
										<span>{t('active')}: {data?.status?.total_active}</span>
									</span>
								</div>
								<div className="vertical-center">
									<span className="bg-secondary bg-border text-white">
										<span>{t('disconnected')}: {data?.status?.total_not_active}</span>
									</span>
								</div>
								<div className="vertical-center">
									<span className="bg-danger bg-border text-white">
										<span>{t('deleted')}: {data?.status?.total_deleted}</span>
									</span>
								</div>
							</div>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block">
							<div className="filter">
								<strong>{t('filter')}</strong>
								<br />
								{filterData.search &&
									<span className="me-2">
										<b>{t('search')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
								{filterData.status &&
									<span className="me-2">
										<b>{t('status')}: </b>
										{findKeyFromArrayByValue(statuses, 'id', filterData.status, 'name')}
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase"
									onClick={resetFilter}>
									{t('reset')}
								</button>
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table table-bordered mb-0">
							<thead>
								<tr>
									<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
									<th style={{ 'minWidth': '33px' }} className="text-center">ID</th>
									<th style={{ 'width': '20%' }}>{t('pos')}</th>
									<th style={{ 'width': '20%' }}>{t('login')}</th>
									<th style={{ 'minWidth': '105px' }}>{t('phone')}</th>
									<th style={{ 'width': '10%' }} className="text-end">{t('tariff')}</th>
									<th style={{ 'width': '10%' }} className="text-end">{t('balance')}</th>
									<th style={{ 'minWidth': '88px' }} className="text-center">{t('created')}</th>
									<th style={{ 'minWidth': '88px' }} className="text-center">{t('credit')}</th>
									<th style={{ 'width': '10%' }} className="text-center">{t('last_cheque')}</th>
									<th style={{ 'width': '10%' }} className="text-center">Server</th>
									<th style={{ 'width': '10%' }} className="text-center">Kassa</th>
									<th style={{ 'width': '10%' }} className="text-center">{t('status')}</th>
								</tr>
							</thead>
							<tbody>
								{data?.data?.map((item, index) => (
									<tr key={index}>
										<td className="text-center">{item.row_number}</td>
										<td className="text-center"><b>{item.id}</b></td>
										<td className="td-ellipsis" title={item.id}>{item.name}</td>
										<td className="td-ellipsis">{item.login}</td>
										<td>{item.phone}</td>
										<td className="text-end">{formatMoney(item.tariff)}</td>
										<td className="text-end">{formatMoney(item.balance)}</td>
										<td className="text-center">{formatDate(item.created_at)}</td>
										<td className="text-center">{formatDate(item.credit_end_date)}</td>
										<td className="text-center">{formatDate(item.last_cheque_date)}</td>
										<td className="text-center">{item.desktop_server_version}</td>
										<td className="text-center">{item.desktop_client_version}</td>
										<td className={`text-center text-white cursor ${returnClassStatus(item.status)}`}>
											{item.status === 1 &&
												<span>{t('activated')}</span>
											}
											{item.status === -1 &&
												<span>{t('disconnected')}</span>
											}
											{item.status === -3 &&
												<span>{t('deleted')}</span>
											}
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>

					{pagination.response &&
						<FilterPagination
							pagination={pagination}
							paginate={paginate}>
						</FilterPagination>
					}
				</div>
			</div>

			{/* MODAL UPDATE*/}
			<Modal show={modalUpdate} animation={false} centered
				dialogClassName="update-modal-width" backdrop="static"
				onHide={() => toggleModalCreateUpdate(false)
				}>
				<Modal.Header closeButton>
					<Modal.Title>
						Создать
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={(e) => createUpdate(e)} autoComplete="off">
						<div className="form-group">
							<label>Тип</label>
							<Select
								options={types}
								value={types.find(option => option.id === selectedItem.type) || ''}
								onChange={(option) => handleSelectType(option)}
								placeholder=""
								noOptionsMessage={() => t('list_empty')}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>
						{selectedItem.type === 'branch' &&
							<div className="form-group">
								<label>Владелец</label>
								<Select
									options={users}
									value={users.find(option => option.login === selectedItem.owner_login) || ''}
									onChange={(option) => setSelectedItem({ ...selectedItem, 'owner_login': option ? option.login : null })}
									placeholder=""
									noOptionsMessage={() => t('list_empty')}
									getOptionLabel={(option) => option.login}
									getOptionValue={(option) => option.id}
								/>
							</div>
						}
						{selectedItem.type === 'pos' &&
							<>
								<div className="form-group">
									<label>Логин</label>
									<input type="text" className="form-control"
										name="login" value={selectedItem.login ?? ''}
										onChange={(e) => setSelectedItem({ ...selectedItem, 'login': e.target.value })} />
								</div>
								<div className="form-group">
									<label>Пароль</label>
									<input type="text" className="form-control"
										name="password" value={selectedItem.password ?? ''}
										onChange={(e) => setSelectedItem({ ...selectedItem, 'password': e.target.value })} />
								</div>
							</>
						}
						<div className="form-group">
							<label>Имя владельца</label>
							<input type="text" className="form-control" name="first_name" value={selectedItem.first_name ?? ''}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'first_name': e.target.value })} />
						</div>
						<div className="form-group">
							<label>Название торговой точки</label>
							<input type="text" className="form-control" name="name" value={selectedItem.name ?? ''}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'name': e.target.value })} />
						</div>
						<div className="form-group">
							<label>Телефон</label>
							<div className="input-group">
								<span className="input-group-text">998</span>
								<input type="number" className="form-control" name="phone"
									value={selectedItem.phone ?? ''}
									onChange={(e) => setSelectedItem({ ...selectedItem, 'phone': e.target.value })} />
							</div>
						</div>

						<div className="form-group">
							<label>Тариф</label>
							<Select
								options={tariffs}
								value={tariffs.find(option => option.id === selectedItem.tariff) || ''}
								onChange={(option) => setSelectedItem({ ...selectedItem, 'tariff': option ? option.id : null })}
								placeholder=""
								noOptionsMessage={() => t('list_empty')}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>
						<div className="d-flex w-100 mt-3 gap-2">
							<button type="button" className="btn btn-outline-warning w-100"
								onClick={() => toggleModalCreateUpdate(false)}>
								{t('cancel')}
							</button>
							<button type="submit" className="btn btn-primary w-100">
								{t('save')}
							</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL UPDATE*/}
		</>
	)
}

export default Index