import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useReactToPrint } from 'react-to-print';
import XLSX from 'xlsx'

import { formatMoney, formatDate } from 'helpers/helpers'
import logo from "assets/images/logo.png";

function Rightbar({ showRightBar, setShowRightBar, selectedItem, toggleModalDelete }) {
	const { t } = useTranslation()
	const printRef = useRef();

	const handlePrint = useReactToPrint({
		content: () => printRef.current,
	});

	function selectedItemToExcel() {
		var excelHeaders = []
		excelHeaders.push([`${t('cashier')}`, '', '', '', selectedItem.cashierName])
		excelHeaders.push([`${t('cheque')} №`, '', '', '', selectedItem.chequeNumber])
		excelHeaders.push([`${t('date')} №`, '', '', '', formatDate(selectedItem.chequeDate, 'dd.MM.yy HH:mm')])
		excelHeaders.push([t('product_name'), t('BARCODE'), t('quantity'), t('price'), t('total')])
		for (let i = 0; i < selectedItem.products.length; i++) {
			var excelItems = []
			excelItems.push(`${i + 1}. ${selectedItem.products[i].name}`)
			excelItems.push(selectedItem.products[i].barcode)
			excelItems.push(selectedItem.products[i].quantity)
			excelItems.push(selectedItem.products[i].sale_price)
			excelItems.push(selectedItem.products[i].totalPrice)
			excelHeaders.push(excelItems)
		}
		excelHeaders.push([t('sale_amount'), '', '', '', selectedItem.totalPrice])
		if (selectedItem.discount > 0) {
			excelHeaders.push([t('discount'), '', '', '', (selectedItem.totalPrice * selectedItem.discount) / 100])
		} else {
			excelHeaders.push([t('discount'), '', '', '', 0])
		}
		excelHeaders.push([t('to_pay'), '', '', '', selectedItem.totalPrice - ((selectedItem.totalPrice * selectedItem.discount) / 100)])
		excelHeaders.push([t('paid'), '', '', '', selectedItem.paid])
		if (selectedItem.vat > 0) {
			excelHeaders.push([t('vat') + selectedItem.vat + '%', '', '', '', selectedItem.totalVatAmount])
		} else {
			excelHeaders.push([t('vat') + ' 0%', '', '', '', formatMoney(0)])
		}
		if (selectedItem.label) {
			excelHeaders.push([t('marking'), '', '', '', selectedItem.label ? t('yes') : t('no')])
		}
		if (selectedItem.transactionsList.length > 0) {
			for (let i = 0; i < selectedItem.transactionsList.length; i++) {
				excelHeaders.push([selectedItem.transactionsList[i]['paymentTypeName'], '', '', '', selectedItem.transactionsList[i]['amountIn']])
			}
		}
		excelHeaders.push([t('change2'), '', '', '', selectedItem.change])
		if (selectedItem.clientAmount) {
			excelHeaders.push([t('amount_of_debt'), '', '', '', selectedItem.clientAmount])
		}
		if (selectedItem.clientAmount > 0) {
			excelHeaders.push([t('debtor'), '', '', '', selectedItem.clientName])
		}
		if ((Number(selectedItem.clientAmount) === 0 && selectedItem.clientName)) {
			excelHeaders.push([t('client'), '', '', '', selectedItem.clientName])
		}

		const ws = XLSX.utils.aoa_to_sheet(excelHeaders);
		// ws['!cols'] = fitToColumn(excelHeaders);
		// function fitToColumn(arrayOfArray) {
		// 	return arrayOfArray[0].map((a, i) => ({ wch: Math.max(...arrayOfArray.map(a2 => a2[i].toString().length)) }));
		// }
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
		XLSX.writeFile(wb, t('cheques') + ".xlsx");
	}

	return (
		<>
			{showRightBar &&
				<>
					<div className="right-sidebar-background" onClick={() => setShowRightBar(!showRightBar)}></div>
					<div className="right-sidebar">
						<div className="position-relative">
							<div className="rightbar-close-icon" onClick={() => setShowRightBar(!showRightBar)}>
								<i className="uil uil-times"></i>
							</div>
						</div>
						<div className="d-flex justify-content-center mt-3 mb-1">
							<div className="d-flex flex-column">
								<div className="d-flex justify-content-center mb-1">
									<img src={logo} width={120} alt="logo" />
								</div>
								<h5 className="text-center fw-700 mb-1">{t('duplicate')}</h5>
								<h5 className="text-center fw-700 mb-1">{selectedItem.pos_name}</h5>
								<h6 className="text-center fw-600 mb-1">{t('phone')}: {selectedItem.pos_phone}</h6>
								<h6 className="text-center fw-500 mb-1">{t('address')}: {selectedItem.pos_address}</h6>
							</div>
						</div>

						<div className="cheque-block-1 cheque-border-bottom mb-2">
							<div className="d-flex justify-content-between px-2">
								<p className="fw-600">{t('cashier')}</p>
								<p>{selectedItem.cashier_first_name}</p>
							</div>
							<div className="d-flex justify-content-between px-2">
								<p className="fw-600">{t('waiter')}</p>
								<p>{selectedItem.waiter_first_name}</p>
							</div>
							{selectedItem.courier_first_name &&
								<div className="d-flex justify-content-between px-2">
									<p className="fw-600">{t('courier')}</p>
									<p>{selectedItem.courier_first_name}</p>
								</div>
							}
							{!!selectedItem.hall_name &&
								<div className="d-flex justify-content-between px-2">
									<p className="fw-600">{t('hall')}</p>
									<p>{selectedItem.hall_name}</p>
								</div>
							}
							{!!selectedItem.table_name &&
								<div className="d-flex justify-content-between px-2">
									<p className="fw-600">{t('table')}</p>
									<p>{selectedItem.table_name}</p>
								</div>
							}
							<div className="d-flex justify-content-between px-2">
								<p className="fw-600">{t('cheque')} ID </p>
								<p>{selectedItem.id}</p>
							</div>
							<div className="d-flex justify-content-between px-2">
								<p className="fw-600">{t('cheque')} № </p>
								<p>{selectedItem.cheque_number}</p>
							</div>
							<div className="d-flex justify-content-between px-2">
								<p className="fw-600">{t('guests')}</p>
								<p>{selectedItem.people_number}</p>
							</div>
							{selectedItem.tin &&
								<div className="d-flex justify-content-between px-2">
									<p className="fw-600">{t('tin')}</p>
									<p>{selectedItem.tin}</p>
								</div>
							}
							<div className="d-flex justify-content-between px-2">
								<p className="fw-600">{t('order_type')} </p>
								{selectedItem.order_type === 1 &&
									<p>{t('hall')}</p>
								}
								{selectedItem.order_type === 2 &&
									<p>{t('takeaway')}</p>
								}
								{selectedItem.order_type === 3 &&
									<p>{t('delivery')}</p>
								}
							</div>
							<div className="d-flex justify-content-between px-2">
								<p className="fw-600">{t('open_date')}</p>
								<p>{formatDate(selectedItem.cheque_opened_at, 'dd.MM.yy HH:mm')}</p>
							</div>
							<div className="d-flex justify-content-between px-2">
								<p className="fw-600">{t('close_date')}</p>
								<p>{formatDate(selectedItem.cheque_closed_at, 'dd.MM.yy HH:mm')}</p>
							</div>
						</div>

						<div className="cheque-block-2 cheque-border-bottom px-2">
							<table className="custom-cheque-table w-100 fz-14">
								<thead>
									<tr>
										<th className="w-50"># {t('product')}</th>
										<th>{t('quantity')}</th>
										<th className="text-end">{t('price')}</th>
									</tr>
								</thead>
								<tbody>
									{selectedItem?.products?.length > 0 &&
										<>
											{selectedItem?.products?.map((item, index) => (
												<tr className={`${item.status < 0 && 'del'}`} key={index}>
													{/* column 1 */}
													<td style={{ 'whiteSpace': 'break-spaces' }}>
														<div>{index + 1}. {item.name}</div>
														{Number(item.discount) > 0 && <div className="ms-2">{t('discount')}</div>}
													</td>
													{/* column 1 */}

													{/* column 2 */}
													<td className="text-left" style={{ 'verticalAlign': 'top' }}>
														<div className="text-nowrap" >
															{formatMoney(item.quantity, 2)} X {formatMoney(item.sale_price)}
														</div>
													</td>
													{/* column 2 */}

													{/* column 3 */}
													<td className="text-end text-nowrap" style={{ 'verticalAlign': 'top' }}>
														<div>{formatMoney(item.total_price)}</div>
														{Number(item.discount) > 0 && <div>{formatMoney(item.discount)}</div>}
													</td>
													{/* column 3 */}
												</tr>
											))
											}
										</>
									}
								</tbody>
							</table>
						</div>

						<div className="cheque-block-3 cheque-border-bottom mb-2 px-2">
							<div className="d-flex justify-content-between">
								<p className="fw-600">{t('amount')}</p>
								<p>{formatMoney(selectedItem.total_amount)}</p>
							</div>
							{selectedItem.order_type === 1 &&
								<div className="d-flex justify-content-between">
									<p className="fw-600">{t('service')} {selectedItem.service_percentage ? selectedItem.service_percentage : 0}%</p>
									<p className="text-success">{formatMoney(selectedItem.service_amount)}</p>
								</div>
							}
							{!!selectedItem.delivery_price &&
								<div className="d-flex justify-content-between">
									<p className="fw-600">{t('delivery')} [km] {selectedItem.delivery_distance}</p>
									<p className="text-success">{formatMoney(selectedItem.delivery_price)}</p>
								</div>
							}
							{Number(selectedItem.discount) > 0 &&
								<div className="d-flex justify-content-between">
									<p className="fw-600">{t('discount')}</p>
									<p className="text-danger">{formatMoney(selectedItem.discount)}</p>
								</div>
							}
							{Number(selectedItem.agent_amount) > 0 &&
								<div className="d-flex justify-content-between">
									<p className="fw-600">{t('agents_bonus')}</p>
									<p className="fw-600">{formatMoney(selectedItem.agent_amount)}</p>
								</div>
							}
							<div className="d-flex justify-content-between">
								<p className="fw-600 fz-30">{t('total_payable')}</p>
								<p className="fw-600 fz-30">{formatMoney(selectedItem.total_amount_service)}</p>
							</div>
							<div className="d-flex justify-content-between">
								<p className="fw-600">{t('paid')}</p>
								<p className="fw-600">{formatMoney(selectedItem.paid)}</p>
							</div>
							{selectedItem?.payments?.map((item, index) => (
								<div className="d-flex justify-content-between" key={index}>
									{Number(item.amount_in) !== 0 ?
										<>
											<p>{item.payment_type_name}</p>
											<p>{formatMoney(item.amount_in)}</p>
										</>
										:
										<>
											<p>{item.payment_purpose_name}</p>
											<p>{formatMoney(item.amount_out)}</p>
										</>
									}
								</div>
							))}
							{Number(selectedItem.client_debtor_amount) > 0 &&
								<div className="d-flex justify-content-between">
									<p>{t('debtor')}</p>
									<p>{selectedItem.debtor_name}</p>
								</div>
							}
							{selectedItem.client_debtor_amount > 0 &&
								<div className="d-flex justify-content-between">
									<p>{t('amount_of_debt')}</p>
									<p>{formatMoney(selectedItem.client_debtor_amount)}</p>
								</div>
							}
							{selectedItem.organization_name &&
								<div className="d-flex justify-content-between">
									<p>{t('organization')}</p>
									<p>{selectedItem.organization_name}</p>
								</div>
							}
							{selectedItem.organization_amount > 0 &&
								<div className="d-flex justify-content-between">
									<p>{t('amount_of_debt')}</p>
									<p>{formatMoney(selectedItem.organization_amount)}</p>
								</div>
							}
							{(selectedItem.client_id && selectedItem.client_name) &&
								<div className="d-flex justify-content-between">
									<p>{t('client')}</p>
									{selectedItem.client_name &&
										<p>{selectedItem.client_name}</p>
									}
								</div>
							}
							{Number(selectedItem.loyalty) > 0 &&
								<div className="d-flex justify-content-between">
									<p>{t('loyalty')}</p>
									<p>{formatMoney(selectedItem.loyalty)}</p>
								</div>
							}
							{(selectedItem.client_id && selectedItem.client_phone) &&
								<div className="d-flex justify-content-between">
									<p>{t('phone')}</p>
									{selectedItem.client_phone &&
										<p>{selectedItem.client_phone}</p>
									}
								</div>
							}
							{(selectedItem.client_id && selectedItem.client_phone2) &&
								<div className="d-flex justify-content-between">
									<p>{t('phone')}2</p>
									{selectedItem.client_phone2 &&
										<p>{selectedItem.client_phone2}</p>
									}
								</div>
							}
							{(selectedItem.address_id && selectedItem.client_address) &&
								<div className="d-flex justify-content-between">
									<p>{t('address')}</p>
									{selectedItem.client_address &&
										<p className="text-end">{selectedItem.client_address}</p>
									}
								</div>
							}
							{Number(selectedItem.shortage) > 0 &&
								<div className="d-flex justify-content-between">
									<p>{t('shortage')}</p>
									{selectedItem.shortage &&
										<p className="text-end">{formatMoney(selectedItem.shortage)}</p>
									}
								</div>
							}
							{!!selectedItem.bool_no_profit &&
								<div className="d-flex justify-content-between">
									<p>{t('no_profit')}</p>
									<p className="text-end">{t('yes')}</p>
								</div>
							}
							{!!selectedItem.bool_no_service_percentage &&
								<div className="d-flex justify-content-between">
									<p>{t('no_service_percentage')}</p>
									<p className="text-end">{t('yes')}</p>
								</div>
							}
						</div>

						<div className="d-flex justify-content-between mb-3 px-2">
							<span>{t('action')}</span>
							<div className="d-flex gap-2">
								{!!selectedItem.deleteable &&
									<div className="table-action-button table-action-danger-button ms-2"
										onClick={() => toggleModalDelete(true)}>
										<i className="uil-times"></i>
									</div>
								}
								<div className="table-action-button table-action-primary-button"
									onClick={selectedItemToExcel}>
									<i className="uil uil-file-alt"></i>
								</div>
								<div className="table-action-button table-action-primary-button"
									onClick={handlePrint}>
									<i className="uil uil-print"></i>
								</div>
							</div>
						</div>
					</div>

					<div style={{ display: "none" }}>
						<div id="cheque" ref={printRef} className="color-dark-theme">
							<div className="d-flex justify-content-center mt-3 mb-1">
								<div className="d-flex flex-column">
									<div className="d-flex justify-content-center mb-1">
										<img src={logo} width={120} alt="logo" />
									</div>
									<h5 className="text-center fw-700 mb-1">{t('duplicate')}</h5>
									<h5 className="text-center fw-700 mb-1">{selectedItem.pos_name}</h5>
									<h6 className="text-center fw-600 mb-1">{t('phone')}: {selectedItem.pos_phone}</h6>
									<h6 className="text-center fw-500 mb-1">{t('address')}: {selectedItem.pos_address}</h6>
								</div>
							</div>

							<div className="cheque-block-1 cheque-border-bottom mb-2">
								<div className="d-flex justify-content-between px-2">
									<p className="fw-600">{t('cashier')}</p>
									<p>{selectedItem.cashier_first_name}</p>
								</div>
								<div className="d-flex justify-content-between px-2">
									<p className="fw-600">{t('waiter')}</p>
									<p>{selectedItem.waiter_first_name}</p>
								</div>
								<div className="d-flex justify-content-between px-2">
									<p className="fw-600">{t('table')}</p>
									<p>{selectedItem.table_name}</p>
								</div>
								<div className="d-flex justify-content-between px-2">
									<p className="fw-600">{t('cheque')} № </p>
									<p>{selectedItem.cheque_number}</p>
								</div>
								<div className="d-flex justify-content-between px-2">
									<p className="fw-600">{t('guests')}</p>
									<p>{selectedItem.people_number}</p>
								</div>
								{selectedItem.tin &&
									<div className="d-flex justify-content-between px-2">
										<p className="fw-600">{t('tin')}</p>
										<p>{selectedItem.tin}</p>
									</div>
								}
								<div className="d-flex justify-content-between px-2">
									<p className="fw-600">{t('order_type')} </p>
									{selectedItem.order_type === 1 &&
										<p>{t('hall')}</p>
									}
									{selectedItem.order_type === 2 &&
										<p>{t('takeaway')}</p>
									}
									{selectedItem.order_type === 3 &&
										<p>{t('delivery')}</p>
									}
								</div>
								<div className="d-flex justify-content-between px-2">
									<p className="fw-600">{t('open_date')}</p>
									<p>{formatDate(selectedItem.cheque_opened_at, 'dd.MM.yy HH:mm')}</p>
								</div>
								<div className="d-flex justify-content-between px-2">
									<p className="fw-600">{t('close_date')}</p>
									<p>{formatDate(selectedItem.cheque_closed_at, 'dd.MM.yy HH:mm')}</p>
								</div>
							</div>

							<div className="cheque-block-2 cheque-border-bottom px-2">
								<table className="custom-cheque-table w-100 fz-14">
									<thead>
										<tr>
											<th className="w-50">№ {t('product')}</th>
											<th>{t('quantity')}</th>
											<th className="text-end">{t('price')}</th>
										</tr>
									</thead>
									<tbody>
										{selectedItem?.products?.length > 0 &&
											<>
												{selectedItem?.products?.map((item, index) => (
													<tr className={`${item.status < 0 && 'del'}`} key={index}>
														{/* column 1 */}
														<td className="d-flex" style={{ 'whiteSpace': 'break-spaces' }}>
															<span>{index + 1} {item.name}</span>
														</td>
														{/* column 1 */}

														{/* column 2 */}
														<td className="text-left" style={{ 'verticalAlign': 'top' }}>
															<span className="text-nowrap" >{formatMoney(item.quantity)} X {formatMoney(item.sale_price)}</span>
														</td>
														{/* column 2 */}

														{/* column 3 */}
														<td className="text-end text-nowrap">
															{formatMoney(item.total_price)}
														</td>
														{/* column 3 */}
													</tr>
												))
												}
											</>
										}
									</tbody>
								</table>
							</div>

							<div className="cheque-block-3 cheque-border-bottom mb-2">
								<div className="d-flex justify-content-between px-2">
									<p className="fw-600">{t('sale_amount')}</p>
									<p>{formatMoney(selectedItem.total_amount)}</p>
								</div>
								{selectedItem.order_type === 1 &&
									<div className="d-flex justify-content-between px-2">
										<p className="fw-600">{t('service')} {selectedItem.service_percentage ? selectedItem.service_percentage : 0}%</p>
										<p>{formatMoney(selectedItem.total_amount * (selectedItem.service_percentage / 100))}</p>
									</div>
								}
								{!!selectedItem.delivery_price &&
									<div className="d-flex justify-content-between px-2">
										<p className="fw-600">{t('delivery')} [km] {selectedItem.delivery_distance}</p>
										<p>{formatMoney(selectedItem.delivery_price)}</p>
									</div>
								}
								{Number(selectedItem.discount) > 0 &&
									<div className="d-flex justify-content-between px-2">
										<p className="fw-600">{t('discount')}</p>
										<p>{formatMoney(selectedItem.discount)}</p>
									</div>
								}
								{Number(selectedItem.agent_amount) > 0 &&
									<div className="d-flex justify-content-between px-2">
										<p className="fw-600">{t('agents_bonus')}</p>
										<p className="fw-600">{formatMoney(selectedItem.agent_amount)}</p>
									</div>
								}
								<div className="d-flex justify-content-between px-2">
									<p className="fw-600 fz-30">{t('total_payable')}</p>
									<p className="fw-600 fz-30">{formatMoney(selectedItem.total_amount_service)}</p>
								</div>
								<div className="d-flex justify-content-between px-2">
									<p className="fw-600">{t('paid')}</p>
									<p className="fw-600">{formatMoney(selectedItem.paid)}</p>
								</div>

								{selectedItem?.payments?.map((item, index) => (
									<div className="d-flex justify-content-between px-2" key={index}>
										{Number(item.amount_in) !== 0 ?
											<>
												<p>{item.payment_type_name}</p>
												<p>{formatMoney(item.amount_in)}</p>
											</>
											:
											<>
												<p>{item.payment_purpose_name}</p>
												<p>{formatMoney(item.amount_out)}</p>
											</>
										}
									</div>
								))}
								{Number(selectedItem.client_debtor_amount) > 0 &&
									<div className="d-flex justify-content-between px-2">
										<p>{t('debtor')}</p>
										<p>{selectedItem.debtor_name}</p>
									</div>
								}
								{selectedItem.client_debtor_amount > 0 &&
									<div className="d-flex justify-content-between px-2">
										<p>{t('amount_of_debt')}</p>
										<p>{formatMoney(selectedItem.client_debtor_amount)}</p>
									</div>
								}
								{selectedItem.organization_name &&
									<div className="d-flex justify-content-between px-2">
										<p>{t('organization')}</p>
										<p>{selectedItem.organization_name}</p>
									</div>
								}
								{selectedItem.organization_amount > 0 &&
									<div className="d-flex justify-content-between px-2">
										<p>{t('amount_of_debt')}</p>
										<p>{formatMoney(selectedItem.organization_amount)}</p>
									</div>
								}
								{(selectedItem.client_id && selectedItem.client_name) &&
									<div className="d-flex justify-content-between px-2">
										<p>{t('client')}</p>
										{selectedItem.client_name &&
											<p>{selectedItem.client_name}</p>
										}
									</div>
								}
								{Number(selectedItem.loyalty) > 0 &&
									<div className="d-flex justify-content-between px-2">
										<p>{t('loyalty')}</p>
										<p>{formatMoney(selectedItem.loyalty)}</p>
									</div>
								}
								{(selectedItem.client_id && selectedItem.client_phone) &&
									<div className="d-flex justify-content-between px-2">
										<p>{t('phone')}</p>
										{selectedItem.client_phone &&
											<p>{selectedItem.client_phone}</p>
										}
									</div>
								}
								{(selectedItem.client_id && selectedItem.client_phone2) &&
									<div className="d-flex justify-content-between px-2">
										<p>{t('phone')}2</p>
										{selectedItem.client_phone2 &&
											<p>{selectedItem.client_phone2}</p>
										}
									</div>
								}
								{(selectedItem.address_id && selectedItem.client_address) &&
									<div className="d-flex justify-content-between px-2">
										<p>{t('address')}</p>
										{selectedItem.client_address &&
											<p className="text-end">{selectedItem.client_address}</p>
										}
									</div>
								}
								{Number(selectedItem.shortage) > 0 &&
									<div className="d-flex justify-content-between px-2">
										<p>{t('shortage')}</p>
										{selectedItem.shortage &&
											<p className="text-end">{formatMoney(selectedItem.shortage)}</p>
										}
									</div>
								}
								{!!selectedItem.bool_no_profit &&
									<div className="d-flex justify-content-between px-2">
										<p>{t('no_profit')}</p>
										<p className="text-end">{t('yes')}</p>
									</div>
								}
								{!!selectedItem.bool_no_service_percentage &&
									<div className="d-flex justify-content-between px-2">
										<p>{t('no_service_percentage')}</p>
										<p className="text-end">{t('yes')}</p>
									</div>
								}
							</div>
						</div>
					</div>
				</>
			}
		</>
	)
}

export default Rightbar
