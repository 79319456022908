import React, { useEffect, useState, useRef } from "react"
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Modal, Dropdown } from 'react-bootstrap'
import { useReactToPrint } from 'react-to-print';
import DatePicker from "react-datepicker"
import XLSX from 'xlsx'

import FilterPagination from 'pages/parts/FilterPagination'

import { GET, httpOk } from 'helpers/api'
import { formatMoney, formatDate, returnSign } from 'helpers/helpers'
import CustomTooltip from "pages/parts/CustomTooltip";

function Shift() {
	const { t } = useTranslation()
	const { cashbox_id } = useParams()
	const date = new Date()

	const printRef = useRef();

	const [data, setData] = useState([])
	const [activeTab, setActiveTab] = useState(1)
	const [showModal, setShowModal] = useState(false)
	const [xReportData, setXreportData] = useState({
		"x_report": {},
		"waiters": {},
		"products": {},
		"menu": {},
		"preparation_places": {},
	})
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [filterData, setFilterData] = useState({
		start_date: formatDate(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd'),
		end_date: formatDate(new Date(), 'yyyy-MM-dd'),
		pos_id: null,
		search: ''
	})
	const [pagination, setPagination] = useState({
		url: `/cashbox/shift/${cashbox_id}`,
		response: null
	})
	const [reports] = useState([
		{ 'id': 1, 'name': `X ${t('report')}`, 'active': true },
		{ 'id': 2, 'name': `${t('waiters')}`, 'active': false },
		{ 'id': 3, 'name': `${t('menu')}`, 'active': false },
		{ 'id': 4, 'name': `${t('products')}`, 'active': false },
		{ 'id': 5, 'name': `${t('preparation_places')}`, 'active': false },
	]);

	async function searchWithFilter() {
		var filterUrl = `/cashbox/shift/${cashbox_id}`
		var urlParams = ""
		checkFilter()

		if (filterData.start_date)
			urlParams += returnSign(urlParams) + 'start_date=' + filterData.start_date
		if (filterData.end_date)
			urlParams += returnSign(urlParams) + 'end_date=' + filterData.end_date
		if (filterData.page)
			urlParams += returnSign(urlParams) + 'page=' + filterData.page

		if (`/cashbox/shift/${cashbox_id}${urlParams}` !== filterUrl)
			setFilterDataExist(true)

		filterUrl = filterUrl + urlParams

		const response = await GET(filterUrl, {}, { loader: true })
		if (httpOk(response)) {
			setData(response.data)
			setPagination({ ...pagination, 'url': filterUrl, 'response': response.data })
		}

		setDropdownIsOpen(false)
	}

	async function toggleModalXreport(item) {
		var response = await GET(`/report-x2?pos_id=${item.pos_id}&cashbox_id=${item.cashbox_id}&shift_id=${item.id}`, {}, { loader: true })
		if (httpOk(response)) {
			response.data['x_report'].pos_name = item.pos_name
			response.data['x_report'].cashbox_name = item.cashbox_name
			response.data['x_report'].id = item.id
			response.data['x_report'].shift_number = item.shift_number
			response.data['x_report'].cashier_name = item.cashier_name
			response.data['x_report'].opened_at = item.opened_at
			response.data['x_report'].closed_at = item.closed_at
			setXreportData(response.data)
			setShowModal(true)
			setActiveTab(1)
		}
	}

	const handlePrint = useReactToPrint({
		content: () => printRef.current,
	});

	function exportToExcel(item) {
		var data = [
			{ "A": t('pos'), "B": item.pos_name },
			{ "A": t('cashbox'), "B": item.cashbox_name },
			{ "A": t('shift_number'), "B": item.shift_number },
			{ "A": t('cashier'), "B": item.cashier_name },
			{ "A": t('start_of_shift'), "B": formatDate(item.opened_at, 'dd.MM.yy HH:mm') },
			{ "A": t('end_of_shift'), "B": item.closed_at ? formatDate(item.closed_at, 'dd.MM.yy HH:mm') : '' },
			{ "A": '', "B": '' },
			{ "A": `${t('number_of_checks') + '[' + t('hall') + ']'}`, "B": item.count_hall },
			{ "A": `${t('number_of_checks') + '[' + t('takeaway') + ']'}`, "B": item.count_takeaway },
			{ "A": `${t('number_of_checks') + '[' + t('delivery') + ']'}`, "B": item.count_delivery },
			{ "A": `${t('number_of_checks')}`, "B": item.count_cheque },
			{ "A": '', "B": '' },
		];

		if (!!item.total_no_profit) {
			data.push({ "A": `${t('number_of_checks') + t('no_profit')}`, "B": item.total_no_profit })
		}

		if (!!item.total_no_service_percentage) {
			data.push({ "A": `${t('number_of_checks') + t('no_service_percentage')}`, "B": item.total_no_service_percentage })
		}

		data.push({ "A": `${t('sales')}`, "B": item.sales })
		data.push({ "A": `${t('service')}`, "B": item.service_percentage_amount })
		data.push({ "A": `${t('delivery')}`, "B": item.total_delivery_amount })
		data.push({ "A": `${t('sale_amount')}`, "B": item.total_sales })
		data.push({ "A": `${t('discount')}`, "B": item.total_discount })
		data.push({ "A": `${t('debt')}`, "B": item.total_client_debtor_amount })
		data.push({ "A": `${t('organization_debt')}`, "B": item.total_organization_amount })

		if (!!item.total_agent_amount) {
			data.push({ "A": `${t('agents_bonus')}`, "B": item.total_agent_amount })
		}

		if (!!item.loyalty) {
			data.push({ "A": `${t('loyalty')}`, "B": item.loyalty.amount_out })
		}

		data.push({
			"A": `${t('total')}`, "B": formatMoney(
				Number(item.total_discount) +
				Number(item.total_client_debtor_amount) +
				Number(item.total_organization_amount) +
				Number(item.total_agent_amount)
			)
		})

		if (!!item.total_return_debt) {
			data.push({ "A": `${t('total_checkout_sales')}`, "B": formatMoney(xReportData['x_report']?.total_payments - xReportData['x_report']?.total_return_debt) })
		}

		data.push({ "A": `${t('total_checkout')}`, "B": item.total_payments })

		if (!!item.loyalty) {
			data.push({ "A": `${t('loyalty')}`, "B": item?.loyalty?.amount_in })
		}

		for (let i = 0; i < item.payments.length; i++) {
			var dataLeft = ""
			var dataRight = 0
			if (Number(item.amount_in) !== 0) {
				dataLeft = t('income2') + ' ' + item.payments[i]['payment_type_name'] + ' ' + item.payments[i]['payment_purpose_name']
			} else {
				dataLeft = t('out_balance') + ' ' + item.payments[i]['payment_type_name'] + ' ' + item.payments[i]['payment_purpose_name']
			}

			if (Number(item.payments[i]['amount_in']) !== 0) {
				dataRight = item.payments[i]['amount_in']
			} else {
				dataRight = item.payments[i]['amount_out']
			}
			data.push({ "A": dataLeft, "B": dataRight })
		}

		if (!!item.total_cash) {
			data.push({ "A": `${t('total_cash')}`, "B": item.total_cash })
		}

		if (!!item.total_bank) {
			data.push({ "A": `${t('total_bank')}`, "B": item.total_bank })
		}

		if (!!item.total_expense) {
			data.push({ "A": `${t('total_expense')}`, "B": item.total_expense })
		}

		data.push({ "A": `${t('cashbox_balance')}`, "B": item.balance })

		var ws = XLSX.utils.json_to_sheet(data, { skipHeader: true });
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
		XLSX.writeFile(wb, t('shifts') + ".xlsx");
	}

	async function exportToExcelItems() {
		var filterUrl = `/shift-payments-excel?id=${xReportData['x_report'].id}`

		var excelHeaders = [['ID', t('amount'), t('discount')]]
		const response = await GET(filterUrl)

		if (httpOk(response)) {
			for (let i = 0; i < response.data.data.length; i++) {
				var item = response.data.data[i]
				var excelItems = []
				excelItems.push(item.cheque_id)
				excelItems.push(Number(item.amount_in))
				excelItems.push(Number(item.discount))
				excelHeaders.push(excelItems)
			}
			excelHeaders.push([])
			excelHeaders.push([t('cash'), response.data.cash_amount_in])
			excelHeaders.push([t('terminal'), response.data.terminal_amount_in])

			const ws = XLSX.utils.aoa_to_sheet(excelHeaders);
			const wb = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
			XLSX.writeFile(wb, t('payment') + ".xlsx");
		}
	}

	function EExcelWaiters() {
		var excelHeaders = [[t('waiters'), t('service'), t('amount')]]

		for (let i = 0; i < xReportData['waiters']?.data?.length; i++) {
			var item = xReportData['waiters']?.data[i]
			var excelItems = []
			excelItems.push(item.first_name)
			excelItems.push(item.total_service_amount)
			excelItems.push(item.total_amount)
			excelHeaders.push(excelItems)
		}
		excelHeaders.push([t('total'), xReportData['waiters']['total_service_amount'], xReportData['x_report']['total_amount']])

		const ws = XLSX.utils.aoa_to_sheet(excelHeaders);
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
		XLSX.writeFile(wb, t('waiters') + ".xlsx");
	}

	function EExcelMenu() {
		var excelHeaders = [[t('menu'), t('amount')]]

		for (let i = 0; i < xReportData['menu']?.menu?.length; i++) {
			var item = xReportData['menu']?.data[i]
			var excelItems = []
			excelItems.push(item.name)
			excelItems.push(item.total_sale_price)
			excelHeaders.push(excelItems)
		}
		excelHeaders.push([t('total'), xReportData['menu']['total_amount']])

		const ws = XLSX.utils.aoa_to_sheet(excelHeaders);
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
		XLSX.writeFile(wb, t('menu') + ".xlsx");
	}

	function EExcelProducts() {
		var excelHeaders = [[t('product'), t('quantity'), t('amount')]]

		for (let i = 0; i < xReportData['products']?.data?.length; i++) {
			var item = xReportData['products']?.data[i]
			var excelItems = []
			excelItems.push(item.name)
			excelItems.push(item.total_quantity)
			excelItems.push(item.total_sale_price)
			excelHeaders.push(excelItems)
		}
		excelHeaders.push([t('total'), xReportData['products']['total_quantity'], xReportData['products']['total_amount']])

		const ws = XLSX.utils.aoa_to_sheet(excelHeaders);
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
		XLSX.writeFile(wb, t('products') + ".xlsx");
	}

	function EExcelPreparationPlaces() {
		var excelHeaders = [[t('preparation_places'), t('amount')]]

		for (let i = 0; i < xReportData['preparation_places']?.data?.length; i++) {
			var item = xReportData['preparation_places']?.data[i]
			var excelItems = []
			excelItems.push(item.name)
			excelItems.push(item.total_sale_price)
			excelHeaders.push(excelItems)
		}
		excelHeaders.push([t('total'), xReportData['x_report']['total_amount']])

		const ws = XLSX.utils.aoa_to_sheet(excelHeaders);
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
		XLSX.writeFile(wb, t('preparation_places') + ".xlsx");
	}

	function resetFilter() {
		setFilterData({
			start_date: formatDate(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd'),
			end_date: formatDate(new Date(), 'yyyy-MM-dd'),
			page: 0,
		})
	}

	async function checkFilter() {
		if (
			filterData.start_date === null &&
			filterData.end_date === null
		) {
			setFilterDataExist(false)
		}
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	async function paginate(page) {
		setFilterData({ ...filterData, 'page': page })
	}

	useEffect(() => {
		searchWithFilter()
	}, [filterData.page]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between">
				<h4 className="vertical-center">
					{t('shifts')} [{data.total}]
				</h4>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex justify-content-between mb-3">
						<div className="d-flex gap-2">
							<div>
								<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
									<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100">
										{t('filter')}
									</Dropdown.Toggle>

									<Dropdown.Menu className="dropdown-filter">
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('period')}</label>
												<div className="calendar-input">
													<DatePicker
														className="form-control"
														dateFormat="dd.MM.yyyy"
														selected={filterData.start_date ? new Date(filterData.start_date) : ''}
														onChange={(date) => setFilterData({ ...filterData, 'start_date': formatDate(date, 'yyyy-MM-dd') })} />
													<i className="uil uil-calendar-alt"></i>
												</div>
											</div>
											<div className="form-group col-md-6">
												<label className="invisible">.</label>
												<div className="calendar-input">
													<DatePicker
														className="form-control"
														dateFormat="dd.MM.yyyy"
														selected={filterData.end_date ? new Date(filterData.end_date) : ''}
														onChange={(date) => setFilterData({ ...filterData, 'end_date': formatDate(date, 'yyyy-MM-dd') })} />
													<i className="uil uil-calendar-alt"></i>
												</div>
											</div>
										</div>
										<div className="row mt-3">
											<div className="col-md-12">
												<div className="d-flex justify-content-end gap-2">
													<button className="btn btn-secondary" onClick={() => setDropdownIsOpen(false)}>{t('cancel')}</button>
													<button className="btn btn-primary" onClick={() => searchWithFilter(false)}>{t('apply')}</button>
												</div>
											</div>
										</div>
									</Dropdown.Menu>
								</Dropdown>
							</div>
							<div>
								<button type="button" className="btn btn-outline-primary btn-wide-2"
									onClick={() => searchWithFilter(true)} title={t('EXCEL')}>
									<i className="uil uil-file-download-alt"></i>
								</button>
							</div>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block">
							<div className="filter">
								<strong>{t('filter')}</strong>
								<br />
								{(filterData.start_date || filterData.end_date) &&
									<span className="me-2">
										<b>{t('period')}: </b>
										{filterData.start_date &&
											<span>{formatDate(filterData.start_date)}</span>
										}
										{(filterData.start_date && filterData.end_date) &&
											<span>|</span>
										}
										{filterData.end_date &&
											<span>{formatDate(filterData.end_date)}</span>
										}
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase" onClick={resetFilter}>{t('reset')}</button>
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table table-bordered mb-0">
							<thead>
								<tr>
									<th style={{ 'minWidth': '100px' }}>ID</th>
									<th style={{ 'width': '10%' }}>{t('shift_number')}</th>
									<th style={{ 'width': '15%' }}>{t('cashier')}</th>
									<th style={{ 'width': '40%' }}></th>
									<th style={{ 'width': '10%' }} className="text-center">{t('open_date')}</th>
									<th style={{ 'width': '10%' }} className="text-center">{t('close_date')}</th>
									<th style={{ 'width': '15%' }} className="text-end">
										<CustomTooltip text={t(`${t('cashbox_balance')} = ${t('cash')} + ${t('terminal')} + ${t('debt_returned')} - ${t('expenses')}`)}>
											{t('cashbox_balance')}
										</CustomTooltip>
									</th>
								</tr>
							</thead>
							<tbody>
								{data?.data?.map((item, index) => (
									<tr key={index} className="bg-on-hover" onClick={() => toggleModalXreport(item)}>
										<td>{item.id}</td>
										<td>{item.shift_number}</td>
										<td className="td-ellipsis">{item.cashier_name}</td>
										<td></td>
										<td className="text-center">{formatDate(item.opened_at, 'dd.MM.yy HH:mm')}</td>
										<td className="text-center">{formatDate(item.closed_at, 'dd.MM.yy HH:mm')}</td>
										<td className="text-end">{formatMoney(item.balance)}</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>

					{pagination.response &&
						<FilterPagination
							pagination={pagination}
							paginate={paginate}>
						</FilterPagination>
					}
				</div>
			</div>

			{/* MODAL */}
			<Modal show={showModal} animation={false} centered
				size="lg" backdrop="static"
				onHide={() => setShowModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{t('additional_info')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="d-flex gap-3">
						<div style={{ 'width': '70%' }}>
							{activeTab === 1 &&
								<div>
									<div className="d-flex justify-content-between mb-1">
										<span>{t('pos')}</span>
										<span>{xReportData['x_report'].pos_name}</span>
									</div>
									<div className="d-flex justify-content-between mb-1">
										<span>{t('cashbox')}</span>
										<span>{xReportData['x_report'].cashbox_name}</span>
									</div>
									<div className="d-flex justify-content-between mb-1">
										<span>{t('shift')} ID</span>
										<span>{xReportData['x_report'].id}</span>
									</div>
									<div className="d-flex justify-content-between mb-1">
										<span>{t('shift_number')}</span>
										<span>{xReportData['x_report'].shift_number}</span>
									</div>
									<div className="d-flex justify-content-between mb-1">
										<span>{t('cashier')}</span>
										<span>{xReportData['x_report'].cashier_name}</span>
									</div>
									<div className="d-flex justify-content-between mb-1">
										<span>{t('start_of_shift')}</span>
										<span>{formatDate(xReportData['x_report'].opened_at, 'dd.MM.yy HH:mm')}</span>
									</div>
									<div className="d-flex justify-content-between mb-1">
										<span>{t('end_of_shift')}</span>
										<span>{formatDate(xReportData['x_report'].closed_at, 'dd.MM.yy HH:mm')}</span>
									</div>
									<hr />
									<div className="d-flex justify-content-between mb-1">
										<span>{t('number_of_checks')} [ {t('hall')} ]</span>
										<span>{xReportData['x_report'].count_hall}</span>
									</div>
									<div className="d-flex justify-content-between mb-1">
										<span>{t('number_of_checks')} [ {t('takeaway')} ]</span>
										<span>{xReportData['x_report'].count_takeaway}</span>
									</div>
									<div className="d-flex justify-content-between mb-1">
										<span>{t('number_of_checks')} [ {t('delivery')} ]</span>
										<span>{xReportData['x_report'].count_delivery}</span>
									</div>
									<div className="d-flex justify-content-between mb-1">
										<b>{t('number_of_checks')}</b>
										<b>{xReportData['x_report'].count_cheque}</b>
									</div>
									{!!(xReportData['x_report'].total_no_profit || xReportData['x_report'].total_no_service_percentage) &&
										<hr />
									}
									{!!xReportData['x_report'].total_no_profit &&
										<div className="d-flex justify-content-between mb-1">
											<span>{t('number_of_checks')} [ {t('no_profit')} ]</span>
											<span>{xReportData['x_report'].total_no_profit}</span>
										</div>
									}
									{!!xReportData['x_report'].total_no_service_percentage &&
										<div className="d-flex justify-content-between mb-1">
											<span>{t('number_of_checks')} [ {t('no_service_percentage')} ]</span>
											<span>{xReportData['x_report'].total_no_service_percentage}</span>
										</div>
									}
									{(xReportData && xReportData['x_report']?.payments?.length > 0) &&
										<hr />
									}
									<div className="d-flex justify-content-between fz14">
										<span>{t('sales')}</span>
										<span className="text-success">{formatMoney(xReportData['x_report']?.sales)}</span>
									</div>
									<div className="d-flex justify-content-between fz14">
										<span>{t('service')}</span>
										<span className="text-success">{formatMoney(xReportData['x_report']?.service_percentage_amount)}</span>
									</div>
									<div className="d-flex justify-content-between fz14">
										<span>{t('delivery')}</span>
										<span className="text-success">{formatMoney(xReportData['x_report']?.total_delivery_amount)}</span>
									</div>
									<div className="d-flex justify-content-between fz14">
										<b>{t('sale_amount')}</b>
										<b>{formatMoney(xReportData['x_report']?.total_sales)}</b>
									</div>
									<div className="ms-3">
										<div className="d-flex justify-content-between d-none">
											<p>{t('change')}</p>
											<p className="text-success">{formatMoney(xReportData['x_report']?.total_change)}</p>
										</div>
										<div className="d-flex justify-content-between">
											<p>{t('discount')}</p>
											<p className="text-danger">{formatMoney(xReportData['x_report']?.total_discount)}</p>
										</div>
										<div className="d-flex justify-content-between">
											<span>{t('debt')}</span>
											<span className="text-danger">{formatMoney(xReportData['x_report'].total_client_debtor_amount)}</span>
										</div>
										<div className="d-flex justify-content-between">
											<span>{t('organization_debt')}</span>
											<span className="text-danger">{formatMoney(xReportData['x_report'].total_organization_amount)}</span>
										</div>
										{!!Number(xReportData['x_report'].total_agent_amount) > 0 &&
											<div className="d-flex justify-content-between">
												<span>{t('agents_bonus')}</span>
												<span className="text-danger">{formatMoney(xReportData['x_report'].total_agent_amount)}</span>
											</div>
										}
										{xReportData['x_report'].loyalty &&
											<div className="d-flex justify-content-between">
												<span>{t('loyalty')}</span>
												<span>{formatMoney(xReportData['x_report']?.loyalty?.amount_out)}</span>
											</div>
										}
										<div className="d-flex justify-content-between">
											<b>
												<CustomTooltip text={t(`${t('total')} = ${t('discount')} + ${t('debt')} + ${t('shortage')} + ${t('agents_bonus')}`)}>
													{t('total')}
												</CustomTooltip>
											</b>
											<b className="text-danger">
												{formatMoney(
													Number(xReportData['x_report'].total_discount) +
													Number(xReportData['x_report'].total_client_debtor_amount) +
													Number(xReportData['x_report'].total_organization_amount) +
													Number(xReportData['x_report'].total_agent_amount)
												)}
											</b>
										</div>
									</div>
									{!!xReportData['x_report']?.total_return_debt &&
										<div className="d-flex justify-content-between fz14">
											<b>
												<CustomTooltip text={t(`${t('total_checkout')} = ${t('sale_amount')} - ${t('discount')} - ${t('agents_bonus')} - ${t('sold_on_credit')}`)}>
													{t('total_checkout_sales')}
												</CustomTooltip>
											</b>
											<b>{formatMoney(xReportData['x_report']?.total_payments - xReportData['x_report']?.total_return_debt)}</b>
										</div>
									}
									<div className="d-flex justify-content-between fz14">
										<b>
											<CustomTooltip text={t(`${t('total_checkout')} = ${t('sale_amount')} - ${t('discount')} - ${t('agents_bonus')} - ${t('sold_on_credit')} + ${t('amortization')}`)}>
												{t('total_checkout')}
											</CustomTooltip>
										</b>
										<b>{formatMoney(xReportData['x_report']?.total_payments)}</b>
									</div>
									<div className="ms-3">
										{xReportData['x_report'].loyalty &&
											<div className="d-flex justify-content-between">
												<span>{t('loyalty')}</span>
												<span>{formatMoney(xReportData['x_report']?.loyalty?.amount_in)}</span>
											</div>
										}
										{xReportData['x_report']?.payments?.map((item, index) => (
											<div className="d-flex justify-content-between" key={index}>
												<p>
													{Number(item.amount_in) !== 0 ?
														<span className="me-2">{t('income2')}</span>
														:
														<span className="me-2">{t('out_balance')}</span>
													}
													<span className="me-2">{item.payment_type_name}</span>
													<span>{item.payment_purpose_name}</span>
												</p>
												{Number(item.amount_in) !== 0 ?
													<p className="text-nowrap text-success">{formatMoney(item.amount_in)}</p>
													:
													<p className="text-nowrap text-danger">{formatMoney(item.amount_out)}</p>
												}
											</div>
										))}
										{!!xReportData['x_report'].total_cash &&
											<div className="d-flex justify-content-between">
												<b>{t('total_cash')}</b>
												<b className="text-success">
													{formatMoney(xReportData['x_report'].total_cash)}
												</b>
											</div>
										}
										{!!xReportData['x_report'].total_bank &&
											<div className="d-flex justify-content-between">
												<b>{t('total_bank')}</b>
												<b className="text-success">
													{formatMoney(xReportData['x_report'].total_bank)}
												</b>
											</div>
										}
										{!!xReportData['x_report'].total_expense &&
											<div className="d-flex justify-content-between">
												<b>{t('total_expense')}</b>
												<b className="text-danger">
													{formatMoney(xReportData['x_report'].total_expense)}
												</b>
											</div>
										}
									</div>
									<hr />
									<b className="d-flex justify-content-between">
										<h5 className="color-00">
											<CustomTooltip text={t(`${t('cashbox_balance')} = ${t('cash')} + ${t('debt_returned')} - ${t('expenses')}`)}>
												{t('cashbox_balance')}
											</CustomTooltip>
										</h5>
										<h5 className="text-no-wrap color-00">
											{formatMoney(xReportData['x_report'].balance)}
										</h5>
									</b>
									<div className="d-flex justify-content-between">
										<span>{t('action')}</span>
										<div className="d-flex gap-2">
											<div className="table-action-button table-action-primary-button"
												onClick={handlePrint}>
												<i className="uil-print"></i>
											</div>
											<div className="table-action-button table-action-primary-button"
												onClick={() => exportToExcel(xReportData['x_report'])}>
												<i className="uil-file-alt"></i>
											</div>
											<div className="table-action-button table-action-primary-button"
												onClick={() => exportToExcelItems()}>
												<i className="uil-file-alt"></i>
											</div>
										</div>
									</div>
								</div>
							}
							{activeTab === 2 &&
								<table className="table table-bordered table-bordered-custom">
									<thead>
										<tr>
											<th className="text-start"><b>{t('waiter')}</b></th>
											<th className="text-end"><b>{t('service')}</b></th>
											<th className="text-end"><b>{t('amount')}</b></th>
										</tr>
									</thead>
									<tbody>
										{xReportData['waiters']?.data?.map((item, index) => (
											<tr key={index}>
												<td className="text-start">{item.first_name}</td>
												<td className="text-end">{formatMoney(item.total_service_amount)}</td>
												<td className="text-end">{formatMoney(item.total_amount)}</td>
											</tr>
										))}
									</tbody>
									<tfoot>
										<tr>
											<td><b>{t('total')}</b></td>
											<td className="text-end">
												<b>{formatMoney(xReportData['waiters']?.total_service_amount)}</b>
											</td>
											<td className="text-end">
												<b>{formatMoney(xReportData['waiters']?.total_amount)}</b>
											</td>
										</tr>
									</tfoot>
								</table>
							}
							{activeTab === 3 &&
								<table className="table table-bordered table-bordered-custom">
									<thead>
										<tr>
											<th className="text-start"><b>{t('menu')}</b></th>
											<th className="text-end"><b>{t('amount')}</b></th>
										</tr>
									</thead>
									<tbody>
										{xReportData['menu']?.data?.map((item, index) => (
											<tr key={index}>
												<td className="text-start">{item.name}</td>
												<td className="text-end">{formatMoney(item.total_sale_price)}</td>
											</tr>
										))}
									</tbody>
									<tfoot>
										<tr>
											<td><b>{t('total')}</b></td>
											<td className="text-end">
												<b>{formatMoney(xReportData['menu']?.total_amount)}</b>
											</td>
										</tr>
									</tfoot>
								</table>
							}
							{activeTab === 4 &&
								<table className="table table-bordered table-bordered-custom">
									<thead>
										<tr>
											<th className="text-start"><b>{t('product')}</b></th>
											<th className="text-end"><b>{t('quantity')}</b></th>
											<th className="text-end"><b>{t('amount')}</b></th>
										</tr>
									</thead>
									<tbody>
										{xReportData['products']?.data?.map((item, index) => (
											<tr key={index}>
												<td className="text-start">{item.name}</td>
												<td className="text-end">{item.total_quantity}</td>
												<td className="text-end">{formatMoney(item.total_sale_price)}</td>
											</tr>
										))}
									</tbody>
									<tfoot>
										<tr>
											<td><b>{t('total')}</b></td>
											<td className="text-end">
												<b>{formatMoney(xReportData['products']?.total_quantity)}</b>
											</td>
											<td className="text-end">
												<b>{formatMoney(xReportData['products']?.total_amount)}</b>
											</td>
										</tr>
									</tfoot>
								</table>
							}
							{activeTab === 5 &&
								<table className="table table-bordered table-bordered-custom">
									<thead>
										<tr>
											<th className="text-start"><b>{t('preparation_places')}</b></th>
											<th className="text-end"><b>{t('amount')}</b></th>
										</tr>
									</thead>
									<tbody>
										{xReportData['preparation_places']?.data?.map((item, index) => (
											<tr key={index}>
												<td className="text-start">{item.name}</td>
												<td className="text-end">{formatMoney(item.total_sale_price)}</td>
											</tr>
										))}
									</tbody>
									<tfoot>
										<tr>
											<td><b>{t('total')}</b></td>
											<td className="text-end">
												<b>{formatMoney(xReportData['preparation_places']?.total_amount)}</b>
											</td>
										</tr>
									</tfoot>
								</table>
							}
						</div>
						<div style={{ 'width': '30%' }}>
							<div className="right" style={{ 'overflow': 'auto' }}>
								{reports.map((item, index) => (
									<div className="d-flex justify-content-between mb-2" key={index}
										style={activeTab === item.id ? { 'borderBottom': '1px solid #000' } : {}}>
										<h6 className="w-75"
											onClick={() => setActiveTab(item.id)}>
											{item.name}
										</h6>
										{item.id === 1 &&
											<div className="w-25 text-end" onClick={() => exportToExcel()}>
												<i className="uil uil-file-download-alt"></i>
											</div>
										}
										{item.id === 2 &&
											<div className="w-25 text-end" onClick={() => EExcelWaiters()}>
												<i className="uil uil-file-download-alt"></i>
											</div>
										}
										{item.id === 3 &&
											<div className="w-25 text-end" onClick={() => EExcelMenu()}>
												<i className="uil uil-file-download-alt"></i>
											</div>
										}
										{item.id === 4 &&
											<div className="w-25 text-end" onClick={() => EExcelProducts()}>
												<i className="uil uil-file-download-alt"></i>
											</div>
										}
										{item.id === 5 &&
											<div className="w-25 text-end" onClick={() => EExcelPreparationPlaces()}>
												<i className="uil uil-file-download-alt"></i>
											</div>
										}
									</div>
								))}
							</div>
						</div>
					</div>
				</Modal.Body>
			</Modal>
			{/* MODAL */}

			<div id="print" className="d-none">
				<div className="color-00 p-2" ref={printRef}>
					<div className="d-flex justify-content-center mb-1">
						<div className="d-flex flex-column">
							<div className="d-flex justify-content-center mb-1">
								{/* <img src={logo} alt="" width="130" /> */}
							</div>
							<span className="text-center text-uppercase fz14">X {t('report')}</span>
						</div>
					</div>
					<div className="d-flex justify-content-between mb-1 fz14">
						<span>{t('pos')}</span>
						<span>{xReportData['x_report'].posName}</span>
					</div>
					<div className="d-flex justify-content-between mb-1 fz14">
						<span>{t('cashbox')}</span>
						<span>{xReportData['x_report'].cashboxName}</span>
					</div>
					<div className="d-flex justify-content-between mb-1 fz14">
						<span>{t('shift_number')}</span>
						<span>{xReportData['x_report'].shiftNumber}</span>
					</div>
					<div className="d-flex justify-content-between mb-1 fz14">
						<span>{t('cashier')}</span>
						<span>{xReportData['x_report'].cashierName}</span>
					</div>
					<div className="d-flex justify-content-between mb-1 fz14">
						<span>{t('start_of_shift')}</span>
						<span>{xReportData['x_report'].shiftOpenDate ? formatDate(xReportData['x_report'].shiftOpenDate, 'dd.MM.yy HH:mm') : ''}</span>
					</div>
					{xReportData['x_report'].shiftCloseDate &&
						<div className="d-flex justify-content-between mb-1 fz14">
							<span>{t('end_of_shift')}</span>
							<span>{xReportData['x_report'].shiftCloseDate ? formatDate(xReportData['x_report'].shiftCloseDate, 'dd.MM.yy HH:mm') : ''}</span>
						</div>
					}
					**************************************************************************************
					<div className="d-flex justify-content-between mb-1 fz14">
						<span>{t('number_of_checks')} [ {t('hall')} ]</span>
						<span>{xReportData['x_report'].count_hall}</span>
					</div>
					<div className="d-flex justify-content-between mb-1 fz14">
						<span>{t('number_of_checks')} [ {t('takeaway')} ]</span>
						<span>{xReportData['x_report'].count_takeaway}</span>
					</div>
					<div className="d-flex justify-content-between mb-1 fz14">
						<span>{t('number_of_checks')} [ {t('delivery')} ]</span>
						<span>{xReportData['x_report'].count_delivery}</span>
					</div>
					<div className="d-flex justify-content-between mb-1 fz14">
						<span>{t('number_of_checks')}</span>
						<span>{xReportData['x_report'].count_cheque}</span>
					</div>
					**************************************************************************************
					<div className="d-flex justify-content-between mb-1 fz14">
						<p>{t('sale_amount')}</p>
						<p>{formatMoney(xReportData['x_report']?.total_sales)}</p>
					</div>
					<div className="d-flex justify-content-between mb-1 fz14">
						<p>{t('received_cashbox')}</p>
						<p>{formatMoney(xReportData['x_report']?.total_payments)}</p>
					</div>
					{xReportData['x_report']?.payments?.map((item, index) => (
						<div className="d-flex justify-content-between px-2" key={index}>
							<p>{item.payment_purpose_name} {item.payment_type_name}</p>
							{Number(item.amount_in) !== 0 ?
								<p>{formatMoney(item.amount_in)}</p>
								:
								<p>{formatMoney(item.amount_out)}</p>
							}
						</div>
					))
					}
					{xReportData['x_report'].debt > 0 &&
						<div className="d-flex justify-content-between mb-1 fz14">
							<span>{t('sold_on_credit')}</span>
							<span>{formatMoney(xReportData['x_report'].debt)}</span>
						</div>
					}
					<div className="d-flex justify-content-between mb-1 fz14">
						<p>{t('discount')}</p>
						<p>{formatMoney(xReportData['x_report']?.total_discount)}</p>
					</div>
					**************************************************************************************
					<div className="d-flex justify-content-between mb-1">
						<h5 className="color-00"><b>{t('cashbox_balance')}</b></h5>
						<div>
							<h5 className="text-no-wrap color-00">
								<b>{formatMoney(xReportData['x_report'].balance)}</b>
							</h5>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default Shift
