import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Modal } from 'react-bootstrap'
import { Dropdown } from 'react-bootstrap'
import Select from 'react-select'
import DatePicker from "react-datepicker"
import XLSX from 'xlsx'
import { toast } from "react-toastify"

import { GET, POST, PUT, httpOk } from 'helpers/api'
import {
	formatMoney, returnSign, formatDate,
	sumFromArray, returnMinDate, formatMoneyInput, copyText
} from "helpers/helpers"
import CustomTooltip from "pages/parts/CustomTooltip"

function Index() {
	const { t } = useTranslation()

	const reduxAccount = useSelector(state => state.account)

	const date = new Date()

	const salaryTypes = [
		{ 'id': 1, 'name': t('schedule') },
		{ 'id': 2, 'name': t('hourly') },
		{ 'id': 3, 'name': t('salary') },
		{ 'id': 4, 'name': 'KPI' },
		{ 'id': 5, 'name': t('arbitary') },
	]
	const [data, setData] = useState({ 'data': [] })
	const [ppt, setPPT] = useState([])
	const [poses, setPoses] = useState([])
	const [payments, setPayments] = useState([])
	const [accounts, setAccounts] = useState([])
	const [accountsChef, setAccountsChef] = useState([])
	const [modalPayment, setModalPayment] = useState(false)
	const [modalInfo, setModalInfo] = useState(false)
	const [selectedItem, setSelectedItem] = useState({})
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [filterData, setFilterData] = useState({
		start_date: formatDate(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd'),
		end_date: formatDate(new Date(), 'yyyy-MM-dd'),
		pos_id: reduxAccount?.pos_id,
	})

	async function searchWithFilter(filterParams = { tableSort: "", passedFilterData: {} }) {
		var filterDataCopy = { ...filterData }
		if (filterParams.passedFilterData && Object.keys(filterParams.passedFilterData).length > 0) {
			filterDataCopy = filterParams.passedFilterData
		}

		var filterUrl = "/employee-salary"
		var urlParams = ""
		checkFilter()

		if (filterDataCopy.pos_id)
			urlParams += returnSign(urlParams) + 'pos_id=' + filterDataCopy.pos_id
		if (filterDataCopy.start_date)
			urlParams += returnSign(urlParams) + 'start_date=' + filterDataCopy.start_date
		if (filterDataCopy.end_date)
			urlParams += returnSign(urlParams) + 'end_date=' + filterDataCopy.end_date

		filterUrl = filterUrl + urlParams

		const response = await GET(filterUrl, {}, { loader: true })
		if (httpOk(response)) {
			setData(response.data)
			setDropdownIsOpen(false)
		}
	}

	async function exportToExcel() {
		var excelHeaders = [[
			t('employee'), 'KPI', t('role'), t('positions'), t('role'), t('payment'),
			t('balance2'), t('salary'), t('hours'), t('sales'), t('gross_income'),
			t('fines'), t('general_payments'), t('remaining_end')
		]]

		data.data.forEach(item => {
			var excelItems = []
			excelItems.push(item.first_name)
			if (item.bonus_percentage) {
				excelItems.push(item.bonus_percentage)
			}
			if (item.items_bonus) {
				excelItems.push(item.items_bonus)
			}
			if (item.items_bonus_fixed) {
				excelItems.push(item.items_bonus_fixed)
			}
			if (
				!item.bonus_percentage &&
				!item.items_bonus &&
				!item.items_bonus_fixed
			) {
				excelItems.push(0)
			}
			excelItems.push(item.role)
			excelItems.push(item.position)
			if (item.salary_type_id) {
				excelItems.push(salaryTypes.find(e => e.id === item.salary_type_id)['name'])
			}
			excelItems.push(item.salary ?? 0)
			excelItems.push('')
			excelItems.push(item.working_hours ? item.working_hours : 0)
			excelItems.push(item?.sales ?? 0)
			excelItems.push(item?.gross ?? 0)
			if (data.kpi_type_id === 1) {
				excelItems.push(item?.bonus_sales ?? 0)
			}
			if (data.kpi_type_id === 2) {
				excelItems.push(item?.bonus ?? 0)
			}
			excelItems.push('')
			excelItems.push(item.total_amount ?? 0)
			excelItems.push(item.balance_end ?? 0)
			excelHeaders.push(excelItems)
		});
		const ws = XLSX.utils.aoa_to_sheet(excelHeaders);
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
		XLSX.writeFile(wb, t('salary') + ".xlsx");
	}

	async function createPayment(e) {
		e.preventDefault();
		var selectedItemCopy = { ...selectedItem }

		var response
		if (selectedItem.first_name) {
			selectedItemCopy.created_at = formatDate(selectedItemCopy.created_at, 'yyyy-MM-dd')
			response = await POST('/employee-payment', selectedItemCopy, { loader: true })
		} else {
			selectedItemCopy.created_at = formatDate(selectedItemCopy.created_at, 'yyyy-MM-dd')
			response = await PUT('/employee-payment', selectedItemCopy, { loader: true })
		}
		if (httpOk(response)) {
			searchWithFilter()
			setModalPayment(false)
		}
	}

	function handleClickEmployee(employee) {
		var pos = poses.find(item => item.id === employee.pos_id)

		employee.safe_balance = pos.safe_balance
		employee.bank_balance = pos.bank_balance
		setSelectedItem(employee)
	}

	function toggleModalPayment() {
		if (!selectedItem.id) {
			toast.error(t('choose_employee'))
			return
		}

		var selectedItemCopy = { ...selectedItem }
		selectedItemCopy.payment_purpose_id = 7
		selectedItemCopy.amount = ''
		selectedItemCopy.note = ''
		selectedItemCopy.created_at = new Date()

		setSelectedItem(selectedItemCopy)
		setModalPayment(true)
	}

	function openUpdateModal(item) {
		item.update = true
		setSelectedItem(item)
		setModalInfo(false)
		setModalPayment(true)
	}

	async function toggleModalInfo() {
		if (!selectedItem.id) {
			toast.error(t('choose_employee'))
			return
		}
		const response = await GET('/employee-detail', {
			'login': selectedItem.login,
			'start_date': filterData.start_date,
			'end_date': filterData.end_date,
		}, { loader: true })
		if (httpOk(response)) {
			setSelectedItem({ ...selectedItem, 'payments': response.data })
			setModalInfo(true)
		}
	}

	async function getEmployeePayments() {
		const response = await GET('/helper/employee-payments', {})
		if (httpOk(response)) setPayments(response.data)
	}

	function checkFilter() {
		if (
			filterData.pos_id === null
		) {
			setFilterDataExist(false)
		}
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	async function getAccountsChef() {
		const response = await GET('/helper/accounts-chef')
		if (httpOk(response)) setAccountsChef(response.data)
	}

	async function getAccounts() {
		const response = await GET('/helper/accounts')
		if (httpOk(response)) setAccounts(response.data)
	}

	async function getPPT() {
		const response = await GET('/helper/ppt-expense?counterparty=employee_salary')
		if (httpOk(response)) setPPT(response.data)
	}

	async function getPoses() {
		const response = await GET('/helper/poses-head')
		if (httpOk(response)) setPoses(response.data)
	}

	useEffect(() => {
		searchWithFilter()
		getPoses()
		getEmployeePayments()
		getAccounts()
		getAccountsChef()
		getPPT()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between">
				<h4 className="vertical-center h-100">
					{t('salary')} [{data?.data?.length}]
				</h4>
				<div className="d-flex gap-2">
					<button className="btn-rounded btn btn-primary"
						onClick={() => toggleModalInfo()}>
						{t('information')}
					</button>
					<button className="btn-rounded btn btn-primary"
						onClick={() => toggleModalPayment()}>
						{t('payment')}
					</button>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex justify-content-between mb-3">
						<div className="d-flex gap-2">
							<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
								<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100">
									{t('filter')}
								</Dropdown.Toggle>

								<Dropdown.Menu className="dropdown-filter">
									<div className="row">
										<div className="form-group col-md-6">
											<label>{t('pos')}</label>
											<Select
												options={poses}
												value={poses.find(option => option.id === filterData.pos_id) || ''}
												onChange={(option) => setFilterData({ ...filterData, 'pos_id': option.id })}
												placeholder=""
												noOptionsMessage={() => t('list_empty')}
												getOptionLabel={(option) => option.name}
												getOptionValue={(option) => option.id}
											/>
										</div>
									</div>
									<div className="row">
										<div className="form-group col-md-6">
											<label>{t('period')}</label>
											<div className="calendar-input">
												<DatePicker
													className="form-control"
													dateFormat="dd.MM.yyyy"
													selected={filterData.start_date ? new Date(filterData.start_date) : ''}
													onChange={(date) => setFilterData({ ...filterData, 'start_date': formatDate(date, 'yyyy-MM-dd') })} />
												<i className="uil uil-calendar-alt"></i>
											</div>
										</div>
										<div className="form-group col-md-6">
											<label className="invisible">.</label>
											<div className="calendar-input">
												<DatePicker
													className="form-control"
													dateFormat="dd.MM.yyyy"
													selected={filterData.end_date ? new Date(filterData.end_date) : ''}
													onChange={(date) => setFilterData({ ...filterData, 'end_date': formatDate(date, 'yyyy-MM-dd') })} />
												<i className="uil uil-calendar-alt"></i>
											</div>
										</div>
									</div>
									<div className="row mt-3">
										<div className="col-md-12">
											<div className="d-flex justify-content-end">
												<button className="btn btn-secondary me-2" onClick={() => setDropdownIsOpen(false)}>{t('cancel')}</button>
												<button className="btn btn-primary" onClick={() => searchWithFilter()}>{t('apply')}</button>
											</div>
										</div>
									</div>
								</Dropdown.Menu>
							</Dropdown>
							<div>
								<button className="btn btn-outline-primary btn-wide-2"
									onClick={() => exportToExcel()}>
									<CustomTooltip text={t('EXCEL')}>
										<i className="uil uil-file-download-alt"></i>
									</CustomTooltip>
								</button>
							</div>
						</div>
						<div className="d-flex gap-2">
							<div className="vertical-center">
								<h4><b>{t('salary')} + KPI</b>:</h4>
							</div>
							<div className="vertical-center text-end">
								<h4>{formatMoney(data.overall_bonus_and_salary)}</h4>
							</div>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block">
							<div className="filter">
								<strong>{t('filter')}</strong>
								<br />
								{(filterData.start_date || filterData.end_date) &&
									<span className="me-2">
										<b>{t('period')}: </b>
										{filterData.start_date &&
											<span>{formatDate(filterData.start_date)}</span>
										}
										{(filterData.start_date && filterData.end_date) &&
											<span>|</span>
										}
										{filterData.end_date &&
											<span>{formatDate(filterData.end_date)}</span>
										}
									</span>
								}
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table table-bordered mb-0">
							<thead>
								<tr>
									<th style={{ 'width': '33px' }} className="text-center">#</th>
									<th className="text-nowrap">{t('employee')}</th>
									<th className="text-nowrap">KPI</th>
									<th className="text-nowrap">{t('role')}</th>
									<th className="text-nowrap">{t('positions')}</th>
									<th className="text-nowrap">{t('payment')}</th>
									<th className="text-end">{t('balance2')}</th>
									<th className="text-end">{t('salary')}</th>
									<th className="text-end">{t('hours')}</th>
									<th className="text-end">{t('sales')}</th>
									<th className="text-end td-ellipsis"
										title={t('gross_income')}>
										{t('gross_income')}
									</th>
									<th className="text-end">KPI</th>
									<th className="text-end">{t('fines')}</th>
									<th className="text-end td-ellipsis"
										title={t('general_payments')} style={{ 'minWidth': '80px' }}>
										{t('general_payments')}
									</th>
									<th className="text-end td-ellipsis"
										title={t('remaining_end')} style={{ 'minWidth': '80px' }}>
										{t('remaining_end')}
									</th>
								</tr>
							</thead>
							<tbody>
								{data?.data?.map((item, index) => (
									<tr key={index}
										className={`cursor ${selectedItem.id === item.id ? 'table-tr-active' : ''}`}
										onClick={() => handleClickEmployee(item)}>
										<td className="text-center">{index + 1}</td>
										<td onClick={() => copyText(item.login)}>
											<CustomTooltip text={item.login}>
												{item.first_name}
											</CustomTooltip>
										</td>
										<td>
											{item.bonus_percentage &&
												<span>{formatMoney(item.bonus_percentage)}%</span>
											}
											{item.bonus_fixed &&
												<span>{formatMoney(item.bonus_fixed)}</span>
											}
											{item.items_bonus &&
												<span>{formatMoney(item.items_bonus)}%</span>
											}
											{item.items_bonus_fixed &&
												<span>{formatMoney(item.items_bonus_fixed)}</span>
											}
											{(
												!item.bonus_fixed &&
												!item.bonus_percentage &&
												!item.items_bonus &&
												!item.items_bonus_fixed
											) &&
												<span>0</span>
											}
										</td>
										<td>{item.role}</td>
										<td>{item.position}</td>
										<td>
											{item.salary_type_id &&
												<span>{salaryTypes.find(e => e.id === item.salary_type_id)['name']}</span>
											}
										</td>
										<td></td>
										<td className="text-nowrap text-end">{formatMoney(item.salary)}</td>
										<td className="text-nowrap text-end">{item.working_hours ? item.working_hours : 0}</td>
										<td className="text-nowrap text-end">
											{formatMoney(item?.sales)}
										</td>
										<td className="text-nowrap text-end">
											{formatMoney(item?.gross)}
										</td>
										{data.kpi_type_id === 1 &&
											<td className="text-nowrap text-end">{formatMoney(item.bonus_sales)}</td>
										}
										{data.kpi_type_id === 2 &&
											<td className="text-nowrap text-end">{formatMoney(item.bonus)}</td>
										}
										<td className="text-end">{formatMoney(item.total_fines)}</td>
										<td className="text-nowrap text-end">{formatMoney(item.total_amount)}</td>
										<td className="text-nowrap text-end">
											{(item.salary_type_id === 1 || item.salary_type_id === 2 || item.salary_type_id === 3) &&
												<span className={(Number(item.salary) > Number(item.total_amount)) ? 'text-danger' : 'text-success'}>
													{formatMoney(item.balance_end)}
												</span>
											}
											{(item.salary_type_id === 4) &&
												<span className={(Number(item.bonus) > Number(item.total_amount)) ? 'text-danger' : 'text-success'}>
													{formatMoney(item.balance_end)}
												</span>
											}
											{(item.salary_type_id === 5) &&
												<span className="text-success">
													{formatMoney(item.balance_end)}
												</span>
											}
										</td>
									</tr>
								))}
								<tr>
									<td></td>
									<td><b>{t('total')}</b></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td className="text-end text-nowrap"><b>{formatMoney(data.overall_salary)}</b></td>
									<td className="text-end text-nowrap"><b>{data.overall_working_hours}</b></td>
									<td className="text-end text-nowrap"><b>{formatMoney(data.overall_sales)}</b></td>
									<td className="text-end text-nowrap"><b>{formatMoney(data.overall_gross)}</b></td>
									{data.kpi_type_id === 1 &&
										<td className="text-end text-nowrap"><b>{formatMoney(data.overall_bonus_sales)}</b></td>
									}
									{data.kpi_type_id === 2 &&
										<td className="text-end text-nowrap"><b>{formatMoney(data.overall_bonus)}</b></td>
									}
									<td className="text-end text-nowrap"><b>{formatMoney(data.overall_total_fines)}</b></td>
									<td className="text-end text-nowrap">
										<b className="text-success">{formatMoney(data.overall_total_amount)}</b>
									</td>
									<td className="text-end text-nowrap text-danger"><b>{formatMoney(data.overall_balance_end)}</b></td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>

			{/* MODAL PAYMENT*/}
			<Modal
				show={modalPayment}
				animation={false} centered
				dialogClassName="update-modal-width"
				backdrop="static"
				onHide={() => setModalPayment(false)}>
				<Modal.Header closeButton>
					<Modal.Title>
						{selectedItem.first_name ?
							<span>{selectedItem.first_name}</span>
							:
							<span>{t('edit')}</span>
						}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={createPayment} autoComplete="off">
						<div className="form-group">
							<label>{t('pos')}</label>
							<Select
								isDisabled
								options={poses}
								value={poses.find(option => option.id === selectedItem.pos_id)}
								placeholder=""
								noOptionsMessage={() => t('list_empty')}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>
						<div className="form-group">
							<label>{t('date')}</label>
							<div className="calendar-input">
								<DatePicker
									disabled={selectedItem.update}
									className="form-control"
									dateFormat="dd.MM.yyyy"
									selected={selectedItem.created_at ? new Date(selectedItem.created_at) : ''}
									onChange={(date) => setSelectedItem({ ...selectedItem, 'created_at': formatDate(date, 'yyyy-MM-dd') })}
									minDate={returnMinDate(new Date(), reduxAccount.backdate ?? 3)}
									maxDate={new Date()}
								/>
								<i className="uil uil-calendar-alt"></i>
							</div>
						</div>
						<div className="form-group">
							<label>{t('payment')}<span className="required-mark">*</span></label>
							<Select
								isDisabled={selectedItem.update}
								options={payments}
								value={payments.find(option => option.id === selectedItem.payment_purpose_id)}
								onChange={(option) => setSelectedItem({ ...selectedItem, 'payment_purpose_id': option.id })}
								placeholder=""
								noOptionsMessage={() => t('list_empty')}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>
						{selectedItem.payment_purpose_id !== 139 &&
							<>
								<div className="form-group">
									<label>{t('expense_type')}</label>
									<Select
										isDisabled={selectedItem.update}
										options={ppt}
										value={ppt.find(option => option.id === selectedItem.payment_purpose_id)}
										onChange={(option) => setSelectedItem({
											...selectedItem, 'payment_purpose_id': option.id,
											'account_chef_id': option.account_chef_id,
											'account_core_id': option.account_core_id,
										})}
										placeholder=""
										noOptionsMessage={() => t('list_empty')}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
									/>
								</div>
								<div className="form-group">
									<label>{t('operation_account')}</label>
									<Select
										isClearable
										options={accountsChef}
										value={accountsChef.find(option => option.id === selectedItem.account_chef_id)}
										onChange={(option) => setSelectedItem({ ...selectedItem, 'account_chef_id': option ? option.id : null })}
										placeholder=""
										noOptionsMessage={() => t('list_empty')}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
									/>
								</div>
								<div className="form-group">
									<label>{t('report_account')}</label>
									<Select
										isDisabled
										options={accounts}
										value={accounts.find(option => option.id === selectedItem.account_core_id)}
										onChange={(option) => setSelectedItem({ ...selectedItem, 'account_core_id': option ? option.id : null })}
										placeholder=""
										noOptionsMessage={() => t('list_empty')}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
									/>
								</div>
							</>
						}
						<div className="form-group">
							<label>{t('amount')}<span className="required-mark">*</span></label>
							<div className="position-relative">
								<input type="text" className="form-control" name="amount"
									value={selectedItem.amount ? formatMoneyInput(selectedItem.amount) : ''}
									onChange={(e) => setSelectedItem({ ...selectedItem, 'amount': e.target.value.replace(/[^0-9.]/g, '') })} />
								<i className="input-inner-icon uil uil-transaction"
									onClick={() => setSelectedItem({ ...selectedItem, 'amount': selectedItem.balance_end.toFixed(0) })}></i>
							</div>
						</div>
						<div className="form-group">
							<label>{t('note')}</label>
							<input type="text" className="form-control" name="note"
								value={selectedItem.note}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'note': e.target.value })} />
						</div>
						<div className="d-flex w-100 mt-3">
							<button type="button" className="btn btn-outline-warning w-100 me-2" onClick={() => setModalPayment(false)}>{t('cancel')}</button>
							<button type="submit" className="btn btn-primary w-100">{t('save')}</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL PAYMENT*/}

			{/* MODAL INFO*/}
			<Modal show={modalInfo} animation={false} centered size="lg"
				dialogClassName="update-modal-width" backdrop="static"
				onHide={() => setModalInfo(false)}>
				<Modal.Header closeButton>
					<Modal.Title>
						{selectedItem.first_name}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="table-responsive">
						<table className="table table-bordered mb-0">
							<thead>
								<tr>
									<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
									<th style={{ 'width': '15%' }} className="text-nowrap">{t('date')}</th>
									<th style={{ 'width': '15%' }} className="text-start text-nowrap">{t('type')}</th>
									<th style={{ 'width': '45%' }} className="text-start text-nowrap">{t('note')}</th>
									<th style={{ 'width': '25%' }} className="text-end text-nowrap">{t('amount')}</th>
									<th style={{ 'minWidth': '33px' }} className="text-end"></th>
								</tr>
							</thead>
							<tbody>
								{selectedItem?.payments?.map((item, index) => (
									<tr key={index}>
										<td className="text-center">{index + 1}</td>
										<td className="text-nowrap">{formatDate(item.created_at, 'dd.MM.yy HH:mm')}</td>
										<td className="text-start">{item.name}</td>
										<td className="text-start">{item.note}</td>
										<td className="text-end text-nowrap">{formatMoney(item.amount)}</td>
										<td>
											{!item.changed_by &&
												<div className="d-flex justify-content-end">
													<div className="table-action-button table-action-primary-button"
														onClick={() => openUpdateModal(item)}>
														<i className="uil-edit-alt"></i>
													</div>
												</div>
											}
										</td>
									</tr>
								))}
								<tr>
									<td></td>
									<td><b>{t('total')}</b></td>
									<td></td>
									<td></td>
									<td className="text-end text-nowrap">
										<b>{formatMoney(sumFromArray(selectedItem?.payments, 'amount'))}</b>
									</td>
									<td></td>
								</tr>
							</tbody>
						</table>
					</div>
				</Modal.Body>
			</Modal>
			{/* MODAL INFO*/}
		</>
	)
}

export default Index