import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import DeleteModal from 'pages/parts/DeleteModal'

import { GET, DELETE, httpOk } from "helpers/api"
import { formatDate } from 'helpers/helpers'

function Preview() {
	const { t } = useTranslation()
	const { id } = useParams()
	const navigate = useNavigate()

	const [data, setData] = useState({})
	const [modalDelete, setModalDelete] = useState(false)
	const [deleteModalParams, setDeleteModalParams] = useState({ id: 0, index: 0 })

	async function deleteItem() {
		const response = await DELETE(`/wms-transfer?id=${deleteModalParams.id}`, {}, { loader: true })
		if (httpOk(response)) {
			var dataCopy = { ...data }
			dataCopy.productList.content[deleteModalParams.index]['status'] = 2
			setData(dataCopy)
			setModalDelete(false)
		}
	}

	async function getData() {
		const response = await GET(`/wms-transfer/${id}`, {}, { loader: true })
		if (httpOk(response)) {
			setData(response.data)
		}
	}

	useEffect(() => {
		getData()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between">
				<div className="d-flex text-truncate">
					<h4 className="vertical-center">
						{t('transfer')}
					</h4>
					<div className="vertical-center mt-1">
						<i className="uil uil-angle-double-right fz-20"></i>
					</div>
					<div className="vertical-center mt-1">
						<h6>{t('preview')}</h6>
					</div>
				</div>
			</div>

			<div className="card mb-3">
				<div className="card-body">
					<h4 className="fw-600">{t('document_from')} {formatDate(data.created_at)}</h4>
					<div className="flex-column preview-info">
						<div className="d-flex">
							<div className="fw-600">{t('pos')}:</div>
							<div>{data.pos_name}</div>
						</div>
						<div className="d-flex">
							<div className="fw-600">{t('note')}:</div>
							<div>{data.note}</div>
						</div>
						<div className="d-flex">
							<div className="fw-600">{t('document')}:</div>
							<div>{data.document_number}</div>
						</div>
					</div>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="table-responsive">
						<table className="table table-bordered">
							<thead>
								<tr>
									<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
									<th style={{ 'width': '30%' }}>{t('name2')}</th>
									<th style={{ 'width': '10%' }} className="text-end">{t('quantity')}</th>
									<th style={{ 'width': '60%' }}></th>
									<th style={{ 'minWidth': '33px' }}></th>
								</tr>
							</thead>
							<tbody>
								{data?.items?.map((item, index) => (
									<tr key={index}>
										<td className="text-center">{index + 1}</td>
										<td>{item.element_name}</td>
										<td className="text-end">{item.quantity}</td>
										<td></td>
										<td>
											<div className="d-flex justify-content-end">
												{item.status === -1 &&
													<span className="text-danger">{t('deleted')}</span>
												}
												{(item.status !== -1 && data.changeable) &&
													<div className="table-action-button table-action-danger-button"
														onClick={() => {
															setDeleteModalParams({ id: item.id, index: index });
															setModalDelete(true)
														}}>
														<i className="uil uil-times"></i>
													</div>
												}
											</div>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>

					<div className="d-flex justify-content-end mt-5">
						<button type="button" className="btn btn-warning btn-rounded btn-wide"
							onClick={() => navigate(-1)}>
							{t('cancel')}
						</button>
					</div>
				</div>
			</div>

			<DeleteModal
				modalDelete={modalDelete}
				setModalDelete={setModalDelete}
				deleteItem={deleteItem}>
			</DeleteModal>
		</>
	)
}

export default Preview