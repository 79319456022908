import React, { Fragment, useEffect, useState } from "react"
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Modal } from 'react-bootstrap'

import { GET, httpOk } from "helpers/api"
import { formatMoney, formatDate } from 'helpers/helpers'

function Preview() {
	const { t } = useTranslation()
	const { id } = useParams()
	const navigate = useNavigate()

	const [data, setData] = useState({})
	const [selectedItem, setSelectedItem] = useState({
		"id": null,
		"name": "",
		"items": []
	})
	const [showModal, setShowModal] = useState(false)

	async function handleSelectItem(item) {
		const response = await GET(`/write-off-item-detail/${item.id}`, {}, { loader: true })
		if (httpOk(response)) {
			setSelectedItem({ ...selectedItem, 'id': item.id, 'name': item.name, 'items': response.data })
			toggleModal(true)
		}
	}

	function toggleModal(bool) {
		if (bool) {
			setShowModal(true)
		} else {
			setSelectedItem({
				"id": null,
				"name": "",
				"items": []
			})
			setShowModal(false)
		}
	}

	async function getData() {
		const response = await GET(`/write-off/${id}`, {}, { loader: true })
		if (httpOk(response)) setData(response.data)
	}

	useEffect(() => {
		getData()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps 

	return (
		<>
			<div className="page-header d-flex justify-content-between">
				<div className="d-flex text-truncate">
					<h4 className="vertical-center">
						{t('write_off')}
					</h4>
					<div className="vertical-center mt-1">
						<i className="uil uil-angle-double-right fz-20"></i>
					</div>
					<div className="vertical-center mt-1">
						<h6>{t('preview')}</h6>
					</div>
				</div>
			</div>

			<div className="card mb-3">
				<div className="card-body">
					<h4 className="fw-600">{t('document_from')} {formatDate(data.created_at)}</h4>
					<div className="flex-column preview-info">
						<div className="d-flex">
							<div className="fw-600">{t('pos')}:</div>
							<div>{data.pos_name}</div>
						</div>
						<div className="d-flex">
							<div className="fw-600">{t('warehouse')}:</div>
							<div>{data.wms_name}</div>
						</div>
						<div className="d-flex">
							<div className="fw-600">{t('document')}:</div>
							<div>{data.document_number}</div>
						</div>
						<div className="d-flex">
							<div className="fw-600">{t('note')}:</div>
							<div>{data.note}</div>
						</div>
					</div>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="table-responsive">
						<table className="table table-bordered table-hover cursor">
							<thead>
								<tr>
									<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
									<th style={{ 'width': '20%' }}>{t('name2')}</th>
									<th style={{ 'width': '50%' }}>{t('type')}</th>
									<th style={{ 'width': '10%' }} className="text-end">{t('price')}</th>
									<th style={{ 'width': '10%' }} className="text-end">{t('quantity')}</th>
									<th style={{ 'width': '10%' }} className="text-end">{t('amount')}</th>
								</tr>
							</thead>
							<tbody>
								{data?.items?.map((item, index) => (
									<tr key={index} onClick={() => handleSelectItem(item)}
										className={(selectedItem.id === item.id) ? 'table-tr-active' : ''}>
										<td className="text-center">{index + 1}</td>
										<td>{item.product_name}</td>
										<td>
											{item.in_product === 0 &&
												<span>{t('element')}</span>
											}
											{item.in_product === 1 &&
												<span>{t('product')}</span>
											}
											{item.in_product === 2 &&
												<span>{t('dish_preparation')}</span>
											}
											{item.in_product === 3 &&
												<span>{t('modifiers')}</span>
											}
											{item.in_product === 4 &&
												<span>{t('SERVICE2')}</span>
											}
										</td>
										<td className="text-end">{formatMoney(item.cost_price)}</td>
										<td className="text-end">{item.quantity}</td>
										<td className="text-end">{formatMoney(item.cost_price * item.quantity)}</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>

					<div className="w-100 d-flex justify-content-end">
						<div className="flex-column w-50">
							<h5 className="d-flex justify-content-end text-nowrap">
								<strong className="me-5">{t('amount')}:</strong>
								<span>{formatMoney(data.total_cost_price)}</span>
							</h5>
						</div>
					</div>

					<div className="d-flex justify-content-end mt-5">
						<button type="button" className="btn btn-warning btn-rounded btn-wide"
							onClick={() => navigate(-1)}>
							{t('cancel')}
						</button>
					</div>
				</div>
			</div>

			{/* MODAL BALANCE */}
			<Modal show={showModal} animation={false} centered size="lg"
				dialogClassName="update-modal-width" onHide={() => setShowModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>
						{selectedItem.name}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="table-responsive">
						<table className="table">
							<thead>
								<tr>
									<th style={{ 'width': '1%' }}>#</th>
									<th style={{ 'width': '20%' }}>{t('name2')}</th>
									<th style={{ 'width': '10%' }}>{t('quantity')}</th>
									<th style={{ 'width': '10%' }}>{t('unit')}</th>
									<th style={{ 'width': '10%' }} className="text-end">{t('cost_price')}</th>
									<th style={{ 'width': '10%' }} className="text-end">{t('amount')}</th>
									<th style={{ 'width': '10%' }} className="text-end">{t('before')}</th>
									<th style={{ 'width': '10%' }} className="text-end">{t('after')}</th>
								</tr>
							</thead>
							<tbody>
								{selectedItem.items?.map((item, index) => (
									<Fragment>
										{item.in_product === 0 &&
											<tr key={index}>
												<td>{index + 1}</td>
												<td>{item.element_name}</td>
												<td>{item.quantity}</td>
												<td>{item.unit_name}</td>
												<td className="text-end">{formatMoney(item.cost_price)}</td>
												<td className="text-end">{formatMoney(item.cost_price * item.quantity)}</td>
												<td className="text-end">{formatMoney(item.balance_before, 2)}</td>
												<td className="text-end">{formatMoney(item.balance_now, 2)}</td>
											</tr>
										}
										{item.in_product === 2 &&
											<Fragment>
												<tr key={index}>
													<td>{index + 1} <b>{item.element_name}</b></td>
													<td>{item.quantity}</td>
													<td>{item.unit_name}</td>
													<td className="text-end">{formatMoney(item.cost_price)}</td>
													<td className="text-end">{formatMoney(item.cost_price * item.quantity)}</td>
													<td className="text-end">{formatMoney(item.balance_before, 2)}</td>
													<td className="text-end">{formatMoney(item.balance_now, 2)}</td>
												</tr>
												{item?.items?.map((item, index) => (
													<tr key={index}>
														<td><b className="ms-5">{item.element_name}</b></td>
														<td>{item.quantity}</td>
														<td>{item.unit_name}</td>
														<td className="text-end">{formatMoney(item.cost_price)}</td>
														<td className="text-end">{formatMoney(item.cost_price * item.quantity)}</td>
														<td className="text-end">{formatMoney(item.balance_before, 2)}</td>
														<td className="text-end">{formatMoney(item.balance_now, 2)}</td>
													</tr>
												))}
											</Fragment>
										}
									</Fragment>
								))}
							</tbody>
						</table>
					</div>
				</Modal.Body>
			</Modal>
			{/* MODAL BALANCE */}
		</>
	)
}

export default Preview