import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { Modal, Dropdown } from 'react-bootstrap'
import Select from 'react-select'
import DatePicker from "react-datepicker"

import FilterSearch from 'pages/parts/FilterSearch'
import FilterPagination from 'pages/parts/FilterPagination'

import { POST, PUT, GET, httpOk } from 'helpers/api'
import { formatDate, regexNumeric, regexPhoneNumber, returnSign, findKeyFromArrayByValue, formatMoney } from 'helpers/helpers'
import CustomTooltip from "pages/parts/CustomTooltip"

function Index() {
	const { t } = useTranslation()

	const [poses, setPoses] = useState([])
	const [selectedItem, setSelectedItem] = useState({})
	const [showModal, setShowModal] = useState(false)
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [filterData, setFilterData] = useState({
		pos_id: null,
		search: '',
	})
	const [pagination, setPagination] = useState({
		url: '/organizations',
		response: null
	})
	const [data, setData] = useState([])

	async function searchWithFilter() {
		var filterUrl = "/organizations"
		var urlParams = ""
		checkFilter()

		if (filterData.pos_id)
			urlParams += returnSign(urlParams) + 'pos_id=' + filterData.pos_id
		if (filterData.registration_type)
			urlParams += returnSign(urlParams) + 'registration_type=' + filterData.registration_type
		if (filterData.start_date)
			urlParams += returnSign(urlParams) + 'start_date=' + filterData.start_date
		if (filterData.end_date)
			urlParams += returnSign(urlParams) + 'end_date=' + filterData.end_date
		if (filterData.search)
			urlParams += returnSign(urlParams) + 'search=' + filterData.search
		if (filterData.page)
			urlParams += returnSign(urlParams) + 'page=' + filterData.page

		if (`/organizations${urlParams}` !== filterUrl)
			setFilterDataExist(true)

		filterUrl = filterUrl + urlParams

		const response = await GET(filterUrl, {}, { loader: true })
		if (httpOk(response)) {
			setData(response.data)
			setPagination({ ...pagination, 'url': filterUrl, 'response': response.data })
		}

		setDropdownIsOpen(false)
	}

	async function exportToExcel() {
		// filterUrl = "/organizations-excel" + urlParams + returnSign(filterUrl)

		// var excelHeaders = [[t('phone'), t('name'), t('address'), t('cheques'), t('last_activity'), t('registered')]]
		// const response = await GET(filterUrl)
		// if (httpOk(response)) {
		// 	for (let i = 0; i < response.data.length; i++) {
		// 		var item = response.data[i]
		// 		var excelItems = []
		// 		excelItems.push(item['phone1'])
		// 		excelItems.push(`${item['first_name']}`)
		// 		excelItems.push(item['address'])
		// 		excelItems.push(item['count_cheques'])
		// 		excelItems.push(formatDate(item['cheque_closed_at']))
		// 		excelItems.push(formatDate(item['created_at']))
		// 		excelHeaders.push(excelItems)
		// 	}

		// 	const ws = XLSX.utils.aoa_to_sheet(excelHeaders);
		// 	const wb = XLSX.utils.book_new();
		// 	XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
		// 	XLSX.writeFile(wb, t('organizations') + ".xlsx");
		// }
	}

	function resetFilter() {
		setFilterData({
			pos_id: null,
			start_date: null,
			end_date: null,
			search: '',
		})
		setFilterDataExist(false)
	}

	function checkFilter() {
		if (
			filterData.pos_id === null &&
			filterData.start_date === null &&
			filterData.end_date === null &&
			filterData.search === ''
		) {
			setFilterDataExist(false)
		}
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	async function paginate(page) {
		setFilterData({ ...filterData, 'page': page })
	}

	async function createUpdate(e) {
		e.preventDefault()
		var sendData = { ...selectedItem }
		sendData.phone1 = '998' + regexNumeric(sendData.phone1)
		if (sendData.phone2)
			sendData.phone2 = '998' + regexNumeric(sendData.phone2)

		var response;
		if (sendData.id) {
			response = await PUT('/organizations', sendData, { loader: true })
		} else {
			response = await POST('/organizations', sendData, { loader: true })
		}

		if (httpOk(response)) {
			searchWithFilter()
			setShowModal(false)
		}
	}

	function toggleModal(bool, item) {
		if (bool) {
			var itemCopy = { ...item }
			if (itemCopy.organization_phone)
				itemCopy.organization_phone = itemCopy.organization_phone.substring(3, 12)
			if (itemCopy.director_phone)
				itemCopy.director_phone = itemCopy.director_phone.substring(3, 12)
			if (itemCopy.accountant_phone)
				itemCopy.accountant_phone = itemCopy.accountant_phone.substring(3, 12)

			setSelectedItem(itemCopy)
			setShowModal(true)
		} else {
			setShowModal(false)
		}

	}

	async function getPoses() {
		const response = await GET('/helper/poses')
		if (httpOk(response)) {
			setPoses(response.data)
		}
	}

	useEffect(() => {
		getPoses()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		searchWithFilter()
	}, [filterData.search, filterData.page]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between">
				<h4 className="vertical-center">
					{t('organizations')} [{formatMoney(data?.total)}]
				</h4>
				<div className="d-flex">
					<button className="btn-rounded btn btn-primary"
						onClick={() => toggleModal(true)}>
						{t('create')}
					</button>
				</div>
			</div>

			<div className="card">
				<div className="card-body">

					<div className="d-flex gap-2 mb-3">
						<div>
							<FilterSearch
								filterData={filterData}
								setFilterData={setFilterData}>
							</FilterSearch>
						</div>
						<div>
							<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
								<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100">
									{t('filter')}
								</Dropdown.Toggle>

								<Dropdown.Menu className="dropdown-filter">
									<div className="row">
										<div className="form-group col-md-6">
											<label>{t('pos')}</label>
											<Select
												options={poses}
												value={poses.find(option => option.id === filterData.pos_id) || ''}
												onChange={(option) => setFilterData({ ...filterData, 'pos_id': option.id })}
												placeholder=""
												noOptionsMessage={() => t('list_empty')}
												getOptionLabel={(option) => option.name}
												getOptionValue={(option) => option.id}
											/>
										</div>
									</div>
									<div className="row">
										<div className="form-group col-md-6">
											<label>{t('period')}</label>
											<div className="calendar-input">
												<DatePicker
													className="form-control"
													dateFormat="dd.MM.yyyy"
													selected={filterData.start_date ? new Date(filterData.start_date) : ''}
													onChange={(date) => setFilterData({ ...filterData, 'start_date': formatDate(date, 'yyyy-MM-dd') })} />
												<i className="uil uil-calendar-alt"></i>
											</div>
										</div>
										<div className="form-group col-md-6">
											<label className="invisible">.</label>
											<div className="calendar-input">
												<DatePicker
													className="form-control"
													dateFormat="dd.MM.yyyy"
													selected={filterData.end_date ? new Date(filterData.end_date) : ''}
													onChange={(date) => setFilterData({ ...filterData, 'end_date': formatDate(date, 'yyyy-MM-dd') })} />
												<i className="uil uil-calendar-alt"></i>
											</div>
										</div>
									</div>
									<div className="row mt-3">
										<div className="col-md-12">
											<div className="d-flex justify-content-end">
												<button className="btn btn-secondary me-2" onClick={() => setDropdownIsOpen(false)}>{t('cancel')}</button>
												<button className="btn btn-primary" onClick={() => searchWithFilter()}>{t('apply')}</button>
											</div>
										</div>
									</div>
								</Dropdown.Menu>
							</Dropdown>
						</div>
						<div>
							<button className="btn btn-outline-primary btn-wide-2"
								onClick={() => exportToExcel()}>
								<CustomTooltip text={t('EXCEL')}>
									<i className="uil uil-file-download-alt"></i>
								</CustomTooltip>
							</button>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block">
							<div className="filter">
								<strong>{t('filter')}</strong>
								<br />
								{(filterData.start_date || filterData.end_date) &&
									<span className="me-2">
										<b>{t('period')}: </b>
										{filterData.start_date &&
											<span>{formatDate(filterData.start_date)}</span>
										}
										{(filterData.start_date && filterData.end_date) &&
											<span>|</span>
										}
										{filterData.end_date &&
											<span>{formatDate(filterData.end_date)}</span>
										}
									</span>
								}
								{filterData.pos_id &&
									<span className="me-2">
										<b>{t('pos')}: </b>
										{findKeyFromArrayByValue(poses, 'id', filterData.pos_id, 'name')}
									</span>
								}
								{filterData.search &&
									<span className="me-2">
										<b>{t('search')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase" onClick={resetFilter}>{t('reset')}</button>
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table table-bordered mb-0">
							<thead>
								<tr>
									<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
									<th style={{ 'width': '20%' }}>{t('name2')}</th>
									<th style={{ 'width': '20%' }} className="td-ellipsis">{t('legal_name')}</th>
									<th style={{ 'width': '10%' }} className="td-ellipsis">{t('tin')}</th>
									<th style={{ 'width': '10%' }} className="td-ellipsis">{t('organization_phone')}</th>
									<th style={{ 'width': '10%' }} className="text-center">
										{t('cheques')}
									</th>
									<th style={{ 'width': '10%' }} className="td-ellipsis text-center">{t('last_activity')}</th>
									<th style={{ 'width': '10%' }} className="td-ellipsis text-center">{t('registered')}</th>
									<th style={{ 'width': '10%' }} className="td-ellipsis text-center">{t('note')}</th>
									<th style={{ 'minWidth': '33px' }}></th>
								</tr>
							</thead>
							<tbody>
								{data?.data?.map((item, index) => (
									<tr key={index}>
										<td className="text-center">
											<CustomTooltip text={item.id}>
												{item.row_number}
											</CustomTooltip>
										</td>
										<td>{item.name}</td>
										<td>{item.legal_name}</td>
										<td>{item.tin}</td>
										<td className="text-nowrap">{regexPhoneNumber(item.organization_phone)}</td>
										<td className="text-center">{item.count_cheques}</td>
										<td className="text-center">{formatDate(item.cheque_closed_at)}</td>
										<td className="text-center">{formatDate(item.created_at)}</td>
										<td>{item.note}</td>
										<td>
											<div className="table-dropdown">
												<Dropdown id={`dropdown-${index}`}>
													<Dropdown.Toggle variant="outline-primary">
														<i className="uil uil-ellipsis-v"></i>
													</Dropdown.Toggle>

													<Dropdown.Menu>
														<div className="table-dropdown-item"
															onClick={() => toggleModal(true, item)}>
															<i className="uil-edit-alt"></i>
															{t('edit')}
														</div>
													</Dropdown.Menu>
												</Dropdown>
											</div>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>

					{pagination.response &&
						<FilterPagination
							pagination={pagination}
							paginate={paginate}>
						</FilterPagination>
					}
				</div>
			</div>

			{/* MODAL */}
			<Modal
				show={showModal}
				animation={false}
				dialogClassName="update-modal-width"
				centered
				backdrop="static"
				onHide={() => toggleModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>
						{selectedItem.id ?
							<>{t('edit')}</>
							:
							<>{t('create')}</>
						}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={createUpdate} autoComplete="off">
						<div className="form-group">
							<label>{t('name2')}<span className="required-mark">*</span></label>
							<input type="text" className="form-control" name="name"
								value={selectedItem.name ?? ''}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'name': e.target.value })} />
						</div>
						<div className="form-group">
							<label>{t('tin')}</label>
							<input type="number" className="form-control" name="tin"
								value={selectedItem.tin ?? ''}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'tin': e.target.value })} />
						</div>
						<div className="form-group">
							<label>{t('bank_account')}</label>
							<input type="number" className="form-control" name="checking_account"
								value={selectedItem.checking_account ?? ''}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'checking_account': e.target.value })} />
						</div>
						<div className="form-group">
							<label>{t('organization_phone')}</label>
							<div className="input-group">
								<span className="input-group-text">998</span>
								<input type="number" className="form-control"
									value={selectedItem.organization_phone ?? ''}
									onChange={(e) => setSelectedItem({ ...selectedItem, 'organization_phone': e.target.value })} />
							</div>
						</div>
						<div className="form-group">
							<label>{t('director_phone')}</label>
							<div className="input-group">
								<span className="input-group-text">998</span>
								<input type="number" className="form-control"
									value={selectedItem.director_phone ?? ''}
									onChange={(e) => setSelectedItem({ ...selectedItem, 'director_phone': e.target.value })} />
							</div>
						</div>
						<div className="form-group">
							<label>{t('accountant_phone')}</label>
							<div className="input-group">
								<span className="input-group-text">998</span>
								<input type="number" className="form-control"
									onChange={(e) => setSelectedItem({ ...selectedItem, 'accountant_phone': e.target.value })} />
							</div>
						</div>
						<div className="form-group">
							<label>{t('address')}</label>
							<input type="text" className="form-control" name="address"
								value={selectedItem.address ?? ''}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'address': e.target.value })} />
						</div>
						<div className="form-group">
							<label>{t('note')}</label>
							<input type="text" className="form-control" name="note"
								value={selectedItem.note ?? ''}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'note': e.target.value })} />
						</div>
						<div className="d-flex gap-2 w-100 mt-3">
							<button type="button" className="btn btn-outline-warning w-100" onClick={() => toggleModal(false)}>{t('cancel')}</button>
							<button type="submit" className="btn btn-primary w-100">{t('save')}</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL */}
		</>
	)
}

export default Index
