import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { Modal } from 'react-bootstrap'
import Select from 'react-select'

import { POST, PUT, GET, httpOk } from 'helpers/api'
import { formatMoney } from "helpers/helpers"

function Index() {
	const { t } = useTranslation()

	const [units, setUnits] = useState([])
	const [categories, setCategories] = useState([])
	const [showModal, setShowModal] = useState(false)
	const [selectedItem, setSelectedItem] = useState({})
	const [data, setData] = useState([])

	async function createUpdate(e) {
		e.preventDefault()

		if (selectedItem.id) {
			await PUT('/admin/elements', selectedItem, { loader: true })
		} else {
			await POST('/admin/elements', selectedItem, { loader: true })
			var selectedItemCopy = { ...selectedItem }
			selectedItemCopy.name = ''
			selectedItemCopy.unit_id = ''
			selectedItemCopy.second_unit_id = null
			selectedItemCopy.quantity = ''
			setSelectedItem(selectedItemCopy)
			searchWithFilter()
		}
	}

	function toggleModal(bool, item) {
		if (bool) {
			var itemCopy = { ...item }
			setSelectedItem(itemCopy)
			setShowModal(true)
		} else {
			setSelectedItem({})
			setShowModal(false)
		}
	}

	async function searchWithFilter() {
		const response = await GET(`/admin/elements`, {}, { loader: true })
		if (httpOk(response)) setData(response.data)
	}

	async function getParentCategories() {
		const response = await GET(`/admin/elements-parent`, {}, { loader: true })
		if (httpOk(response)) setCategories(response.data)
	}

	async function getUnits() {
		const response = await GET('/helper/units')
		if (httpOk(response)) setUnits(response.data)
	}

	useEffect(() => {
		searchWithFilter()
		getParentCategories()
		getUnits()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between">
				<h4 className="vertical-center">
					{t('elements')}
				</h4>
				<div className="d-flex">
					<button className="btn-rounded btn btn-primary"
						onClick={() => setShowModal(true)}>
						{t('create')}
					</button>
				</div>
			</div>

			<div className="card">
				<div className="card-body">

					<div className="table-responsive">
						<table className="table table-bordered mb-0">
							<thead>
								<tr>
									<th style={{ 'width': '1%' }}>#</th>
									<th style={{ 'width': '25%' }}>{t('name2')}</th>
									<th style={{ 'width': '10%' }}>{t('unit')}</th>
									<th style={{ 'width': '5%' }}>{t('rebuild')}</th>
									<th style={{ 'width': '60%' }}></th>
								</tr>
							</thead>
							<tbody>
								{data?.map((item, index) => (
									<tr key={index}>
										<td>{index + 1}</td>
										<td>{item.name}</td>
										<td>
											{item?.unit_name}
											{item.second_unit_name &&
												<span>
													<i className="uil uil-arrow-right"></i>
													{item?.second_unit_name}
												</span>
											}
										</td>
										<td>{formatMoney(item.quantity)}</td>
										<td>
											<div className="d-flex justify-content-end">
												<div className="table-action-button table-action-primary-button" onClick={() => toggleModal(true, item)}>
													<i className="uil-edit-alt"></i>
												</div>
											</div>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>

			{/* MODAL */}
			<Modal show={showModal} animation={false} centered dialogClassName="update-modal-width"
				backdrop="static" onHide={() => toggleModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>
						{selectedItem.id ?
							<>{t('edit')}</>
							:
							<>{t('create')}</>
						}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={createUpdate} autoComplete="off">
						<div className="form-group">
							<label>{t('category')}</label>
							<Select
								options={categories}
								value={categories.find(option => option.id === selectedItem.parent_id) || ''}
								onChange={(option) => setSelectedItem({ ...selectedItem, 'parent_id': option.id })}
								placeholder=""
								noOptionsMessage={() => t('list_empty')}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>
						<div className="form-group">
							<label>{t('title')}<span className="required-mark">*</span></label>
							<input type="text" className="form-control" name="name"
								value={selectedItem.name}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'name': e.target.value })} />
						</div>
						<div className="form-group mb-3">
							<label>{t('unit')}<span className="required-mark">*</span></label>
							<Select
								options={units}
								value={units.find(option => option.id === selectedItem.unit_id) ?? null}
								onChange={(option) => setSelectedItem({ ...selectedItem, 'unit_id': option.id })}
								placeholder=""
								noOptionsMessage={() => t('list_empty')}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>
						<h4>{t('rebuild')}</h4>
						<hr />
						<div className="form-group">
							<label>{t('unit')}<span className="required-mark">*</span></label>
							<Select
								options={units}
								value={units.find(option => option.id === selectedItem.second_unit_id) ?? null}
								onChange={(option) => setSelectedItem({ ...selectedItem, 'second_unit_id': option.id })}
								placeholder=""
								noOptionsMessage={() => t('list_empty')}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>
						<div className="form-group">
							<label>{t('quantity')}</label>
							<input type="number" className="form-control" name="quantity"
								value={selectedItem.quantity ?? ''}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'quantity': e.target.value })} />
						</div>
						<div className="d-flex gap-2 w-100 mt-3">
							<button type="button" className="btn btn-outline-warning w-100"
								onClick={() => toggleModal(false)}>
								{t('cancel')}
							</button>
							<button type="submit" className="btn btn-primary w-100">{t('save')}</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL */}
		</>
	)
}

export default Index
