import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { Dropdown, Modal } from 'react-bootstrap'
import Select from 'react-select'
import QRCode from "qrcode.react"

import DeleteModal from 'pages/parts/DeleteModal'
import { returnSign, findKeyFromArrayByValue } from "helpers/helpers";
import { DELETE, FILE, GET, httpOk, POST, PUT } from 'helpers/api'
import { smiles } from "helpers/smiles"

function Index() {
	const { t } = useTranslation()

	const statuses = [
		{ "id": 1, "name": t('success') },
		{ "id": -1, "name": t('deleted') },
	]

	const [showModal, setShowModal] = useState(false)
	const [showQrModal, setShowQrModal] = useState(false)
	const [showGallery, setShowGallery] = useState(false)
	const [qrValue, setQrValue] = useState("")
	const [selectedItem, setSelectedItem] = useState({ 'name': '', 'showSmiles': false })
	const [data, setData] = useState([])
	const [poses, setPoses] = useState([])
	const [menuGallery, setMenuGallery] = useState([])
	const [modalDelete, setModalDelete] = useState(false)
	const [deleteModalParams, setDeleteModalParams] = useState({ id: 0, index: 0 })
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [filterData, setFilterData] = useState({
		status: 1,
	})

	async function createUpdate(e) {
		e.preventDefault();

		var selectedItemCopy = { ...selectedItem }

		var response;
		if (selectedItemCopy.id) {
			response = await PUT('/menu', selectedItemCopy, { loader: true })
		} else {
			response = await POST('/menu', selectedItemCopy, { loader: true })
		}

		if (httpOk(response)) {
			searchWithFilter()
			setShowModal(false)
			setShowGallery(false)
			setSelectedItem({ 'name': '' })
		}
	}

	async function searchWithFilter() {
		var filterUrl = "/menu"
		var urlParams = ""
		checkFilter()

		if (filterData.status)
			urlParams += returnSign(urlParams) + 'status=' + filterData.status

		if (`/menu${urlParams}` !== filterUrl)
			setFilterDataExist(true)

		filterUrl = filterUrl + urlParams

		const response = await GET(filterUrl, {}, { loader: true })
		if (httpOk(response)) {
			setData(response.data)
			setDropdownIsOpen(false)
		}
	}

	function resetFilter() {
		setFilterData({
			status: 1,
		})
		setFilterDataExist(false)
	}

	function checkFilter() {
		if (filterData.status === null) {
			setFilterDataExist(false)
		}
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	function toggleModal(bool, item = {}) {
		if (bool) {
			setSelectedItem(item)
			setShowModal(true)
		} else {
			setSelectedItem({ 'name': '' })
			setShowModal(false)
			setShowGallery(false)
		}
	}

	async function deleteItem() {
		const response = await DELETE('/menu', { 'id': deleteModalParams.id }, { loader: true })
		if (httpOk(response)) {
			searchWithFilter()
			setModalDelete(false)
		}
	}

	function downloadQRCode() {
		const canvas = document.getElementById("qr-gen");
		const pngUrl = canvas
			.toDataURL("image/png")
			.replace("image/png", "image/octet-stream");
		let downloadLink = document.createElement("a");
		downloadLink.href = pngUrl;
		downloadLink.download = 'qr.png';
		document.body.appendChild(downloadLink);
		downloadLink.click();
		document.body.removeChild(downloadLink);
	}

	async function fileUpload(e) {
		let formData = new FormData();
		formData.append("id", selectedItem.id);
		formData.append("image", e.target.files[0]);
		formData.append("path", 'menu');

		var response
		if (!selectedItem.id) {
			response = await FILE('/upload-image', formData, {}, { loader: true });
		} else {
			formData.append("db_name", 'menu');
			formData.append("column_name", 'image');
			response = await FILE(`/update-image`, formData, {}, { loader: true });
		}
		if (httpOk(response)) {
			setSelectedItem({ ...selectedItem, 'image': response.data.url })
		}

	}

	async function fileDeleteFromServer(item) {
		if (selectedItem.id) {
			let formData = new FormData();
			formData.append("id", selectedItem.id);
			formData.append("db_name", 'menu');
			formData.append("column_name", 'image');
			formData.append("path", 'menu');
			formData.append("filename", item.image);

			await FILE(`/update-image`, formData, {}, { loader: true });
		}
		setSelectedItem({ ...selectedItem, 'image': item.image })
		setShowGallery(false)
	}

	async function getMenuGallery() {
		const response = await GET('/menu-gallery', {})
		if (httpOk(response)) {
			setMenuGallery(response.data)
		}
	}

	async function getPoses() {
		const response = await GET('/pos', {})
		if (httpOk(response)) {
			setPoses(response.data)
		}
	}

	useEffect(() => {
		searchWithFilter()
		getPoses()
		getMenuGallery()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between">
				<h4 className="vertical-center">
					{t('menu')} [{data.length}]
				</h4>
				<div className="d-flex gap-2">
					<button className="btn-rounded btn btn-primary"
						onClick={() => setShowQrModal(true)}>
						QR {t('menu')}
					</button>
					<button className="btn-rounded btn btn-primary"
						onClick={() => setShowModal(true)}>
						{t('create')}
					</button>
				</div>
			</div>

			<div className="card">
				<div className="card-body">

					<div className="d-flex flex-wrap mb-3">
						<div className="d-flex flex-wrap gap-2">
							<div>
								<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
									<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100">
										{t('filter')}
									</Dropdown.Toggle>

									<Dropdown.Menu className="dropdown-filter">
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('status')}</label>
												<Select
													options={statuses}
													value={statuses.find(option => option.id === filterData.status) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'status': option.id })}
													placeholder=""
													noOptionsMessage={() => t('list_empty')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
										</div>
										<div className="row mt-3">
											<div className="col-md-12">
												<div className="d-flex justify-content-end">
													<button className="btn btn-secondary me-2"
														onClick={() => setDropdownIsOpen(false)}>
														{t('cancel')}
													</button>
													<button className="btn btn-primary"
														onClick={() => searchWithFilter()}>
														{t('apply')}
													</button>
												</div>
											</div>
										</div>
									</Dropdown.Menu>
								</Dropdown>
							</div>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block">
							<div className="filter">
								<strong>{t('filter')}</strong>
								<br />
								{filterData.status &&
									<span className="me-2">
										<b>{t('status')}: </b>
										{findKeyFromArrayByValue(statuses, 'id', filterData.status, 'name')}
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase" onClick={resetFilter}>{t('reset')}</button>
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table table-bordered mb-0">
							<thead>
								<tr>
									<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
									<th style={{ 'width': '20%' }}>{t('name2')}</th>
									<th style={{ 'width': '5%' }} className="text-end">{t('products')}</th>
									<th style={{ 'width': '10%' }} className="text-center">{t('service')}</th>
									<th style={{ 'width': '15%' }} className="text-center">{t('telegram_bot')}</th>
									<th style={{ 'width': '15%' }} className="text-center">{t('menu_top')}</th>
									<th style={{ 'width': '50%' }}></th>
									<th style={{ 'width': '35px', 'minWidth': '35px' }}></th>
								</tr>
							</thead>
							<tbody>
								{data.map((item, index) => (
									<tr key={index}>
										<td className="text-center">{index + 1}</td>
										<td>{item.name}</td>
										<td className="text-end">{item.products_count}</td>
										<td className="text-center">
											{item.bool_service_percentage ?
												<span className="bg-primary bg-border text-white">{t('yes')}</span>
												:
												<span className="bg-danger bg-border text-white">{t('no')}</span>
											}
										</td>
										<td className="text-center">
											{item.bool_telegram_bot ?
												<span className="bg-primary bg-border text-white">{t('yes')}</span>
												:
												<span className="bg-danger bg-border text-white">{t('no')}</span>
											}
										</td>
										<td className="text-center">
											{item.bool_menu_top ?
												<span className="bg-primary bg-border text-white">{t('yes')}</span>
												:
												<span className="bg-danger bg-border text-white">{t('no')}</span>
											}
										</td>
										<td></td>
										<td>
											<div className="table-dropdown">
												<Dropdown id={`dropdown-${index}`}>
													<Dropdown.Toggle variant="outline-primary">
														<i className="uil uil-ellipsis-v"></i>
													</Dropdown.Toggle>

													<Dropdown.Menu>
														<div className="table-dropdown-item"
															onClick={() => toggleModal(true, item)}>
															<i className="uil-edit-alt"></i>
															{t('edit')}
														</div>
														{item.status > 0 &&
															<div className="table-dropdown-item text-danger"
																onClick={() => {
																	setDeleteModalParams({ id: item.id, index: index });
																	setModalDelete(true)
																}}>
																<i className="uil-times"></i>
																{t('delete')}
															</div>
														}
													</Dropdown.Menu>
												</Dropdown>
											</div>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>

			{/* MODAL */}
			<Modal show={showModal} animation={false} centered
				dialogClassName="update-modal-width" backdrop="static" onHide={() => toggleModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>
						{!selectedItem.id ?
							<>{t('create')}</>
							:
							<>{t('edit')}</>
						}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={createUpdate} autoComplete="off">
						<div className="form-group">
							<label>{t('name2')}<span className="required-mark">*</span></label>
							<div className="position-relative">
								<input type="text" className="form-control" name="name" autoFocus
									value={selectedItem.name}
									onChange={(e) => setSelectedItem({ ...selectedItem, 'name': e.target.value })} />
								<div className="input-smile-icon"
									onClick={() => setSelectedItem({ ...selectedItem, 'showSmiles': !selectedItem.showSmiles })}>🙂</div>
								{selectedItem.showSmiles &&
									<div className="input-smile-wrapper">
										{smiles.map((item, index) => (
											<div key={index} onClick={() => setSelectedItem({ ...selectedItem, 'name': selectedItem.name + smiles[index] })}>
												{smiles[index]}
											</div>
										))
										}
									</div>
								}
							</div>
						</div>
						<label>{t('image')}</label>
						<div className="con-upload position-relative">
							<div className={"con-img-upload " + (showGallery ? 'd-none' : '')}>
								{selectedItem.image &&
									<div className="img-upload">
										<button type="button" className="btn-x-file" onClick={() => setSelectedItem({ ...selectedItem, 'image': '' })}>
											<i className="uil-times"></i>
										</button>
										<img src={`https://backend.mison.uz/uploads/menu/${selectedItem.image}`}
											style={{ maxWidth: 'none', maxHeight: '100%' }} alt={selectedItem.image} />
										<div className="menu-image-button" onClick={() => setShowGallery(!showGallery)}>
											<i className="uil uil-image-v"></i>
										</div>
									</div>
								}
								{!selectedItem.image &&
									<div className="con-input-upload">
										<input type="file" onChange={(e) => fileUpload(e)} />
										<span className="text-input fz14">
											{t('image2')}
										</span>
										<div className="menu-image-button" onClick={() => setShowGallery(!showGallery)}>
											<i className="uil uil-image-v"></i>
										</div>
									</div>
								}
							</div>

							<div className={"inventory-modal-height position-relative" + (!showGallery ? ' d-none' : '')}>
								<div className="row">
									{menuGallery.map((item, index) => (
										<div className={"col-6" + (item.image === selectedItem.image ? ' active' : '')} key={index}
											onClick={() => fileDeleteFromServer(item)}>
											<div className="menu-gallery-item">
												<img src={`https://backend.mison.uz/uploads/menu/${item.image}`} alt={item.image} />
											</div>
										</div>
									))}
								</div>
								<div className="menu-image-button" onClick={() => setShowGallery(!showGallery)}>
									<i className="uil uil-image-v"></i>
								</div>
							</div>
						</div>

						{selectedItem.id &&
							<div className="d-flex justify-content-between mt-2">
								<p className="fw-600 vertical-center">{t('percent_service')}</p>
								<div className="form-check form-switch form-switch-lg">
									<input type="checkbox" className="form-check-input" name="bool_service_percentage"
										disabled //на кассе сделал функцию не рабочей так как считало неверно
										checked={selectedItem?.bool_service_percentage}
										onChange={(e) => setSelectedItem({ ...selectedItem, 'bool_service_percentage': e.target.checked })} />
								</div>
							</div>
						}
						<div className="d-flex justify-content-between mt-2">
							<p className="fw-600 vertical-center">{t('telegram_bot')}</p>
							<div className="form-check form-switch form-switch-lg">
								<input type="checkbox" className="form-check-input" name="bool_telegram_bot"
									checked={selectedItem?.bool_telegram_bot}
									onChange={(e) => setSelectedItem({ ...selectedItem, 'bool_telegram_bot': e.target.checked })} />
							</div>
						</div>
						<div className="d-flex justify-content-between mt-2">
							<p className="fw-600 vertical-center">{t('menu_top')}</p>
							<div className="form-check form-switch form-switch-lg">
								<input type="checkbox" className="form-check-input" name="bool_menu_top"
									checked={selectedItem?.bool_menu_top}
									onChange={(e) => setSelectedItem({ ...selectedItem, 'bool_menu_top': e.target.checked })} />
							</div>
						</div>
						<div className="d-flex w-100 mt-3">
							<button type="button" className="btn btn-outline-warning w-100 me-2"
								onClick={() => toggleModal(false)}>
								{t('cancel')}
							</button>
							<button type="submit" className="btn btn-primary w-100"
								disabled={!(selectedItem.name)}>
								{t('save')}
							</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL */}

			{/* QR MODAL */}
			<Modal show={showQrModal} animation={false} centered
				dialogClassName="update-modal-width" backdrop="static" onHide={() => setShowQrModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>
						QR
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="form-group">
						<label>{t('pos')}</label>
						<Select
							options={poses}
							value={poses.find(option => option.id === qrValue)}
							onChange={(option) => setQrValue(option.id)}
							placeholder=""
							noOptionsMessage={() => t('list_empty')}
							getOptionLabel={(option) => option.address}
							getOptionValue={(option) => option.id}
						/>
						{qrValue &&
							<div className="mt-3 fz-18 text-center">
								{`${'https://service.mison.uz/menu/'}${qrValue}`}
							</div>
						}
						{qrValue &&
							<div className="d-flex justify-content-center">
								<QRCode
									id="qr-gen"
									value={`${'https://service.mison.uz/menu/'}${qrValue}`}
									size={300}
									level={"H"}
									includeMargin={true}
								/>
							</div>
						}
					</div>
					<div className="d-flex w-100 mt-3">
						<button type="button" className="btn btn-primary w-100"
							onClick={() => downloadQRCode()}
							disabled={!(qrValue)}>
							{t('download')}
						</button>
					</div>
				</Modal.Body>
			</Modal>
			{/* QR MODAL */}

			<DeleteModal
				modalDelete={modalDelete}
				setModalDelete={setModalDelete}
				deleteItem={deleteItem}>
			</DeleteModal>
		</>
	)
}

export default Index