import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { useSelector } from "react-redux"
import Select from 'react-select'
import { Dropdown } from 'react-bootstrap'
import DatePicker from "react-datepicker"
import XLSX from 'xlsx'

import useDidMountEffect from 'pages/parts/useDidMountEffect'

import { GET, httpOk } from 'helpers/api'
import { formatMoney, returnSign, formatDate } from 'helpers/helpers'
import CustomTooltip from "pages/parts/CustomTooltip"

function SummaryCheques() {
	const { t } = useTranslation()
	const date = new Date()

	const reduxAccount = useSelector(state => state.account)

	const [data, setData] = useState([])
	const [poses, setPoses] = useState([{ 'id': -1, 'name': t('all') }])
	const [overall, setOverall] = useState({})
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterData, setFilterData] = useState({
		start_date: formatDate(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd'),
		pos_id: reduxAccount.pos_id,
		type: null,
		random: 0,
	})

	async function searchWithFilter(filterParams = { exportToExcel: false }) {
		var filterDataCopy = { ...filterData }
		var filterUrl = `/report-summary-cheques`
		var urlParams = ""

		if (filterDataCopy.pos_id)
			urlParams += returnSign(urlParams) + 'pos_id=' + filterDataCopy.pos_id
		if (filterDataCopy.start_date)
			urlParams += returnSign(urlParams) + 'start_date=' + filterDataCopy.start_date

		filterUrl = filterUrl + urlParams

		if (filterParams.exportToExcel) {
			var excelHeaders = [[
				t('date'), t('guests'), t('cheques'), t('hall'), t('takeaway'), t('delivery'), t('manager'),
				t('telegram'), t('call_center'), t('sales'), t('gross_income'),
			]]

			for (let i = 0; i < data.length; i++) {
				var excelItems = []
				excelItems.push(data[i].days)
				excelItems.push(data[i].total_people_number)
				excelItems.push(data[i].total_cheques)
				excelItems.push(data[i].total_hall)
				excelItems.push(data[i].total_takeaway)
				excelItems.push(data[i].total_delivery)
				excelItems.push(data[i].total_delivery_manager)
				excelItems.push(data[i].total_delivery_telegram)
				excelItems.push(data[i].total_delivery_call_center)
				excelItems.push(data[i].sales_total_amount)
				excelItems.push(data[i].gross_income_total_amount)
				excelHeaders.push(excelItems)
			}

			excelHeaders.push([
				t('total'),
				overall.overall_people_number,
				overall.overall_cheques,
				overall.overall_hall,
				overall.overall_takeaway,
				overall.overall_delivery,
				overall.overall_delivery_manager,
				overall.overall_delivery_telegram,
				overall.overall_delivery_call_center,
				overall.overall_sales,
				overall.overall_gross_income,
			])

			const ws = XLSX.utils.aoa_to_sheet(excelHeaders);
			const wb = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
			XLSX.writeFile(wb, t('summary_cheques') + ".xlsx");
		} else {
			const response = await GET(filterUrl, {}, { loader: true })
			if (httpOk(response)) {
				setOverall(response.data)
				setData(response.data.data)
			}
		}

		setDropdownIsOpen(false)
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	async function getPoses() {
		const response = await GET('/helper/poses')
		if (httpOk(response)) {
			setPoses(response.data)
		}
	}

	useEffect(() => {
		getPoses()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useDidMountEffect(() => {
		searchWithFilter()
	}, [filterData.random]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header">
				<h4 className="vertical-center h-100">
					{t('summary_cheques')}
				</h4>
			</div>

			<div className="card">
				<div className="card-body">

					<div className="d-flex justify-content-between flex-wrap mb-3">
						<div className="d-flex gap-2">
							<div>
								<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
									<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100">
										{t('filter')}
									</Dropdown.Toggle>

									<Dropdown.Menu className="dropdown-filter">
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('pos')}</label>
												<Select
													options={poses}
													value={poses.find(option => option.id === filterData.pos_id) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'pos_id': option.id, 'plan': option.plan })}
													placeholder=""
													noOptionsMessage={() => t('list_empty')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
											<div className="form-group col-md-6">
												<label>{t('period')}</label>
												<div className="calendar-input">
													<DatePicker
														showMonthYearPicker
														showFullMonthYearPicker
														className="form-control"
														dateFormat="dd.MM.yyyy"
														selected={filterData.start_date ? new Date(filterData.start_date) : ''}
														onChange={(date) => setFilterData({ ...filterData, 'start_date': formatDate(date, 'yyyy-MM-dd') })} />
													<i className="uil uil-calendar-alt"></i>
												</div>
											</div>
										</div>
										<div className="row mt-3">
											<div className="col-md-12">
												<div className="d-flex justify-content-end">
													<button className="btn btn-secondary me-2" onClick={() => setDropdownIsOpen(false)}>{t('cancel')}</button>
													<button className="btn btn-primary" onClick={() => searchWithFilter()}>{t('apply')}</button>
												</div>
											</div>
										</div>
									</Dropdown.Menu>
								</Dropdown>
							</div>
							<div>
								<CustomTooltip text={t('EXCEL')}>
									<button className="btn btn-outline-primary btn-wide-2" onClick={() => searchWithFilter({ exportToExcel: true })}>
										<i className="uil uil-file-download-alt"></i>
									</button>
								</CustomTooltip>
							</div>
						</div>
					</div>

					<div className="table-responsive table-bordered-override table-fixed mb-3">
						<table className="table table-bordered mb-0">
							<thead>
								<tr>
									<th>{t('date')}</th>
									<th className="text-nowrap text-end">{t('products')}</th>
									<th className="text-nowrap text-end">{t('guests')}</th>
									<th className="text-nowrap text-end">{t('cheques')}</th>
									<th className="text-nowrap text-end">{t('success')}</th>
									<th className="text-nowrap text-end">{t('deleted')}</th>
									<th className="text-nowrap text-end">{t('expected')}</th>
									<th className="text-nowrap text-end">{t('hall')}</th>
									<th className="text-nowrap text-end">{t('takeaway')}</th>
									<th className="text-nowrap text-end">{t('delivery')}</th>
									<th className="text-nowrap text-end">{t('manager')}</th>
									<th className="text-nowrap text-end">{t('telegram')}</th>
									<th className="text-nowrap text-end">{t('call_center')}</th>
									<th className="text-nowrap text-end">{t('sales')}</th>
									<th className="text-nowrap text-end">{t('gross_income')}</th>
									<th className="text-nowrap text-end">
										<CustomTooltip text={t(`${t('sales')} / ${t('cheques')}`)}>
											{t('average_check')}
										</CustomTooltip>
									</th>
									<th className="text-nowrap text-end">
										<CustomTooltip text={`* = ${t('products')} / ${t('guests')}`}>
											{t('product_quantity')}
										</CustomTooltip>
									</th>
									<th className="text-nowrap text-end">
										<CustomTooltip text={`* = ${t('sales')} / ${t('products')}`}>
											{t('product_price')}
										</CustomTooltip>
									</th>
								</tr>
							</thead>
							<tbody>
								{data?.map((item, index) => (
									<tr key={index}>
										<td>{formatDate(item.days)}</td>
										<td className="text-nowrap text-end">{formatMoney(item.total_products_quantity)}</td>
										<td className="text-nowrap text-end">{formatMoney(item.total_people_number)}</td>
										<td className="text-nowrap text-end">{formatMoney(item.total_cheques)}</td>
										<td className="text-nowrap text-end">{formatMoney(item.total_cheques_success)}</td>
										<td className="text-nowrap text-end">{formatMoney(item.total_cheques_deleted)}</td>
										<td className="text-nowrap text-end">{formatMoney(item.total_cheques_wait)}</td>
										<td className="text-nowrap text-end">{formatMoney(item.total_hall)}</td>
										<td className="text-nowrap text-end">{formatMoney(item.total_takeaway)}</td>
										<td className="text-nowrap text-end">{formatMoney(item.total_delivery)}</td>
										<td className="text-nowrap text-end">{formatMoney(item.total_delivery_manager)}</td>
										<td className="text-nowrap text-end">{formatMoney(item.total_delivery_telegram)}</td>
										<td className="text-nowrap text-end">{formatMoney(item.total_delivery_call_center)}</td>
										<td className="text-nowrap text-end">{formatMoney(item.sales_total_amount)}</td>
										<td className="text-nowrap text-end">{formatMoney(item.gross_income_total_amount)}</td>
										<td className="text-nowrap text-end">{formatMoney(item.avg_cheque)}</td>
										<td className="text-nowrap text-end">{formatMoney(item.per_person, 2)}</td>
										<td className="text-nowrap text-end">{formatMoney(item.per_price)}</td>
									</tr>
								))}
							</tbody>
							<tfoot>
								{data.length > 0 &&
									<tr>
										<td><b>{t('total')}</b></td>
										<td className="text-nowrap text-end"><b>{formatMoney(overall.overall_products_quantity)}</b></td>
										<td className="text-nowrap text-end"><b>{formatMoney(overall.overall_people_number)}</b></td>
										<td className="text-nowrap text-end"><b>{formatMoney(overall.overall_cheques)}</b></td>
										<td className="text-nowrap text-end"><b>{formatMoney(overall.overall_cheques_success)}</b></td>
										<td className="text-nowrap text-end"><b>{formatMoney(overall.overall_cheques_deleted)}</b></td>
										<td className="text-nowrap text-end"><b>{formatMoney(overall.overall_cheques_wait)}</b></td>
										<td className="text-nowrap text-end"><b>{formatMoney(overall.overall_hall)}</b></td>
										<td className="text-nowrap text-end"><b>{formatMoney(overall.overall_takeaway)}</b></td>
										<td className="text-nowrap text-end"><b>{formatMoney(overall.overall_delivery)}</b></td>
										<td className="text-nowrap text-end"><b>{formatMoney(overall.overall_delivery_manager)}</b></td>
										<td className="text-nowrap text-end"><b>{formatMoney(overall.overall_delivery_telegram)}</b></td>
										<td className="text-nowrap text-end"><b>{formatMoney(overall.overall_delivery_call_center)}</b></td>
										<td className="text-nowrap text-end"><b>{formatMoney(overall.overall_sales)}</b></td>
										<td className="text-nowrap text-end"><b>{formatMoney(overall.overall_gross_income)}</b></td>
										<td className="text-nowrap text-end"><b>{formatMoney(overall.overall_avg_cheques)}</b></td>
										<td className="text-nowrap text-end"><b>{formatMoney(overall.overall_per_person, 2)}</b></td>
										<td className="text-nowrap text-end"><b>{formatMoney(overall.overall_per_price)}</b></td>
									</tr>
								}
							</tfoot>
						</table>
					</div>
				</div>
			</div>
		</>
	)
}

export default SummaryCheques