import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { Dropdown, Modal } from 'react-bootstrap'
import Select from 'react-select'

import FilterSearch from 'pages/parts/FilterSearch'
import FilterPagination from 'pages/parts/FilterPagination'

import { formatDate, regexLogin, regexPhoneNumber, findKeyFromArrayByValue, returnSign, formatMoney, formatMoneyInput } from 'helpers/helpers'
import { httpOk, GET, POST, PUT } from 'helpers/api'

function Index() {
	const { t } = useTranslation()

	const userRoles = [
		{ 'role': 'ROLE_OWNER', 'name': t('owner') },
		{ 'role': 'ROLE_PARTNER', 'name': t('partner') },
	]

	const [selectedItem, setSelectedItem] = useState({
		'login': '',
		'phone': '',
		'password': '',
	})
	const [data, setData] = useState([])
	const [payments, setPayments] = useState([])
	const [modalUser, setModalUser] = useState(false)
	const [modalPayment, setModalPayment] = useState(false)
	const [modalPaymentHistory, setModalPaymentHistory] = useState(false)
	const [filterData, setFilterData] = useState({ 'role': 'ROLE_OWNER' })
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [pagination, setPagination] = useState({
		url: '/admin/users',
		response: null
	})

	async function searchWithFilter() {
		var filterDataCopy = { ...filterData }
		var filterUrl = "/admin/users"
		var urlParams = ""
		checkFilter()

		if (filterDataCopy.role)
			urlParams += returnSign(urlParams) + 'role=' + filterDataCopy.role
		if (filterDataCopy.status)
			urlParams += returnSign(urlParams) + 'status=' + filterDataCopy.status
		if (filterDataCopy.search)
			urlParams += returnSign(urlParams) + 'search=' + filterDataCopy.search
		if (filterDataCopy.page)
			urlParams += returnSign(urlParams) + 'page=' + filterDataCopy.page

		if (`/admin/users${urlParams}` !== filterUrl)
			setFilterDataExist(true)

		filterUrl = filterUrl + urlParams

		const response = await GET(filterUrl, {}, { loader: true })
		if (httpOk(response)) {
			setData(response.data)
			setPagination({ ...pagination, 'url': filterUrl, 'response': response.data })
		}

		setDropdownIsOpen(false)
	}

	async function createUpdate(e) {
		e.preventDefault();
		var selectedItemCopy = { ...selectedItem }

		if (selectedItemCopy.phone && selectedItemCopy.phone.length === 9)
			selectedItemCopy.phone = '998' + selectedItemCopy.phone

		var response
		if (!selectedItemCopy.id) {
			response = await POST('/admin/users', selectedItemCopy)
		} else {
			response = await PUT('/admin/users', selectedItemCopy)
		}

		if (httpOk(response)) {
			toggleModal(false)
			searchWithFilter()
		}
	}

	async function partnerPayment(e) {
		e.preventDefault();

		var response = await POST('/admin/partner-payment', selectedItem, { loader: true })
		if (httpOk(response)) {
			setSelectedItem({})
			setModalPayment(false)
			searchWithFilter()
		}
	}

	function toggleModal(bool = false, item = {}) {
		if (bool) {
			item.role = 'ROLE_OWNER'
			if (item.phone) item.phone = item.phone.substring(3, 12)
			setSelectedItem(item)
		} else {
			setSelectedItem({
				'login': '',
				'phone': '',
				'password': '',
				'role': 'ROLE_OWNER',
			})
		}

		setModalUser(bool)
	}

	function toggleModalPayment(bool = false, item = {}) {
		setModalPayment(bool)
		setSelectedItem(item)
	}

	function toggleModalPaymentHistory() {
		setPayments([])
	}

	function resetFilter() {
		setFilterData({
			status: null,
			page: 0,
			random: filterData.random + 1
		})
	}

	function checkFilter() {
		if (
			filterData.status === null &&
			filterData.page === null &&
			filterData.search === ''
		) {
			setFilterDataExist(false)
		}
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	async function paginate(page) {
		setFilterData({ ...filterData, 'page': page })
	}

	useEffect(() => {
		searchWithFilter()
	}, [filterData.search, filterData.page]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between">
				<h4 className="vertical-center">
					Пользователи [{data.total}]
				</h4>
				<div className="d-flex">
					<button className="btn-rounded btn btn-primary"
						onClick={() => toggleModal(true)}>
						{t('create')}
					</button>
				</div>
			</div>

			<div className="card">
				<div className="card-body">

					<div className="d-flex flex-wrap gap-2 mb-3">
						<div>
							<FilterSearch
								filterData={filterData}
								setFilterData={setFilterData}>
							</FilterSearch>
						</div>
						<div>
							<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
								<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100">
									{t('filter')}
								</Dropdown.Toggle>
								<Dropdown.Menu className="dropdown-filter">
									<div className="row">
										<div className="form-group col-md-6">
											<label>{t('role')}</label>
											<Select
												isClearable
												options={userRoles}
												value={userRoles.find(option => option.role === filterData.role) || ''}
												onChange={(option) => setFilterData({ ...filterData, 'role': option ? option.role : null })}
												placeholder=""
												noOptionsMessage={() => t('list_empty')}
												getOptionLabel={(option) => option.name}
												getOptionValue={(option) => option.role}
											/>
										</div>
									</div>

									<div className="row mt-3">
										<div className="col-md-12">
											<div className="d-flex justify-content-end gap-2">
												<button className="btn btn-secondary"
													onClick={() => setDropdownIsOpen(false)}>
													{t('cancel')}
												</button>
												<button className="btn btn-primary"
													onClick={() => searchWithFilter(false)}>
													{t('apply')}
												</button>
											</div>
										</div>
									</div>
								</Dropdown.Menu>
							</Dropdown>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block">
							<div className="filter">
								<strong>{t('filter')}</strong>
								<br />
								{filterData.search &&
									<span className="me-2">
										<b>{t('search')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
								{filterData.role &&
									<span className="me-2">
										<b>{t('role')}: </b>
										{findKeyFromArrayByValue(userRoles, 'role', filterData.role, 'name')}
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase"
									onClick={resetFilter}>
									{t('reset')}
								</button>
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table table-bordered mb-0">
							<thead>
								<tr>
									<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
									<th style={{ 'width': '15%' }}>{t('login')}</th>
									<th style={{ 'minWidth': '120px' }} className="text-center">{t('phone')}</th>
									<th style={{ 'minWidth': '120px' }} className="text-center">{t('date')}</th>
									<th style={{ 'width': '10%' }} className="text-center">{t('role')}</th>
									<th style={{ 'width': '30%' }}></th>
									<th style={{ 'width': '10%' }} className="text-end">{t('active')}</th>
									<th style={{ 'width': '10%' }} className="text-end">{t('disconnected')}</th>
									<th style={{ 'minWidth': '33px' }} className="text-end"></th>
								</tr>
							</thead>
							<tbody>
								{data?.data?.map((item, index) => (
									<tr key={index}>
										<td className="text-center">{item.row_number}</td>
										<td className="td-ellipsis">{item.login}</td>
										<td className="text-center">{regexPhoneNumber(item.phone)}</td>
										<td className="text-center">{formatDate(item.created_at)}</td>
										<td className="text-center">
											{item.role === 'ROLE_OWNER' &&
												<span>{t('owner')}</span>
											}
											{item.role === 'ROLE_PARTNER' &&
												<span>{t('partner')}</span>
											}
										</td>
										<td></td>
										<td className="text-end">{item.count_pos}</td>
										<td className="text-end">{item.count_pos_off}</td>
										<td>
											<div className="table-dropdown">
												<Dropdown id={`dropdown-${index}`}>
													<Dropdown.Toggle variant="outline-primary">
														<i className="uil uil-ellipsis-v"></i>
													</Dropdown.Toggle>

													<Dropdown.Menu>
														<div className="table-dropdown-item"
															onClick={() => toggleModal(true, item)}>
															<i className="uil-edit-alt"></i>
															{t('edit')}
														</div>
														{item.role === "ROLE_PARTNER" &&
															<>
																<div className="table-dropdown-item"
																	onClick={() => toggleModalPayment(true, item)}>
																	<i className="uil-edit-alt"></i>
																	Оплата
																</div>
																<div className="table-dropdown-item"
																	onClick={() => toggleModalPaymentHistory(item)}>
																	<i className="uil-info-circle"></i>
																	История оплат
																</div>
															</>
														}
													</Dropdown.Menu>
												</Dropdown>
											</div>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>

					{pagination.response &&
						<FilterPagination
							pagination={pagination}
							paginate={paginate}>
						</FilterPagination>
					}
				</div>
			</div>

			{/* MODAL */}
			<Modal show={modalUser} animation={false} centered
				dialogClassName="update-modal-width" backdrop="static"
				onHide={() => toggleModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>
						{!selectedItem.id ?
							<span>{t('create')}</span>
							:
							<span>{t('edit')}</span>
						}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={(e) => createUpdate(e)} autoComplete="off">
						<div className="form-group">
							<label>{t('type')}</label>
							<Select
								options={userRoles}
								value={userRoles.find(option => option.role === selectedItem.role) || ''}
								onChange={(option) => setSelectedItem({ ...selectedItem, 'role': option.role })}
								placeholder=""
								noOptionsMessage={() => t('list_empty')}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.role}
							/>
						</div>
						{selectedItem.role === 'ROLE_PARTNER' &&
							<div className="form-group">
								<label>Процент бонуса</label>
								<input type="text" className="form-control" name="partner_percentage"
									disabled={selectedItem.id}
									value={selectedItem.partner_percentage}
									onChange={(e) => setSelectedItem({ ...selectedItem, 'partner_percentage': e.target.value })} />
							</div>
						}
						<div className="form-group">
							<label>Имя</label>
							<input type="text" className="form-control" name="first_name" value={selectedItem.first_name}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'first_name': e.target.value })} />
						</div>
						<div className="form-group">
							<label>Логин</label>
							<input type="text" className="form-control" name="login" value={selectedItem.login}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'login': regexLogin(e.target.value) })} />
						</div>
						<div className="form-group">
							<label>Телефон</label>
							<div className="input-group">
								<span className="input-group-text">998</span>
								<input type="number" className="form-control" name="phone"
									value={selectedItem.phone ?? ''}
									onChange={(e) => setSelectedItem({ ...selectedItem, 'phone': e.target.value })} />
							</div>
						</div>
						<div className="form-group">
							<label>Пароль</label>
							<input type="text" className="form-control" name="password" value={selectedItem.password}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'password': e.target.value })} />
						</div>
						<div className="d-flex w-100 gap-2 mt-3">
							<button type="button" className="btn btn-outline-warning w-100"
								onClick={() => toggleModal(false)}>{t('cancel')}</button>
							<button type="submit" className="btn btn-primary w-100">{t('save')}</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL */}

			{/* MODAL PAYMENT*/}
			<Modal show={modalPayment} animation={false} centered
				dialogClassName="update-modal-width" backdrop="static"
				onHide={() => toggleModalPayment()}>
				<Modal.Header closeButton>
					<Modal.Title>Оплата</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={(e) => partnerPayment(e)} autoComplete="off">
						<div className="form-group">
							<label>Баланс</label>
							<input type="text" className="form-control"
								disabled
								value={formatMoneyInput(selectedItem.partner_balance) ?? ''}
								onChange={() => { }} />
						</div>
						<div className="form-group">
							<label>Сумма</label>
							<input type="text" className="form-control" name="amount"
								value={selectedItem.amount ? formatMoneyInput(selectedItem.amount) : ''}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'amount': e.target.value.replace(/[^0-9.-]/g, '') })} />
						</div>
						<div className="form-group">
							<label>Примечание</label>
							<input type="text" className="form-control" name="note" value={selectedItem.note}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'note': e.target.value })} />
						</div>
						<div className="d-flex gap-2 w-100 mt-3">
							<button type="button" className="btn btn-outline-warning w-100"
								onClick={() => toggleModalPayment()}>
								{t('cancel')}
							</button>
							<button type="submit" className="btn btn-primary w-100">{t('save')}</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL PAYMENT*/}

			{/* MODAL PAYMENT HISTORY*/}
			<Modal show={modalPaymentHistory} animation={false} centered
				dialogClassName="update-modal-width" backdrop="static"
				onHide={() => setModalPaymentHistory(false)}>
				<Modal.Header closeButton>
					<Modal.Title>
						История
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="table-responsive">
						<table className="table table-bordered mb-0">
							<thead>
								<tr>
									<th className="text-nowrap">{t('date')}</th>
									<th className="text-nowrap">{t('note')}</th>
									<th className="text-nowrap text-center">{t('status')}</th>
									<th className="text-nowrap text-end">{t('amount')}</th>
								</tr>
							</thead>
							<tbody>
								{payments?.map((item, index) => (
									<tr key={index}>
										<td>{formatDate(item.created_at, 'dd.MM.yyyy HH:mm')}</td>
										<td className="td-ellipsis" title={item.note}>{item.note}</td>
										<td className="text-center">
											{item.status === 1 &&
												<span className="text-success">Успешно</span>
											}
											{item.status === -2 &&
												<span className="text-danger">Списание</span>
											}
											{item.status === -3 &&
												<span className="text-danger">Перевод</span>
											}
										</td>
										<td className="text-nowrap text-end">{formatMoney(item.amount)}</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</Modal.Body>
			</Modal>
			{/* MODAL PAYMENT HISTORY*/}
		</>
	)
}

export default Index