import React, { useEffect, useState, useRef } from "react"
import { Link, useParams, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';
import Select from 'react-select'
import DatePicker from "react-datepicker"
import { format } from 'date-fns'
import { Modal } from 'react-bootstrap'

import { GET, PUT, httpOk } from 'helpers/api'
import { DayOfTheWeek } from "../../../helpers/DayOfTheWeek";
import TransferAccess from './TransferAccess'
import { formatMoneyInput } from "helpers/helpers";

const defaultSettings = {
	"bool_people_select": false, // Выбрать кол-во гостей к столу
	"bool_courier_select": false, // Выбрать курьера при оформление заказа на доставку
	"bool_cheque_delete_password": false, // Спрашивать кассира пароль для удаление чека
	"cheque_delete_password": '',
	"bool_telegram_bot": false,
	"bool_monitor_button": false, // Фаст фуд монитор кнопка готово OpenedCheques.js and Chef.js
	"bool_debt_button": false, // Кнопка в долг в оплате Payment.js
	"bool_agent_button": false, // Кнопка бонус агентам Шоферы и ТурАгенты Payment.js
	"bool_partial_pay_button": false, // Кнопка частичная оплата Payment.js
	"bool_prepayment_button": false, // Кнопка предоплаты фаст фуд оплата Payment.js и OpenedCheques.js F3 F4
	"bool_delivery_button": false, // Кнопка доставки в кассе если завидение работает на доставку
	"telegram_group_id": '', // Айди телеграм группы куда отправляются заказы по доставке
	"bool_table_transfer": true, // Кнопка перенос стола у офицанта
	"bool_table_free": true, // Кнопка освобождение стола у офицанта
	"bool_no_profit": false, // Кнопка оплата 3 точки без выручки
	"bool_no_service": false, // Кнопка оплата 3 точки без обслуги
	"agents_decrease_days": '', // Снижать баллы каждые n дней у неактивных агентов
	"agents_decrease_points": 0, // Снижать кол-во баллов у неактивных агентов
	"bool_no_delivery_price_button": false, // Кнопка оплата без цены доставки
	"bool_supplier_button": false, // Кнопка оплата поставщикам
	"bool_remove_multiple_products": false, // Кнопка удаление несколько продуктов с чека
	"bool_show_sale_price": false, // Показать цену продуктов
	"delivery_price_changeable": false, // Изменять цену доставки
	"bool_fast_food_mode": false, // Закрыть чек после предоплаты
	"online_stop_list": false, // Онлайн стоп лист
}

function CreateUpdate() {
	const { t } = useTranslation()
	const { id } = useParams()
	const navigate = useNavigate()

	const kpiTypes = [
		{ 'id': 1, 'name': t('sales') },
		{ 'id': 2, 'name': t('gross_income') },
	]

	const ymaps = useRef(null);
	const pointRef = useRef();

	const [data, setData] = useState({
		name: '',
		address: '',
		phone: '',
		longitude: 41.311081,
		latitude: 69.240562,
		settings: defaultSettings,
		plans: [],
		year: "",
	})
	const [modalYmap, setModalYmap] = useState(false)
	const [activeTab, setActiveTab] = useState(1)
	const [posWorkingDays, setPosWorkingDays] = useState([
		{ day: 1, activated: true, working_start_time: "09:00", working_end_time: "23:59", start_time: new Date(new Date().setHours(9, 0, 0, 0)), end_time: new Date(new Date().setHours(23, 59, 0, 0)) },
		{ day: 2, activated: true, working_start_time: "09:00", working_end_time: "23:59", start_time: new Date(new Date().setHours(9, 0, 0, 0)), end_time: new Date(new Date().setHours(23, 59, 0, 0)) },
		{ day: 3, activated: true, working_start_time: "09:00", working_end_time: "23:59", start_time: new Date(new Date().setHours(9, 0, 0, 0)), end_time: new Date(new Date().setHours(23, 59, 0, 0)) },
		{ day: 4, activated: true, working_start_time: "09:00", working_end_time: "23:59", start_time: new Date(new Date().setHours(9, 0, 0, 0)), end_time: new Date(new Date().setHours(23, 59, 0, 0)) },
		{ day: 5, activated: true, working_start_time: "09:00", working_end_time: "23:59", start_time: new Date(new Date().setHours(9, 0, 0, 0)), end_time: new Date(new Date().setHours(23, 59, 0, 0)) },
		{ day: 6, activated: true, working_start_time: "09:00", working_end_time: "23:59", start_time: new Date(new Date().setHours(9, 0, 0, 0)), end_time: new Date(new Date().setHours(23, 59, 0, 0)) },
		{ day: 7, activated: true, working_start_time: "09:00", working_end_time: "23:59", start_time: new Date(new Date().setHours(9, 0, 0, 0)), end_time: new Date(new Date().setHours(23, 59, 0, 0)) },
	])
	const [defaultState, setDefaultState] = useState({
		center: [41.311081, 69.240562],
		zoom: 11,
		controls: ["zoomControl"]
	});

	async function createUpdate() {
		var sendData = { ...data }
		sendData.working_days = posWorkingDays
		if (sendData.phone) sendData.phone = '998' + sendData.phone
		if (sendData.phone_call_center) sendData.phone_call_center = '998' + sendData.phone_call_center

		const response = await PUT('/pos', sendData, { loader: true })

		if (httpOk(response)) navigate('/pos')
	}

	function handleSettingChange(e, type) {
		var dataCopy = { ...data }
		if (type === 'checkbox') {
			dataCopy.settings[e.target.name] = e.target.checked
		}
		if (type === 'text') {
			dataCopy.settings[e.target.name] = e.target.value
		}

		setData(dataCopy)
	}

	function handleChangePlan(e, index) {
		var dataCopy = { ...data }

		var value = e.target.value.replace(/[^0-9.]/g, '')
		if (dataCopy.plans[index][e.target.name] === "plan_margin" && Number(value) > 100) return
		if (Number(value) > 99999999999) return

		dataCopy.plans[index][e.target.name] = value

		setData(dataCopy)
	}

	function renderWeekName(id) {
		for (let i = 0; i < DayOfTheWeek.length; i++) {
			if (DayOfTheWeek[i]['id'] === id) {
				return DayOfTheWeek[i]['name' + localStorage.getItem('i18nextLng').split('-').join('')]
			}
		}
	}

	function selectTime(time, index, type) {
		var posWorkingDaysCopy = [...posWorkingDays]
		if (type === 'start_time') {
			posWorkingDaysCopy[index]['start_time'] = time
			posWorkingDaysCopy[index]['working_start_time'] = format(new Date(time), 'HH:mm')
		} else {
			posWorkingDaysCopy[index]['end_time'] = time
			posWorkingDaysCopy[index]['working_end_time'] = format(new Date(time), 'HH:mm')
		}
		setPosWorkingDays(posWorkingDaysCopy)
	}

	async function parseCoordinates(value) {
		var numbers = value.replace(/\s/g, '').split(",")
		var latitude = numbers[0];
		var longitude = numbers[1];

		setDefaultState({
			center: [latitude, longitude],
			zoom: 11,
			controls: ["zoomControl"]
		})
		setData({
			...data,
			'coordinates': value,
			'longitude': longitude,
			'latitude': latitude
		})
	}

	function setDaysActivated(index) {
		var posWorkingDaysCopy = [...posWorkingDays]
		posWorkingDaysCopy[index]['activated'] = !posWorkingDaysCopy[index]['activated']
		setPosWorkingDays(posWorkingDaysCopy)
	}

	async function getData() {
		try {
			var response = await GET(`/pos/${id}`, {}, { loader: true })

			if (httpOk(response)) {
				response = response.data;
				response.settings = JSON.parse(response.settings)
				response.phone = response?.phone?.substring(3, 12)
				response.phone_call_center = response?.phone_call_center?.substring(3, 12)

				var date = new Date();
				var posWorkingDaysCopy = [...posWorkingDays]
				for (let i = 0; i < response.working_days.length; i++) {
					var startFormatted = null
					var endFormatted = null

					if (response.working_days[i]['start_time']) {
						const start = response.working_days[i]['start_time'].split(':');
						const end = response.working_days[i]['end_time'].split(':');
						startFormatted = new Date(date.getFullYear(), date.getMonth(), date.getDate(), start[0], start[1], 0, 0);
						endFormatted = new Date(date.getFullYear(), date.getMonth(), date.getDate(), end[0], end[1], 0, 0);
					}

					posWorkingDaysCopy[i]['activated'] = response.working_days[i]['activated']
					posWorkingDaysCopy[i]['start_time'] = startFormatted
					posWorkingDaysCopy[i]['end_time'] = endFormatted
					posWorkingDaysCopy[i]['working_start_time'] = response.working_days[i]['start_time']
					posWorkingDaysCopy[i]['working_end_time'] = response.working_days[i]['end_time']
				}
				setPosWorkingDays(posWorkingDaysCopy)

				if (response.settings === null) {
					response.settings = defaultSettings
				} else if (
					defaultSettings &&
					response.settings &&
					Object.keys(defaultSettings).length === Object.keys(response.settings).length) {
				} else {
					for (var key in defaultSettings) {
						if (response.settings.hasOwnProperty(key)) { //IF OBJECT HAVE all KEYS
							if (
								defaultSettings[key] &&
								response.settings[key] &&
								Object.keys(defaultSettings[key]).length !== Object.keys(response.settings[key]).length
							) { //IF OBJECT of OBJECT HAVE all KEYS
								for (var tableKey in defaultSettings[key]) {
									if (response.settings[key].hasOwnProperty(tableKey)) {
										response.settings[key][tableKey] = defaultSettings[key][tableKey]
									}
								}
							}
						} else {
							response.settings[key] = defaultSettings[key]
						}
					}
				}

				if (!response.longitude || !response.latitude) {
					response.longitude = 69.240562
					response.latitude = 41.311081
				} else {
					response.longitude = Number(response.longitude)
					response.latitude = Number(response.latitude)

					setDefaultState({
						center: [response.latitude, response.longitude],
						zoom: 11,
						controls: ["zoomControl"]
					})
				}
				setData(response)
			}
		} catch (error) {
			console.error(error);
		}
	}

	useEffect(() => {
		getData()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex mb-2">
				<div className="d-flex">
					<h4 className="vertical-center">
						{t('pos')}
					</h4>
					<div className="vertical-center mt-1">
						<i className="uil uil-angle-double-right fz-20"></i>
					</div>
					<div className="vertical-center mt-1">
						<h6>{t('edit')}</h6>
					</div>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<form autoComplete="off">
						<div className="row mb-5">
							<div className="col-md-6">
								<div>
									<div className="form-group">
										<label>{t("coordinates")} Yandex<span className="required-mark">*</span></label>
										<div className="position-relative">
											<input type="text" className="form-control" name="name"
												disabled
												value={`${data.latitude}, ${data.longitude}` ?? ''}
												onChange={(e) => { }} autoFocus />
											<div className="input-button-right"
												onClick={() => setModalYmap(true)}>
												<i className="uil uil-map-marker input-icon"></i>
											</div>
										</div>
									</div>
									<div className="d-flex gap-2">
										<div className="form-group w-100">
											<label>{t("name2")}<span className="required-mark">*</span></label>
											<input type="text" className="form-control" name="name" value={data.name ?? ''}
												onChange={(e) => setData({ ...data, 'name': e.target.value })} autoFocus />
										</div>
										<div className="form-group w-100">
											<label>{t("short_name")}</label>
											<input type="text" className="form-control" name="short_name" value={data.short_name ?? ''}
												onChange={(e) => setData({ ...data, 'short_name': e.target.value })} />
										</div>
									</div>
									<div className="d-flex gap-2">
										<div className="form-group w-100">
											<label>{t('cheque_phone')}</label>
											<div className="input-group">
												<span className="input-group-text">998</span>
												<input type="number" className="form-control" name="phone"
													value={data.phone ?? ''}
													onChange={(e) => setData({ ...data, 'phone': e.target.value })} />
											</div>
										</div>
										<div className="form-group w-100">
											<label>{t('pos_phone')}</label>
											<div className="input-group">
												<span className="input-group-text">998</span>
												<input type="number" className="form-control" name="phone_call_center"
													value={data.phone_call_center ?? ''}
													onChange={(e) => setData({ ...data, 'phone_call_center': e.target.value })} />
											</div>
										</div>
									</div>
									<div className="d-flex gap-2">
										<div className="form-group w-100">
											<label>{t("address")}</label>
											<input type="text" className="form-control" name="address" value={data.address ?? ''}
												onChange={(e) => setData({ ...data, 'address': e.target.value })} />
										</div>

										<div className="form-group w-100">
											<label>KPI</label>
											<Select
												options={kpiTypes}
												value={kpiTypes.find(option => option.id === data.kpi_type_id)}
												onChange={(option) => setData({ ...data, 'kpi_type_id': option.id })}
												placeholder=""
												noOptionsMessage={() => t('list_empty')}
												getOptionLabel={(option) => option.name}
												getOptionValue={(option) => option.id}
											/>
										</div>
									</div>
								</div>

								<div className="form-group">
									<h5 className="fw-600">{t('working_days')}</h5>
									<div className="table-responsive">
										<table className="table table-bordered mb-0">
											<thead>
												<tr>
													<th>#</th>
													<th>{t('days_of_the_week')}</th>
													<th>{t('opening_time')}</th>
													<th>{t('closing_time')}</th>
												</tr>
											</thead>
											<tbody>
												{posWorkingDays.map((item, index) => (
													<tr key={index}>
														<td className="vertical-middle">
															<input type="checkbox" className="form-check-input"
																checked={item.activated}
																onChange={() => setDaysActivated(index)} />
														</td>
														<td className="vertical-middle">{renderWeekName(item.day)}</td>
														<td>
															{item.activated ?
																<DatePicker
																	className="form-control text-center w-50 h-25"
																	selected={posWorkingDays[index]['start_time'] ? new Date(posWorkingDays[index]['start_time']) : ''}
																	onChange={(date) => selectTime(date, index, 'start_time')}
																	showTimeSelect
																	showTimeSelectOnly
																	timeIntervals={30}
																	timeCaption={t('time')}
																	timeFormat="HH:mm"
																	dateFormat="HH:mm" />
																:
																<span>-</span>
															}
														</td>
														<td>
															{item.activated ?
																<DatePicker
																	className="form-control text-center w-50 h-25"
																	selected={posWorkingDays[index]['end_time'] ? new Date(posWorkingDays[index]['end_time']) : ''}
																	onChange={(date) => selectTime(date, index, 'end_time')}
																	showTimeSelect
																	showTimeSelectOnly
																	timeIntervals={30}
																	timeCaption={t('time')}
																	timeFormat="HH:mm"
																	dateFormat="HH:mm" />
																:
																<span>-</span>
															}
														</td>
													</tr>
												))}
											</tbody>
										</table>
									</div>
								</div>
							</div>
							<div className="col-md-6">
								<div className="tabs tabs-sm">
									<ul>
										<li className={activeTab === 1 ? 'active' : ''}
											onClick={() => setActiveTab(1)}>
											<span className="link">{t('settings')}</span>
										</li>
										<li className={activeTab === 2 ? 'active' : ''}
											onClick={() => setActiveTab(2)}>
											<span className="link">{t('telegram')}</span>
										</li>
										<li className={activeTab === 3 ? 'active' : ''}
											onClick={() => setActiveTab(3)}>
											<span className="link">{t('services')}</span>
										</li>
									</ul>
								</div>
								<div className={activeTab !== 1 ? 'd-none' : ''}>
									<div className="d-flex justify-content-between mb-2">
										<p className="fw-600 vertical-center">{t('guests_selection')}</p>
										<div className="form-check form-switch form-switch-lg">
											<input type="checkbox" className="form-check-input" name="bool_people_select"
												checked={data?.settings?.bool_people_select}
												onChange={(e) => handleSettingChange(e, 'checkbox')} />
										</div>
									</div>
									<div className="d-flex justify-content-between mb-2">
										<p className="fw-600 vertical-center">{t('password_delete_cheque')}</p>
										<div className="form-check form-switch form-switch-lg">
											<input type="checkbox" className="form-check-input" name="bool_cheque_delete_password"
												checked={data?.settings?.bool_cheque_delete_password}
												onChange={(e) => handleSettingChange(e, 'checkbox')} />
										</div>
									</div>
									{(data?.settings?.bool_cheque_delete_password !== 0 && data?.settings?.bool_cheque_delete_password) &&
										<div className="form-group">
											<label>{t('password_delete_cheque')}</label>
											<input type="text" className="form-control" name="cheque_delete_password"
												value={data?.settings?.cheque_delete_password ?? ''}
												onChange={(e) => handleSettingChange(e, 'text')}
											/>
										</div>
									}
									<div className="d-flex justify-content-between mb-2">
										<p className="fw-600 vertical-center">{t('button_monitor')}</p>
										<div className="form-check form-switch form-switch-lg">
											<input type="checkbox" className="form-check-input" name="bool_monitor_button"
												checked={data?.settings?.bool_monitor_button}
												onChange={(e) => handleSettingChange(e, 'checkbox')} />
										</div>
									</div>
									<div className="d-flex justify-content-between mb-2">
										<p className="fw-600 vertical-center">{t('button_debt')}</p>
										<div className="form-check form-switch form-switch-lg">
											<input type="checkbox" className="form-check-input" name="bool_debt_button"
												checked={data?.settings?.bool_debt_button}
												onChange={(e) => handleSettingChange(e, 'checkbox')} />
										</div>
									</div>
									<div className="d-flex justify-content-between mb-2">
										<p className="fw-600 vertical-center">{t('button_agents')}</p>
										<div className="form-check form-switch form-switch-lg">
											<input type="checkbox" className="form-check-input" name="bool_agent_button"
												checked={data?.settings?.bool_agent_button}
												onChange={(e) => handleSettingChange(e, 'checkbox')} />
										</div>
									</div>
									<div className="d-flex justify-content-between mb-2">
										<p className="fw-600 vertical-center">{t('button_partial_payment')}</p>
										<div className="form-check form-switch form-switch-lg">
											<input type="checkbox" className="form-check-input" name="bool_partial_pay_button"
												checked={data?.settings?.bool_partial_pay_button}
												onChange={(e) => handleSettingChange(e, 'checkbox')} />
										</div>
									</div>
									<div className="d-flex justify-content-between mb-2">
										<p className="fw-600 vertical-center">{t('button_prepayment')}</p>
										<div className="form-check form-switch form-switch-lg">
											<input type="checkbox" className="form-check-input" name="bool_prepayment_button"
												checked={data?.settings?.bool_prepayment_button}
												onChange={(e) => handleSettingChange(e, 'checkbox')} />
										</div>
									</div>
									<div className="d-flex justify-content-between mb-2">
										<p className="fw-600 vertical-center">{t('button_delivery')}</p>
										<div className="form-check form-switch form-switch-lg">
											<input type="checkbox" className="form-check-input" name="bool_delivery_button"
												checked={data?.settings?.bool_delivery_button}
												onChange={(e) => handleSettingChange(e, 'checkbox')} />
										</div>
									</div>
									<div className="d-flex justify-content-between mb-2">
										<p className="fw-600 vertical-center">{t('button_no_profit')}</p>
										<div className="form-check form-switch form-switch-lg">
											<input type="checkbox" className="form-check-input" name="bool_no_profit"
												checked={data?.settings?.bool_no_profit}
												onChange={(e) => handleSettingChange(e, 'checkbox')} />
										</div>
									</div>
									<div className="d-flex justify-content-between mb-2">
										<p className="fw-600 vertical-center">{t('button_no_service')}</p>
										<div className="form-check form-switch form-switch-lg">
											<input type="checkbox" className="form-check-input" name="bool_no_service"
												checked={data?.settings?.bool_no_service}
												onChange={(e) => handleSettingChange(e, 'checkbox')} />
										</div>
									</div>
									<div className="d-flex justify-content-between mb-2">
										<p className="fw-600 vertical-center">{t('button_no_delivery_price')}</p>
										<div className="form-check form-switch form-switch-lg">
											<input type="checkbox" className="form-check-input" name="bool_no_delivery_price_button"
												checked={data?.settings?.bool_no_delivery_price_button}
												onChange={(e) => handleSettingChange(e, 'checkbox')} />
										</div>
									</div>
									<div className="d-flex justify-content-between mb-2">
										<p className="fw-600 vertical-center">{t('button_supplier_payment')}</p>
										<div className="form-check form-switch form-switch-lg">
											<input type="checkbox" className="form-check-input" name="bool_supplier_button"
												checked={data?.settings?.bool_supplier_button}
												onChange={(e) => handleSettingChange(e, 'checkbox')} />
										</div>
									</div>
									<div className="d-flex justify-content-between mb-2">
										<p className="fw-600 vertical-center">{t('button_remove_multiple_products')}</p>
										<div className="form-check form-switch form-switch-lg">
											<input type="checkbox" className="form-check-input" name="bool_remove_multiple_products"
												checked={data?.settings?.bool_remove_multiple_products}
												onChange={(e) => handleSettingChange(e, 'checkbox')} />
										</div>
									</div>
									<div className="d-flex justify-content-between mb-2">
										<p className="fw-600 vertical-center">{t('show_sale_price')}</p>
										<div className="form-check form-switch form-switch-lg">
											<input type="checkbox" className="form-check-input" name="bool_show_sale_price"
												checked={data?.settings?.bool_show_sale_price}
												onChange={(e) => handleSettingChange(e, 'checkbox')} />
										</div>
									</div>
									<div className="d-flex justify-content-between mb-2">
										<p className="fw-600 vertical-center">{t('delivery_price_changeable')}</p>
										<div className="form-check form-switch form-switch-lg">
											<input type="checkbox" className="form-check-input" name="delivery_price_changeable"
												checked={data?.settings?.delivery_price_changeable}
												onChange={(e) => handleSettingChange(e, 'checkbox')} />
										</div>
									</div>
									<div className="d-flex justify-content-between mb-2">
										<p className="fw-600 vertical-center">{t('fast_food_mode')}</p>
										<div className="form-check form-switch form-switch-lg">
											<input type="checkbox" className="form-check-input" name="bool_fast_food_mode"
												checked={data?.settings?.bool_fast_food_mode}
												onChange={(e) => handleSettingChange(e, 'checkbox')} />
										</div>
									</div>
									<div className="d-flex justify-content-between mb-2">
										<p className="fw-600 vertical-center">{t('online_stop_list')}</p>
										<div className="form-check form-switch form-switch-lg">
											<input type="checkbox" className="form-check-input" name="online_stop_list"
												checked={data?.settings?.online_stop_list}
												onChange={(e) => handleSettingChange(e, 'checkbox')} />
										</div>
									</div>
								</div>
								<div className={activeTab !== 2 ? 'd-none' : ''}>
									<div className="pos-settings-wrapper">
										<p className="fw-600 vertical-center">{t('tg_bot')}</p>
										<div className="form-check form-switch form-switch-lg">
											<input type="checkbox" className="form-check-input" name="bool_telegram_bot"
												checked={data?.settings?.bool_telegram_bot}
												onChange={(e) => handleSettingChange(e, 'checkbox')} />
										</div>
									</div>
									<div className="d-flex justify-content-between mb-2">
										<p className="fw-600 vertical-center">{t('select_courier')}</p>
										<div className="form-check form-switch form-switch-lg">
											<input type="checkbox" className="form-check-input" name="bool_courier_select"
												checked={data?.settings?.bool_courier_select}
												onChange={(e) => handleSettingChange(e, 'checkbox')} />
										</div>
									</div>
									<div className="pos-settings-wrapper">
										<p className="pos-settings-left">{t('telegram_group_id')}</p>
										<div className="pos-settings-right">
											<input type="text" className="form-control" name="telegram_group_id"
												value={data?.settings?.telegram_group_id ?? ''}
												onChange={(e) => handleSettingChange(e, 'text')}
											/>
										</div>
									</div>
								</div>
								{activeTab === 3 &&
									<TransferAccess activeTab={activeTab} id={data.id}></TransferAccess>
								}
							</div>
						</div>

						<h5 className="fw-600">{t('plan')}</h5>
						<div className="table-resposive">
							<table className="table table-bordered">
								<thead>
									<tr>
										<th>{t('type')}</th>
										<th>{t('january')}</th>
										<th>{t('february')}</th>
										<th>{t('march')}</th>
										<th>{t('april')}</th>
										<th>{t('may')}</th>
										<th>{t('june')}</th>
										<th>{t('july')}</th>
										<th>{t('august')}</th>
										<th>{t('september')}</th>
										<th>{t('october')}</th>
										<th>{t('november')}</th>
										<th>{t('december')}</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td className="text-nowrap">
											<b>{t('sales')}</b>
										</td>
										{data?.plans?.map((item, index) => (
											<td className="td-input" key={index}>
												<input type="text" className="form-control" name="plan_sales"
													value={formatMoneyInput(item.plan_sales) ?? ''}
													onChange={(e) => handleChangePlan(e, index)} />
											</td>
										))}
									</tr>
									<tr>
										<td className="text-nowrap">
											<b>{t('income')}</b>
										</td>
										{data?.plans?.map((item, index) => (
											<td className="td-input" key={index}>
												<input type="text" className="form-control" name="plan_income"
													value={formatMoneyInput(item.plan_income) ?? ''}
													onChange={(e) => handleChangePlan(e, index)} />
											</td>
										))}
									</tr>
									<tr>
										<td className="text-nowrap">
											<b>{t('margin')}%</b>
										</td>
										{data?.plans?.map((item, index) => (
											<td className="td-input" key={index}>
												<input type="text" className="form-control" name="plan_margin"
													value={formatMoneyInput(item.plan_margin) ?? ''}
													onChange={(e) => handleChangePlan(e, index)} />
											</td>
										))}
									</tr>
									<tr>
										<td className="text-nowrap">
											<b>{t('number_of_checks')}</b>
										</td>
										{data?.plans?.map((item, index) => (
											<td className="td-input" key={index}>
												<input type="text" className="form-control" name="plan_cheques"
													value={formatMoneyInput(item.plan_cheques) ?? ''}
													onChange={(e) => handleChangePlan(e, index)} />
											</td>
										))}
									</tr>
									<tr>
										<td className="text-nowrap">
											<b>{t('number_of_products')}</b>
										</td>
										{data?.plans?.map((item, index) => (
											<td className="td-input" key={index}>
												<input type="text" className="form-control" name="plan_products"
													value={formatMoneyInput(item.plan_products) ?? ''}
													onChange={(e) => handleChangePlan(e, index)} />
											</td>
										))}
									</tr>
									<tr>
										<td className="text-nowrap">
											<b>{t('number_of_guests')}</b>
										</td>
										{data?.plans?.map((item, index) => (
											<td className="td-input" key={index}>
												<input type="text" className="form-control" name="plan_guests"
													value={formatMoneyInput(item.plan_guests) ?? ''}
													onChange={(e) => handleChangePlan(e, index)} />
											</td>
										))}
									</tr>
									<tr>
										<td className="text-nowrap">
											<b>{t('net_profit')}</b>
										</td>
										{data?.plans?.map((item, index) => (
											<td className="td-input" key={index}>
												<input type="text" className="form-control" name="plan_net_profit"
													value={formatMoneyInput(item.plan_net_profit) ?? ''}
													onChange={(e) => handleChangePlan(e, index)} />
											</td>
										))}
									</tr>
								</tbody>
							</table>
						</div>

						<div className="btn-wrapper gap-2">
							<Link className="btn btn-warning btn-rounded btn-wide" to="/pos">
								{t('cancel')}
							</Link>
							<button type="button" className="btn btn-primary btn-rounded btn-wide" tabIndex="4"
								onClick={() => createUpdate()}>
								{t('save')}
							</button>
						</div>
					</form>
				</div>
			</div>

			{/* MODAL MAP */}
			<Modal show={modalYmap} animation={false} dialogClassName="custom-delivery-modal"
				backdrop="static" onHide={() => setModalYmap(false)}>
				<Modal.Body>
					<input type="text" className="form-control ymap-coordinates-input"
						placeholder={t('coordinates') + ' 41.311151, 69.279737'}
						onChange={(e) => parseCoordinates(e.target.value)} />
					<div className="row">
						<div className="col-md-12" style={{ height: '80vh' }} >
							<YMaps
								style={{ height: '80vh' }}
								query={{
									load: "package.full",
									apikey: '1c08a7da-6cda-4a21-a1e7-a6f7d9ba19e6'
								}}>
								<Map
									defaultOptions={{
										copyrightUaVisible: false,
										yandexMapDisablePoiInteractivity: true,
									}}
									style={{ height: '80vh' }}
									defaultState={defaultState}
									onLoad={(e) => {
										ymaps.current = e;
									}}
									onClick={(event) => {
										const coords = event.get("coords");
										ymaps.current.geocode(coords).then((res) => {
											//const firstGeoObject = res.geoObjects.get(0);
											//console.log(firstGeoObject)
											setDefaultState({
												center: [coords[0], coords[1]],
												zoom: 11,
												controls: ["zoomControl"]
											})
											setData({ ...data, latitude: coords[0], longitude: coords[1] })
										});
									}}>
									<Placemark instanceRef={pointRef} geometry={[data.latitude, data.longitude]} />
								</Map>
							</YMaps>
						</div>
					</div>
					<div className="d-flex w-100 mt-3">
						<button type="button" className="btn btn-outline-warning w-100 me-2" style={{ 'flex': 1 }}
							onClick={() => setModalYmap(false)}>
							{t('cancel')}
						</button>
						<button type="submit" className="btn btn-primary w-100" style={{ 'flex': 3 }}
							onClick={() => setModalYmap(false)}>
							<span>{t('save')}</span>
						</button>
					</div>
				</Modal.Body>
			</Modal>
			{/* MODAL MAP */}
		</>
	)
}

export default CreateUpdate