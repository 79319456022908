import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import DeleteModal from "pages/parts/DeleteModal"
import ReactSelect from 'pages/parts/ReactSelect'
import { GET, DELETE, httpOk } from 'helpers/api'
import { formatMoney } from 'helpers/helpers'

function Size({ posId, data, setData }) {
	const { t } = useTranslation()

	const tbodyRef = useRef()
	const scrollRef = useRef()

	const [elements, setElements] = useState([])
	const [elementsDuplicate, setElementsDuplicate] = useState([])
	const [modalDeleteSize, setModalDeleteSize] = useState(false)
	const [deleteModalSizeParams, setDeleteModalSizeParams] = useState({ 'id': 0, 'index': 0 })

	// REACTSELECT LOGIC
	function handleSelectSearch(value) {
		if (!value) {
			setElements([...elementsDuplicate])
			return
		}
		var elementsDuplicateCopy = [...elementsDuplicate]

		var filteredItems = elementsDuplicateCopy.filter(item => item.name.toLowerCase().includes(value.toLowerCase()))
		if (filteredItems[0]) {
			filteredItems[0]['selected'] = true
		}

		setElements(filteredItems)
	}

	function handleSelectElement(option, index) {
		var dataCopy = { ...data }
		var found = !!dataCopy.sizes.find(item => item.product_id === option.product_id)
		if (found) {
			dataCopy.sizes[index]['product_id'] = ''
			toast.error(`Уже добавлен`)
			return
		}

		dataCopy['sizes'][index] = option
		setData(dataCopy)
	}

	function closeReactSelect() {
		setTimeout(() => {
			var elementsCopy = [...elements]
			for (let i = 0; i < elementsCopy.length; i++) {
				elementsCopy[i]['selected'] = false
			}
			if (elementsCopy[0] && elementsCopy[0]['selected'])
				elementsCopy[0]['selected'] = true
			setElements(elementsCopy)
		}, 100);
	}

	function handleTableKeyDown(event) {
		event.stopPropagation();
		var currentIndex = elements.findIndex(el => el.selected);
		if (currentIndex >= 0) {
			var elementsCopy = [...elements]
			elementsCopy[currentIndex].selected = false
			switch (event.key) {
				case "ArrowUp":
					if (currentIndex === 0) {
						elementsCopy[elements.length - 1].selected = true
						currentIndex = elements.length - 1
					} else {
						elementsCopy[currentIndex - 1].selected = true
					}
					scrollRef.current.scrollTop = (currentIndex * 29.6) - 59.2
					break;
				case "ArrowDown":
					if (currentIndex === elements.length - 1) {
						elementsCopy[0].selected = true
						currentIndex = 0
					} else {
						elementsCopy[currentIndex + 1].selected = true
					}
					scrollRef.current.scrollTop = (currentIndex * 29.6)
					break;
				default: break;
			}

			setElements(elementsCopy)
		}
	}
	// REACTSELECT LOGIC

	function handleSizeChange(e, index) {
		var dataCopy = { ...data }

		if (e.target.type === 'text') {
			dataCopy['sizes'][index][e.target.name] = e.target.value
		}

		if (e.target.type === 'number') {
			dataCopy['sizes'][index][e.target.name] = e.target.value
		}

		if (e.target.type === 'radio') {
			for (let i = 0; i < dataCopy['sizes'].length; i++) {
				dataCopy['sizes'][i][e.target.name] = false
			}
			dataCopy['sizes'][index][e.target.name] = e.target.checked
		}

		if (e.type === 'click') {
			for (let i = 0; i < dataCopy['sizes'].length; i++) {
				dataCopy['sizes'][i]['active'] = false
			}
			dataCopy['sizes'][index]['active'] = true
			dataCopy.activeSizeIndex = index
		}

		setData(dataCopy)
	}

	async function deleteSize() {
		await DELETE(`/products-size/${deleteModalSizeParams.id}`, {}, { loader: true })
		var dataCopy = { ...data }
		dataCopy.sizes.splice(deleteModalSizeParams.index, 1)
		setData(dataCopy)
		setModalDeleteSize(false)
	}

	function toggleModalDeleteSize(item, index) {
		if (item.id) {
			setDeleteModalSizeParams({ 'id': item.id, 'index': index })
			setModalDeleteSize(true)
		} else {
			var dataCopy = { ...data }
			dataCopy.sizes.splice(index, 1)
			setData(dataCopy)
		}
	}

	function resetDefaultSize() {
		var dataCopy = { ...data }

		for (let i = 0; i < dataCopy['sizes'].length; i++) {
			dataCopy['sizes'][i]['bool_size_default'] = false
		}

		setData(dataCopy)
	}

	async function getSizes() {
		const response = await GET(`/products-sizes/${posId}`)
		if (httpOk(response)) {
			setElements(response.data)
			setElementsDuplicate(response.data)
		}
	}

	useEffect(() => {
		getSizes()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="table-responsive">
				<table className="table table-bordered">
					<thead>
						<tr>
							<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
							<th style={{ 'width': '40%' }}>{t('product')}</th>
							<th style={{ 'width': '20%' }} className="text-end">{t('cost_price')}</th>
							<th style={{ 'width': '20%' }} className="text-end">{t('sale_price')}</th>
							<th style={{ 'width': '20%' }} className="text-center">{t('default')}</th>
							<th style={{ 'minWidth': '33px' }}></th>
						</tr>
					</thead>
					<tbody>
						{data?.sizes?.map((item, index) => (
							<tr key={index}>
								<td className="text-center">{index + 1}</td>
								<td>
									<div>
										<ReactSelect
											search={handleSelectSearch}
											value={item.name}
											index={index}
											scrollRef={scrollRef}
											options={elements}
											onClose={() => closeReactSelect()}
											selectItem={(option) => handleSelectElement(option, index)}
											onKeyUp={(e) => {
												if (e.keyCode === 40 || e.keyCode === 38) {
													handleTableKeyDown(e)
												}
											}}
											table={
												<table className="table mb-0">
													<tbody ref={tbodyRef}>
														{elements.map((selectItem, selectIndex) => (
															<tr
																id={selectItem.id}
																className={`cursor ${((selectItem.selected || selectItem.product_id === item.product_id) && 'selected')}`}
																tabIndex={-1}
																key={selectIndex}
																onClick={() => handleSelectElement(selectItem, index)}
																onKeyDown={(e) => handleTableKeyDown(e, selectItem, index)}>
																<td>{selectItem.name}</td>
																<td>{selectItem.product_type_name}</td>
																<td className="text-end">{selectItem.sale_price}</td>
															</tr>
														))}
													</tbody>
												</table>
											}
										/>
									</div>
								</td>
								<td className="text-end">{formatMoney(item.cost_price)}</td>
								<td className="text-end">{formatMoney(item.sale_price)}</td>
								<td className="vertical-middle">
									<div className="d-flex justify-content-center">
										<input type="radio" name="bool_size_default" className="form-check-input"
											checked={item?.bool_size_default}
											onChange={(e) => handleSizeChange(e, index)} />
									</div>
								</td>
								<td className="vertical-middle">
									<div className="d-flex justify-content-center">
										<div className="table-action-button table-action-danger-button"
											onClick={() => toggleModalDeleteSize(item, index)}>
											<i className="uil-times"></i>
										</div>
									</div>
								</td>
							</tr>
						))}
						{!!data?.sizes?.length &&
							<tr>
								<td></td>
								<td></td>
								<td></td>
								<td></td>
								<td className="p-0" onClick={() => resetDefaultSize()}>
									<button type="button" className="btn-sm btn-primary w-100">
										{t('reset')}
									</button>
								</td>
								<td></td>
							</tr>
						}
					</tbody>
				</table>
			</div>

			<DeleteModal
				modalDelete={modalDeleteSize}
				setModalDelete={setModalDeleteSize}
				deleteItem={deleteSize}>
			</DeleteModal>
		</>
	)
}

export default Size