import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'
import Select from 'react-select'

import { httpOk, GET, PUT } from 'helpers/api'

function Index() {
	const { t } = useTranslation()
	const { id } = useParams()

	const [data, setData] = useState({})
	const [paymentTypes, setPaymentTypes] = useState([])
	const [externalMenu, setExternalMenu] = useState([])

	async function searchWithFilter() {
		var filterUrl = `/external-integrations/${id}`

		var response = await GET(filterUrl, {}, { loader: true })
		if (httpOk(response)) {

			const responsePoses = await GET('/helper/poses')
			if (responsePoses.data.length !== response.data.poses) {
				let missingItems = responsePoses.data.filter(item => !response.data.poses.some(existingItem => existingItem.id === item.id));
				console.log(missingItems);
				response.data.poses.push(...missingItems);
			}

			setData(response.data);
		}
	}

	async function createUpdate(e) {
		e.preventDefault()

		var response = await PUT('/external-integrations', data, { loader: true })

		if (httpOk(response)) { }
	}

	function handleSettingChange(e, item, index) {
		var dataCopy = { ...data }

		dataCopy['poses'][index]['status'] = e.target.checked
		setData(dataCopy)
	}

	async function getPaymentTypes() {
		const response = await GET('/helper/payment-types')
		if (httpOk(response)) setPaymentTypes(response.data)
	}

	async function getExternalMenus() {
		const response = await GET('/helper/external-menu')
		if (httpOk(response)) setExternalMenu(response.data)
	}

	useEffect(() => {
		getPaymentTypes()
		getExternalMenus()
		searchWithFilter()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between">
				<div className="d-flex text-truncate">
					<h4 className="vertical-center">
						{t('integrations')}
					</h4>
					<div className="vertical-center mt-1">
						<i className="uil uil-angle-double-right fz-20"></i>
					</div>
					<div className="vertical-center mt-1" onClick={createUpdate}>
						<h6>{data?.name}</h6>
					</div>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="row">
						<div className="col-md-6">
							<div className="form-group">
								<label>{t('name2')}</label>
								<input type="text" className="form-control" name="name"
									value={data.name ?? ''}
									onChange={(e) => setData({ ...data, 'name': e.target.value })} />
							</div>
							<div className="form-group w-100">
								<label>{t("description")}</label>
								<textarea className="form-control" name="description"
									value={data.description ?? ''}
									onChange={(e) => setData({ ...data, 'description': e.target.value })} />
							</div>
							<div className="d-flex gap-2">
								<div className="form-group w-75">
									<label>Webhook URL</label>
									<input type="text" className="form-control" name="webhook"
										disabled
										value={data.webhook ?? ''}
										onChange={() => { }} />
								</div>
								<div className="form-group w-25">
									<label>prefix</label>
									<input type="text" className="form-control" name="prefix"
										value={data.prefix ?? ''}
										onChange={(e) => setData({ ...data, 'prefix': e.target.value })} />
								</div>
							</div>
							<hr />
							<div className="d-flex gap-2">
								<div className="form-group w-100">
									<label>Client ID</label>
									<input type="text" className="form-control" name="client_id"
										disabled
										value={data.client_id ?? ''}
										onChange={() => { }} />
								</div>
								<div className="form-group w-100">
									<label>Client Secret</label>
									<input type="text" className="form-control" name="client_secret"
										disabled
										value={data.client_secret ?? ''}
										onChange={() => { }} />
								</div>
							</div>
							<div className="d-flex gap-2">
								<div className="form-group w-100">
									<label>Vendor Client ID</label>
									<input type="text" className="form-control" name="vendor_client_id"
										value={data.vendor_client_id ?? ''}
										onChange={(e) => setData({ ...data, 'vendor_client_id': e.target.value })} />
								</div>
								<div className="form-group w-100">
									<label>Vendor Client Secret</label>
									<input type="text" className="form-control" name="vendor_client_secret"
										value={data.vendor_client_secret ?? ''}
										onChange={(e) => setData({ ...data, 'vendor_client_secret': e.target.value })} />
								</div>
							</div>
							<hr />
							<div className="d-flex gap-2">
								<div className="form-group w-100">
									<label>{t('payment_type')}</label>
									<Select
										options={paymentTypes}
										value={paymentTypes.find(option => option.payment_type_id === data.payment_type_id) || ''}
										onChange={(option) => setData({ ...data, 'payment_type_id': option.payment_type_id })}
										placeholder=""
										noOptionsMessage={() => t('list_empty')}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.payment_type_id}
									/>
								</div>
								<div className="form-group w-100">
									<label>{t('external_menu')}</label>
									<Select
										options={externalMenu}
										value={externalMenu.find(option => option.id === data.external_group_id) || ''}
										onChange={(option) => setData({ ...data, 'external_group_id': option.id })}
										placeholder=""
										noOptionsMessage={() => t('list_empty')}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
									/>
								</div>
							</div>
							<div className="d-flex gap-2">
								<div className="form-group w-100">
									<label>Имя клиента</label>
									<input type="text" className="form-control" name="client_name"
										value={data.client_name ?? ''}
										onChange={(e) => setData({ ...data, 'client_name': e.target.value })} />
								</div>
								<div className="form-group w-100">
									<label>Номер клиента</label>
									<input type="text" className="form-control" name="client_phone"
										value={data.client_phone ?? ''}
										onChange={(e) => setData({ ...data, 'client_phone': e.target.value })} />
								</div>
							</div>
						</div>
						<div className="col-md-6">
							<div className="table-responsive">
								<table className="table table-bordered mb-0">
									<thead>
										<tr>
											<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
											<th style={{ 'width': '90%' }}>{t('name2')}</th>
											<th style={{ 'width': '10%' }}></th>
										</tr>
									</thead>
									<tbody>
										{data?.poses?.map((item, index) => (
											<tr key={index}>
												<td style={{ 'width': '33px' }} className="text-center">{index + 1}</td>
												<td>{item.name}</td>
												<td className="d-flex justify-content-end">
													<div className="form-check form-switch form-switch-sm">
														<input type="checkbox" className="form-check-input"
															checked={item.status ? true : false}
															onChange={(e) => handleSettingChange(e, item, index)} />
													</div>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						</div>
					</div>

					<div className="btn-wrapper gap-2">
						<Link className="btn btn-warning btn-rounded btn-wide" to="/external-integrations">
							{t('cancel')}
						</Link>
						<button type="button" className="btn btn-primary btn-rounded btn-wide"
							onClick={(e) => createUpdate(e)}>
							{t('save')}
						</button>
					</div>
				</div>
			</div>
		</>
	)
}

export default Index