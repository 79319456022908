import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Modal } from 'react-bootstrap'

import RightbarProduct from './RightbarProduct'
import RightbarProducts from './RightbarProducts'
import { formatMoney } from "helpers/helpers";
import { httpOk, GET, PUT, POST } from 'helpers/api'

function Index() {
	const { t } = useTranslation()
	const { id } = useParams()

	const [data, setData] = useState({})
	const [selectedItem, setSelectedItem] = useState({})
	const [modalMenu, setModalMenu] = useState(false)
	const [modalProduct, setModalProduct] = useState(false)
	const [showRightBar, setShowRightBar] = useState(false)

	async function searchWithFilter() {
		var filterUrl = `/external-menu/${id}`

		var response = await GET(filterUrl, {}, { loader: true })
		if (httpOk(response)) {
			setData(response.data);
		}
	}

	async function createUpdate(e) {
		e.preventDefault()

		var selectedItemCopy = { ...selectedItem }
		selectedItemCopy.external_group_id = id

		var response
		if (!selectedItemCopy.id) {
			response = await POST(`/external-menu`, selectedItemCopy, { loader: true })
		} else {
			response = await PUT(`/external-menu`, selectedItemCopy, { loader: true })
		}

		if (httpOk(response)) {
			var dataCopy = { ...data }

			var foundIndex = dataCopy.menus.findIndex(obj => obj.id === selectedItemCopy.id)
			if (foundIndex >= 0) {
				dataCopy.menus[foundIndex]['name'] = selectedItemCopy.name
				setData(dataCopy)
			}

			toggleModalMenu()
			if (!selectedItemCopy.id) searchWithFilter()
		}
	}

	function toggleExternalMenu(index) {
		var dataCopy = { ...data }
		dataCopy.menus[index]['active'] = !dataCopy.menus[index]['active']

		setData(dataCopy)
	}

	function toggleModalMenu(bool = false, item = {}, index) {
		item.index = index
		setSelectedItem(item)
		setModalMenu(bool)
	}

	function toggleModalProduct(bool = false, item = {}, index) {
		item.index = index
		setSelectedItem(item)
		setModalProduct(bool)
	}

	async function toggleRightbar(bool = false, item = {}) {
		console.log(item)
		setSelectedItem(item)
		setShowRightBar(bool)
	}

	async function updateProduct(e) {
		e.preventDefault()
		var selectedItemCopy = { ...selectedItem }
		selectedItemCopy.external_group_id = id
		var response = await PUT(`/external-menu-product`, selectedItemCopy, { loader: true })

		if (httpOk(response)) {
			var dataCopy = { ...data }

			var foundIndex = dataCopy.menus.findIndex(obj => obj.id === selectedItem.external_menu_id)
			if (foundIndex >= 0) {
				dataCopy.menus[foundIndex]['products'][selectedItem.index]['name'] = selectedItem.name
				dataCopy.menus[foundIndex]['products'][selectedItem.index]['sale_price'] = selectedItem.sale_price
				dataCopy.menus[foundIndex]['products'][selectedItem.index]['description'] = selectedItem?.description
				dataCopy.menus[foundIndex]['products'][selectedItem.index]['unit'] = selectedItem?.unit
				dataCopy.menus[foundIndex]['products'][selectedItem.index]['weight'] = selectedItem?.weight
				dataCopy.menus[foundIndex]['products'][selectedItem.index]['image'] = selectedItem?.image
				setData(dataCopy)
				toggleModalProduct()
			}
		}
	}

	async function addProduct(item) {
		var response = await POST('/external-menu-product', {
			'product_id': item.product_id,
			'external_menu_id': selectedItem.id,
			'external_group_id': id,
		}, { loader: true })
		if (httpOk(response)) {
			var dataCopy = { ...data }
			var index = dataCopy.menus.findIndex(el => el.id === selectedItem.id)
			dataCopy.menus[index]['products'].push(response.data)
			setData(dataCopy)
		}
	}

	useEffect(() => {
		searchWithFilter()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between">
				<div className="d-flex text-truncate">
					<h4 className="vertical-center">
						{t('external_menu')}
					</h4>
					<div className="vertical-center mt-1">
						<i className="uil uil-angle-double-right fz-20"></i>
					</div>
					<div className="vertical-center mt-1">
						<h6>{data?.group?.name}</h6>
					</div>
				</div>
				<div className="btn-rounded btn btn-primary"
					onClick={() => toggleModalMenu(true)}>
					{t('create')}
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					{data?.menus?.map((item, index) => (
						<div key={index} className="mb-3">
							<div className="external-menu">
								<div className="d-flex" onClick={() => toggleExternalMenu(index)}>
									<div className="external-menu-arrow">
										{item.active ?
											<i className="uil uil-arrow-down"></i>
											:
											<i className="uil uil-arrow-right"></i>
										}
									</div>
									<div className="external-menu-text gap-2">
										<span>{item.name}</span>
										<span className={`${item.count_products > 0 ? '' : 'text-danger'}`}>
											({item.count_products})
										</span>
									</div>
								</div>
								<div style={{
									'height': '100%',
									'display': 'flex',
									'marginLeft': 'auto',
								}}>
									<div className="external-menu-icons"
										onClick={() => toggleRightbar(true, item)}>
										<i className="uil-plus"></i>
									</div>
									<div className="external-menu-icons"
										onClick={() => toggleModalMenu(true, item)}>
										<i className="uil-pen"></i>
									</div>
									<div className="external-menu-icons">
										<i className="uil-expand-arrows-alt"></i>
									</div>
								</div>
							</div>
							{item.active &&
								<div className="table-responsive" style={{ 'minHeight': 0 }}>
									<table className="table table-bordered mb-0">
										<thead>
											<tr>
												<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
												<th style={{ 'width': '15%' }}>{t('name2')}</th>
												<th style={{ 'width': '15%' }} className="td-ellipsis">{t('system_name2')}</th>
												<th style={{ 'width': '10%' }} className="text-center">{t('image2')}</th>
												<th style={{ 'width': '10%' }} className="text-center">{t('description')}</th>
												<th style={{ 'width': '10%' }} className="text-center">{t('weight')}</th>
												<th style={{ 'width': '10%' }} className="text-center">{t('unit')}</th>
												<th style={{ 'width': '5%' }} className="text-center">{t('status')}</th>
												<th style={{ 'width': '15%' }}>{t('fiscalization')}</th>
												<th style={{ 'width': '10%' }} className="text-end">{t('sale_price')}</th>
												<th style={{ 'minWidth': '33px' }} className="text-center"></th>
											</tr>
										</thead>
										<tbody>
											{item?.products?.map((item, index2) => (
												<tr key={index2}>
													<td className="text-center">{index2 + 1}</td>
													<td className="text-primary cursor" onClick={() => toggleModalProduct(true, item, index2)}>
														<span>{item.name}</span>
													</td>
													<td>{item.system_name}</td>
													<td className="text-center">
														{item.image ?
															<span className="bg-primary bg-border"></span>
															:
															<span className="bg-danger bg-border"></span>
														}
													</td>
													<td className="td-ellipsis text-center">
														{item.description ?
															<span>{item.description}</span>
															:
															<span className="bg-danger bg-border"></span>
														}
													</td>
													<td className="text-center">
														{item.weight ?
															<span>{item.weight}</span>
															:
															<span className="bg-danger bg-border"></span>
														}
													</td>
													<td className="text-center">
														<span>{item.unit}</span>
													</td>
													<td className="text-center">
														{item.status &&
															<span className="bg-primary bg-border"></span>
														}
														{!item.status &&
															<span className="bg-danger bg-border"></span>
														}
													</td>
													<td className="td-ellipsis text-center">
														{item.fiscal_code ?
															<span title={item.fiscal_code}>{item.fiscal_code}</span>
															:
															<span className="bg-danger bg-border"></span>
														}
													</td>
													<td className="text-end">{formatMoney(item.sale_price)}</td>
													<td>
														<button className="table-action-button table-action-primary-button"
															onClick={() => toggleModalProduct(true, item, index2)}>
															<i className="uil-edit-alt"></i>
														</button>
													</td>
												</tr>
											))}
										</tbody>
									</table>
								</div>
							}
						</div>
					))}
				</div>
			</div>

			{/* MODAL MENU */}
			<Modal show={modalMenu} animation={false} centered
				dialogClassName="update-modal-width" backdrop="static"
				onHide={() => toggleModalMenu()}>
				<Modal.Header closeButton>
					<Modal.Title>
						{!selectedItem.id ?
							<>{t('create')}</>
							:
							<>{t('edit')}</>
						}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={createUpdate} autoComplete="off">
						<div className="form-group">
							<label>{t('name2')}</label>
							<input type="text" className="form-control" name="name"
								value={selectedItem.name ?? ""}
								autoFocus
								onChange={(e) => setSelectedItem({ ...selectedItem, 'name': e.target.value })} />
						</div>

						<div className="fz-16">
							<div className="d-flex flex-column">
								<span>* Название без кавычек</span>
								<span>* Название на русском языке</span>
							</div>
						</div>

						<div className="d-flex gap-2 w-100 mt-3">
							<button type="button" className="btn btn-outline-warning w-100"
								onClick={() => toggleModalMenu()}>
								{t('cancel')}
							</button>
							<button type="submit" className="btn btn-primary w-100">
								{t('save')}
							</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL MENU */}

			<RightbarProduct
				showRightBar={modalProduct}
				setShowRightBar={setModalProduct}
				toggleModalProduct={toggleModalProduct}
				selectedItem={selectedItem}
				setSelectedItem={setSelectedItem}
				updateProduct={updateProduct}>
			</RightbarProduct>

			<RightbarProducts
				showRightBar={showRightBar}
				setShowRightBar={setShowRightBar}
				addProduct={addProduct}>
			</RightbarProducts>
		</>
	)
}

export default Index