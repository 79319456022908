import React from 'react'
import { useTranslation } from 'react-i18next'

import { FILE, httpOk } from 'helpers/api';
import { copyText } from 'helpers/helpers';

function User({ data, setData, handleUserChange, getUUID }) {
	const { t } = useTranslation()

	async function fileUpload(e) {
		let formData = new FormData();
		formData.append("image", e.target.files[0]);
		formData.append("path", 'logos');

		var dataCopy = { ...data }
		var response = null;
		response = await FILE(`/upload-image-only`, formData, {}, { loader: true });
		if (httpOk(response)) {
			dataCopy.settings.logo = response.data.url
			dataCopy.settings.logo64 = response.data.base64
			setData(dataCopy)
		}
	}

	function deleteImage() {
		var dataCopy = { ...data }

		dataCopy.settings.logo = ""
		dataCopy.settings.logo64 = ""
		setData(dataCopy)
	}

	function handleSettingChange(e) {
		var dataCopy = { ...data }
		if (e.target.type === "text") {
			dataCopy.settings[e.target.name] = e.target.value
		}
		if (e.target.type === "checkbox") {
			dataCopy.settings[e.target.name] = e.target.checked
		}

		setData(dataCopy)
	}

	return (
		<form autoComplete="off">
			<div className="row">
				<div className="col-md-6">
					<div className="d-flex justify-content-between mb-2">
						<div className="fw-500 vertical-center">
							{t('name')}
						</div>
						<div className="w-50">
							<input type="text" className="form-control" name="first_name"
								value={data?.first_name ?? ''}
								onChange={(e) => handleUserChange(e)} />
						</div>
					</div>
					<div className="d-flex justify-content-between mb-2">
						<div className="fw-500 vertical-center">
							{t('phone')}
						</div>
						<div className="w-50">
							<input type="number" className="form-control" name="phone"
								value={data?.phone ?? ''}
								onChange={(e) => handleUserChange(e)} />
						</div>
					</div>
					<div className="d-flex justify-content-between mb-2">
						<div className="fw-500 vertical-center">
							{t('backdate')}
						</div>
						<div className="w-50">
							<input type="number" className="form-control" name="backdate"
								value={data?.backdate ?? ''}
								onChange={(e) => handleUserChange(e)} />
						</div>
					</div>

					<div className="d-flex justify-content-between mb-2">
						<div className="fw-500 vertical-center">
							{t('secret_key')}
						</div>
						<div className="d-flex gap-2 w-50">
							<input type="text" className="form-control" name="secret_key"
								disabled
								value={data?.secret_key ?? ''}
								onChange={() => { }} />
							<div className="btn btn-secondary"
								onClick={() => {
									copyText(data?.secret_key)
								}}>
								<i className="uil uil-copy"></i>
							</div>
							<div className="btn btn-success" onClick={() => getUUID()}>
								<i className="uil uil-sync"></i>
							</div>
						</div>
					</div>
				</div>
				<div className="col-md-6">
					<div className="d-flex gap-2">
						<div style={{ 'width': '40%' }}>
							<div className="con-upload mb-3">
								<div className="con-img-upload">
									{data.settings.logo &&
										<div className="img-upload">
											<button type="button" className="btn-x-file"
												onClick={() => deleteImage()}>
												<i className="uil-times"></i>
											</button>
											<img src={`https://backend.mison.uz/uploads/logos/${data.settings.logo}`}
												style={{ maxWidth: 'none', maxHeight: '100%' }} alt={data.settings.logo} />
										</div>
									}
									{!data.settings.logo &&
										<div className="con-input-upload">
											<input type="file" onChange={(e) => fileUpload(e)} />
											<span className="text-input fz14">
												{t('cheque')}
											</span>
										</div>
									}
								</div>
							</div>
						</div>
						<div style={{ 'width': '60%' }}>
							<div className="form-group">
								<label>{t('advertising_text')}</label>
								<input type="text" className="form-control" name="advertising_text"
									value={data.settings.advertising_text ?? ''}
									onChange={(e) => handleSettingChange(e)} />
							</div>
							<div className="d-flex justify-content-between mb-2">
								<div className="fw-500 vertical-center">
									QR в чеке для оценки сервиса
								</div>
								<div className="form-check form-switch form-switch-lg">
									<input type="checkbox" className="form-check-input"
										name="bool_cheque_feedback_qr"
										checked={data?.settings.bool_cheque_feedback_qr ? true : false}
										onChange={(e) => handleSettingChange(e, 'checkbox')} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</form>
	)
}

export default User