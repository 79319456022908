import React, { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { useTranslation } from 'react-i18next'

import { GET, PUT, httpOk } from 'helpers/api'
import { Dropdown, DropdownButton } from "react-bootstrap"
import { formatDate } from "helpers/helpers"

function Index() {
	const { t } = useTranslation()
	const navigate = useNavigate()

	const [data, setData] = useState([])

	async function handleChangeStatus(e, type, index, id) {
		//var dataCopy = JSON.parse(JSON.stringify(data))

		var sendDta = {
			"id": id,
			"status": e.target.checked,
		}

		await PUT('/loyalty/services-status', sendDta, { loader: true })
		getData()
	}

	function goTo(page) {
		if (page === '/loyalty/services/create-amount') {
			navigate('/loyalty/services/create-amount')
			return
		}

		if (page === '/loyalty/services/create-saving') {
			navigate('/loyalty/services/create-saving')
			return
		}

		if (page === '/loyalty/services/create-visit') {
			navigate('/loyalty/services/create-visit')
			return
		}
	}

	async function getData() {
		const response = await GET('/loyalty/services', {}, { loader: true })
		if (httpOk(response)) setData(response.data)
	}

	useEffect(() => {
		getData()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between">
				<h4 className="vertical-center">
					{t('loyalty')} [{data.length}]
				</h4>
				<div className="d-flex">
					<DropdownButton title={t('action')}>
						<Dropdown.Item onClick={() => goTo('/loyalty/services/create-amount')}>
							От суммы чека
						</Dropdown.Item>
						<Dropdown.Item onClick={() => goTo('/loyalty/services/create-saving')}>
							Накопительная программа
						</Dropdown.Item>
						<Dropdown.Item onClick={() => goTo('/loyalty/services/create-visit')}>
							По посещению
						</Dropdown.Item>
					</DropdownButton>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="table-responsive">
						<table className="table table-bordered mb-0">
							<thead>
								<tr>
									<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
									<th style={{ 'width': '20%' }} >{t('name2')}</th>
									<th style={{ 'width': '15%' }} >{t('type')}</th>
									<th style={{ 'width': '15%' }} >{t('launch_date')}</th>
									<th style={{ 'width': '15%' }} >{t('stop_date')}</th>
									<th style={{ 'width': '10%' }} className="text-center text-nowrap">{t('status')}</th>
									<th style={{ 'width': '25%' }}></th>
									<th style={{ 'minWidth': '33px' }}></th>
								</tr>
							</thead>
							<tbody>
								{data?.map((item, index) => (
									<tr key={index}>
										<td className="text-center">{index + 1}</td>
										<td>{item.name}</td>
										<td>{item.service_name}</td>
										<td>{formatDate(item.start_at, 'dd.MM.yy HH:mm')}</td>
										<td>{formatDate(item.stop_at, 'dd.MM.yy HH:mm')}</td>
										<td>
											<div className="form-check form-switch form-switch-md justify-content-center">
												<input type="checkbox" className="form-check-input" name="status"
													checked={item.status}
													onChange={(e) => handleChangeStatus(e, 'checkbox', index, item.id)} />
											</div>
										</td>
										<td></td>
										<td>
											<div className="table-dropdown">
												<Dropdown id={`dropdown-${index}`}>
													<Dropdown.Toggle variant="outline-primary">
														<i className="uil uil-ellipsis-v"></i>
													</Dropdown.Toggle>

													<Dropdown.Menu>
														{item.loyalty_service_type_id === 1 &&
															<Link to={`/loyalty/services/update-amount/${item.id}`} className="table-dropdown-item">
																<i className="uil-edit-alt"></i>
																{t('edit')}
															</Link>
														}
														{item.loyalty_service_type_id === 2 &&
															<Link to={`/loyalty/services/update-saving/${item.id}`} className="table-dropdown-item">
																<i className="uil-edit-alt"></i>
																{t('edit')}
															</Link>
														}
														{item.loyalty_service_type_id === 3 &&
															<Link to={`/loyalty/services/update-visit/${item.id}`} className="table-dropdown-item">
																<i className="uil-edit-alt"></i>
																{t('edit')}
															</Link>
														}
													</Dropdown.Menu>
												</Dropdown>
											</div>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</>
	)
}

export default Index