import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'

import { httpOk, GET, POST, PUT, DELETE } from 'helpers/api'

function Modifiers({
	showModifierModal,
	setShowModifierModal,
	showAddModifierModal,
	setShowAddModifierModal,
	addModifierToProduct,
	activeTab,
}) {
	const { t } = useTranslation()

	const [modifiers, setModifiers] = useState([])
	const [modifierProducts, setModifierProducts] = useState([])
	const [selectedModifier, setSelectedModifier] = useState({})
	const [productModifiers, setProductModifiers] = useState({
		'parent': false,
		'modifiers': []
	})

	async function createUpdate() {
		if (!selectedModifier.id) {
			await POST('/modifiers', selectedModifier, { loader: true })
		}

		if (selectedModifier.id) {
			await PUT('/modifiers', selectedModifier, { loader: true })
			setSelectedModifier({})
		}

		getModifiers()
	}

	async function addModifierToProductHOC() {
		addModifierToProduct(productModifiers)
		setProductModifiers({
			'parent': false,
			'modifiers': []
		})
	}

	function selectModifierToProduct(item) {
		var productModifiersCopy = { ...productModifiers }

		var error = !!productModifiers?.modifiers?.find((element) => element.id === item.id)
		if (error) {
			toast.error('Одинаковые модификаторы')
			return
		}

		productModifiersCopy.modifiers.push(item)
		setProductModifiers(productModifiersCopy)
	}

	async function selectProductToModifier(item) {
		var selectedModifierCopy = { ...selectedModifier }
		var sendData = {
			'parent_id': selectedModifierCopy.id,
			'product_id': item.id,
		}
		await POST('/modifiers-product', sendData, { loader: true })
		getModifiers()
	}

	async function deleteModifier(item) {
		await DELETE('/modifiers', { 'id': item.id }, { loader: true })
		getModifiers()
	}

	function addModifierModal(bool) {
		if (!bool) {
			setShowAddModifierModal(false)
			setProductModifiers({
				'parent': false,
				'modifiers': []
			})
		}
	}

	function existInSelected(item) {
		var bool = false
		bool = !!productModifiers?.modifiers?.find((element) => element.id === item.id)
		return bool
	}

	async function getModifiers() {
		const response = await GET('/modifiers')
		if (httpOk(response)) setModifiers(response.data)
	}

	async function getModifierProducts() {
		const response = await GET('/helper/product-modifiers')
		if (httpOk(response)) setModifierProducts(response.data)
	}

	useEffect(() => {
		if (activeTab === 2) {
			getModifiers()
			getModifierProducts()
		}
	}, [activeTab]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			{/* MODAL MODIFIER */}
			<Modal show={showModifierModal} animation={false} centered size="lg"
				backdrop="static" onHide={() => setShowModifierModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>
						<div className="d-flex gap-2">
							<div className="vertical-center">{t('modifiers')}</div>
						</div>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="d-flex gap-2 mb-3">
						<div className="position-relative w-100">
							<input type="text" className="form-control"
								placeholder={t('name2')}
								value={selectedModifier.name ?? ''}
								onChange={(e) => setSelectedModifier({ ...selectedModifier, 'name': e.target.value })} />
							{selectedModifier.id &&
								<div className="table-action-button table-action-danger-button"
									style={{ 'position': 'absolute', 'top': '8px', 'right': '8px' }}
									onClick={() => setSelectedModifier({})}>
									<i className="uil uil-times"></i>
								</div>
							}
						</div>
						<button type="button" className="btn btn-primary"
							disabled={selectedModifier.id}
							onClick={() => createUpdate()}>
							{t('create')}
						</button>
						<button type="button" className="btn btn-primary"
							disabled={!selectedModifier.id}
							onClick={() => createUpdate()}>
							{t('edit')}
						</button>
					</div>
					<div className="row">
						<div className="col-md-6">
							{modifiers.map((item, index) => (
								<div key={index}
									style={{ 'borderBottom': '1px solid #eee' }}
									onClick={() => setSelectedModifier(item)}>
									<div className={'cursor ' + (selectedModifier.id === item.id ? 'table-tr-active' : '')}>
										<div className="vertical-center">
											<b className="py-1">{index + 1}. {item.name}</b>
										</div>
									</div>
									{item?.products?.map((product, productIndex) => (
										<div className="ms-4 py-1" key={productIndex}>
											<div className="d-flex justify-content-between">
												<div className="vertical-center">
													- {product.name}
												</div>
												<div className="d-flex justify-content-center" onClick={() => deleteModifier(product)}>
													<div className="table-action-button table-action-danger-button">
														<i className="uil uil-times"></i>
													</div>
												</div>
											</div>
										</div>
									))
									}
								</div>
							))}
						</div>
						<div className="col-md-6">
							{modifierProducts.map((item, index) => (
								<div className="py-1" key={index}>
									<div className="d-flex justify-content-between">
										<div className="vertical-center">
											{item.name}
										</div>
										<div className="d-flex justify-content-center" onClick={() => selectProductToModifier(item)}>
											<div className="table-action-button table-action-primary-button">
												<i className="uil uil-plus"></i>
											</div>
										</div>
									</div>
								</div>
							))}
						</div>
					</div>
				</Modal.Body>
			</Modal>
			{/* MODAL MODIFIER */}

			{/* MODAL ADD MODIFIER PRODUCT */}
			<Modal show={showAddModifierModal} animation={false} centered
				backdrop="static" onHide={() => addModifierModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>
						<div>{t('modifiers')}</div>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{modifiers.map((item, index) => (
						<div key={index} style={{ 'borderBottom': '1px solid #000' }}
							className="py-2">
							<div className="vertical-center modifier-item"
								onClick={() => selectModifierToProduct(item)}>
								<div className={(existInSelected(item) ? 'modifier-selected' : '')}>
									<b>{index + 1}. {item.name}</b>
								</div>
							</div>
							{item?.products?.map((product, productIndex) => (
								<div key={productIndex} className="ms-4">
									- {product.name}
								</div>
							))
							}
						</div>
					))
					}
					<button type="button" className="btn btn-primary w-100 mt-3"
						onClick={() => addModifierToProductHOC()}>
						{t('save')}
					</button>
				</Modal.Body>
			</Modal>
			{/* MODAL ADD MODIFIER PRODUCT */}
		</>
	)
}

export default Modifiers